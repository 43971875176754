import { useRef, useEffect } from 'react'

import noop from '../noop'

/** @type {Record<string, Listener[]>} */
const resolversByAction = {}

/** @type {Record<string, Listener[]>} */
const rejectorsByAction = {}

let uuid = 0

/** @type {() => import('redux').Middleware} */
export default function awaitActionTypeMiddleware() {
  return () => (next) => (action) => {
    // Let reducers do their thing first.
    const result = next(action)

    const resolvers = resolversByAction[action.type]
    if (resolvers) {
      iterateAndRemove(
        resolvers,
        (listener) => listener.validator(action),
        (listener) => listener.resolver(action),
      )
    }

    const rejectors = rejectorsByAction[action.type]
    if (rejectors) {
      iterateAndRemove(
        rejectors,
        (listener) => listener.validator(action),
        (listener) => listener.rejector(action),
      )
    }

    return result
  }
}

/**
 * @param {number} lookup
 */
function removeByUuid(lookup) {
  Object.keys(resolversByAction).forEach((x) => {
    iterateAndRemove(
      resolversByAction[x],
      (listener) => listener.uuid === lookup,
      noop,
    )
  })

  Object.keys(rejectorsByAction).forEach((x) => {
    iterateAndRemove(
      rejectorsByAction[x],
      (listener) => listener.uuid === lookup,
      noop,
    )
  })
}

/**
 *
 *
 * @param {Listener[]} array
 * @param {(listener: Listener) => boolean} shouldRun
 * @param {(listener: Listener) => void} onRemove
 */
function iterateAndRemove(array, shouldRun, onRemove) {
  // Intentionally loop backwards since we are manipulating the array.
  for (let i = array.length - 1; i >= 0; i -= 1) {
    const listener = array[i]

    if (shouldRun(listener)) {
      onRemove(listener)
      array.splice(i, 1)
    }
  }
}

/**
 * @param {{ resolve?: string[], reject?: string[] }} options
 */
export function awaitActionType(options) {
  /** @type {() => void} */
  let resolver
  /** @type {() => void} */
  let rejector
  const promise = new Promise((resolve, reject) => {
    resolver = resolve
    rejector = reject
  })

  uuid += 1

  let validator = () => true
  if (options.validator) {
    validator = options.validator
  }

  function unsubscribe() {
    removeByUuid(uuid)
  }

  const listener = { resolver, rejector, uuid, promise, validator }

  ;(options.resolve || []).forEach((x) => {
    resolversByAction[x] = resolversByAction[x] || []
    resolversByAction[x].push(listener)
  })
  ;(options.reject || []).forEach((x) => {
    rejectorsByAction[x] = rejectorsByAction[x] || []
    rejectorsByAction[x].push(listener)
  })

  return {
    promise,
    unsubscribe,
  }
}

/**
 * @param {{ resolve?: string[], reject?: string[] }} options
 */
export function useAwaitActionType(options) {
  const ref = useRef(null)

  useEffect(() => {
    return () => {
      if (ref.current) {
        ref.current.unsubscribe()
      }
    }
  }, [])

  return () => {
    if (ref.current) {
      ref.current.unsubscribe()
    }

    ref.current = awaitActionType(options)

    return ref.current
  }
}

/** @typedef {{ uuid: number, resolver: () => void, rejector: (err?: any) => void, validator?: (action: any) => boolean }} Listener */
