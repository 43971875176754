import { createReducer } from '../../lib/Utils'
import * as appActions from '../app/actions'

import * as actions from './actions'

const initialState = {
  all: {},
}

export default createReducer(initialState, {
  [actions.GET_AUTOMATIONS_FULFILLED]: (state, action) => {
    const newAll = {}

    action.payload.forEach((x) => {
      newAll[x.id] = x
    })

    return {
      ...state,
      all: {
        ...state.all,
        ...newAll,
      },
    }
  },

  [actions.UPDATE_AUTOMATIONS_FULFILLED]: (state, action) => {
    return {
      ...state,
      all: {
        ...state.all,
        [action.payload.id]: action.payload,
      },
    }
  },

  [appActions.SELECT_RETAILER_ACCOUNT]: (state) => ({
    ...state,
    all: {},
  }),
  [actions.CLEAR_AUTOMATIONS]: (state) => ({
    ...state,
    all: {},
  }),
})
