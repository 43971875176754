import * as React from "react";
import { forwardRef, memo } from "react";
const SvgInfoRestricted = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 30 30", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "#FFF", d: "M23.412 2.986c6.638 4.648 8.25 13.789 3.602 20.426-4.648 6.638-13.789 8.25-20.426 3.602-6.638-4.648-8.25-13.789-3.602-20.426C7.634-.05 16.775-1.662 23.412 2.986M6.71 24.139l14.034-20.06C15.33 1.229 8.49 2.784 4.893 7.923c-3.6 5.142-2.72 12.105 1.816 16.216M23.272 5.844 9.235 25.91c5.415 2.867 12.269 1.314 15.872-3.832 3.605-5.149 2.718-12.125-1.835-16.233" }));
const ForwardRef = forwardRef(SvgInfoRestricted);
const Memo = memo(ForwardRef);
export default Memo;
