import * as React from "react";
import { forwardRef, memo } from "react";
const SvgPetCare = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlSpace: "preserve", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeMiterlimit: 10
}, viewBox: "0 0 233 409", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M1092.9 321.1c15.1 0 33.7 26.9 33.7 45.2 0 13.7 12.3 48.8 12.3 48.8s-77.2 18.8-77.2-51.1c0-9.6 14.7-42.9 31.2-42.9", style: {
  fill: "#f4511e",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "m1068.2 406-14 11.1 3.8 13.2.1-.7c.5-3.6 3.6-6.3 7.2-6.4z", style: {
  fill: "#ffc49e",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "m1059.3 466.7-10.7 1.8c-1.5.2-2.8-1-2.5-2.5l7.2-44.9c.5-3.3 2-6.4 4.4-8.7 2.8-2.6 6.5-4.2 10.5-4.2h2.2", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "#ffc49e",
  strokeWidth: "12.3px"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "m1097 677 .1 16.5h44.4v-1.6l-28.9-14.9z", style: {
  fill: "#ffc49e",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "M1100.6 696.7h20.8c14.8 0 21.6-2 21.6-4.5 0-2.1-2.3-3.9-4.6-4.8l-12.1-4.3c0 3.7-2.2 6.8-10.7 6.8h-6c-5.5 0-12.6-.7-12.6-8.2l-1.7 8.9c-.5 3.3 2.1 6.1 5.3 6.1", style: {
  fill: "#ad6d4a",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("g", { transform: "translate(-993.619 -306.215)" }, /* @__PURE__ */ React.createElement("clipPath", { id: "a" }, /* @__PURE__ */ React.createElement("path", { d: "M1095.2 690.7c-.4 3.2 2.1 6.1 5.4 6.1h20.8c14.8 0 21.6-2 21.6-4.5 0-2.1-2.3-3.9-4.6-4.8l-12.1-4.3c0 3.7-2.2 6.8-10.7 6.8h-6c-5.5 0-12.6-.7-12.6-8.2z" })), /* @__PURE__ */ React.createElement("g", { clipPath: "url(#a)" }, /* @__PURE__ */ React.createElement("path", { d: "M1093.4 694.5h52.2v9.3h-52.2z", style: {
  fill: "#bd8958"
} }))), /* @__PURE__ */ React.createElement("path", { d: "m1112.7 677 7.2-163.2c.7-18-1.2-23.2-3.6-41.1l-.5-3.8H1069l-1 11.4c-1.1 12.9-2.4 20.6-1.9 33.5l7.5 163.2h15.7l-.1-162.3c0-2.2 1.8-3.9 4-3.9s3.9 1.7 4 3.9L1097 677z", style: {
  fill: "#914941",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "M1096.4 321.1c16.8 0 37.5 29.9 37.5 50.3 0 15.2-.1 14.9 13.7 54.3 0 0-85.9 20.9-85.9-56.8 0-10.8 16.3-47.8 34.7-47.8", style: {
  fill: "#f4511e",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "m1118.9 406.1 13.9 11.1-3.6 12.7-.3-.1c-1.2-3.8-3.6-6.3-7.2-6.4zM1094.3 411.6c3.3 0 6-2.7 6-6v-28.8h-11.9v28.8c-.1 3.3 2.6 6 5.9 6", style: {
  fill: "#ffc49e",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "M1128.9 487.9H1057l9.4-86.1h53z", style: {
  fill: "#bd8958",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "M1127 365.3v-6.1c0-19.9-14.3-34.7-32.5-34.7s-32.9 10.7-32.9 32v2.9z", style: {
  fill: "#f4511e",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "M1094.3 395.2c17.5 0 31.7-14.2 31.7-31.7s-14.2-31.7-31.7-31.7-31.7 14.2-31.7 31.7 14.2 31.7 31.7 31.7", style: {
  fill: "#ffc49e",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "M1125.9 358.5c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5c.1-2.8-2.2-5-5-5", style: {
  fill: "#ffc49e",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "M1109.9 361.7c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8 1.8-.8 1.8-1.8-.8-1.8-1.8-1.8M1078.7 361.7c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8 1.8-.8 1.8-1.8-.8-1.8-1.8-1.8", style: {
  fill: "#062640",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "M1062.6 358.5c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5", style: {
  fill: "#ffc49e",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "M1104.3 377.9c-3.4 7-16.5 8.2-20 0", style: {
  fill: "#fff",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "M1076.8 334.6s10.1 19.7 38.5 19.7c.7 0 .8-1 .1-1.2-2.3-.7-5.2-1.9-6.1-3.8 0 0 4.6 3 9.6 2.4.6-.1.7-.9.2-1.1-3.4-1.6-7.4-4.8-5.5-9.1l-19.3-7h-17.5zM1075.2 370.7c0 .2-.2.4-.4.4s-.4-.2-.4-.4.2-.4.4-.4.4.1.4.4M1075.2 374.4c0 .2-.2.4-.4.4s-.4-.2-.4-.4.2-.4.4-.4.4.2.4.4M1079.5 369.3c0 .2-.2.4-.4.4s-.4-.2-.4-.4.2-.4.4-.4.4.2.4.4M1114.4 374.8c0 .2-.2.4-.4.4s-.4-.2-.4-.4.2-.4.4-.4.4.2.4.4M1111.3 369.7c0 .2-.2.4-.4.4s-.4-.2-.4-.4.2-.4.4-.4.4.2.4.4M1079.7 373.5c0 .2-.2.4-.4.4s-.4-.2-.4-.4.2-.4.4-.4.4.1.4.4M1107.8 374c0 .2-.2.4-.4.4s-.4-.2-.4-.4.2-.4.4-.4.4.2.4.4M1105.8 369.2c0 .2-.2.4-.4.4s-.4-.2-.4-.4.2-.4.4-.4.4.2.4.4", style: {
  fill: "#f4511e",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "M1062.6 358.5s8.1-20.8 31.7-20.8 31.7 20.8 31.7 20.8-2.2-30.4-31.7-30.4-31.7 25.4-31.7 30.4", style: {
  fill: "#f4511e",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "M1109.4 401.8s1.1 11.6-15.3 11.6c-16.3 0-15.3-11.6-15.3-11.6z", style: {
  fill: "#ffc49e",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "M1109.4 401.8s1.1 11.6-15.3 11.6c-16.3 0-15.3-11.6-15.3-11.6", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "#ffc208",
  strokeWidth: "3.78px"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "M1108.2 581.3c2.8 0 5.5-.7 7.9-2-.3 4.1-3.7 7.2-7.9 7.2-4.1 0-7.5-3.2-7.9-7.2 2.4 1.3 5.1 2 7.9 2M1080.3 581.3c2.8 0 5.5-.7 7.9-2-.3 4.1-3.7 7.2-7.9 7.2s-7.5-3.2-7.9-7.2c2.4 1.3 5.1 2 7.9 2", style: {
  fill: "#231f20",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "m1073 677 .2 16.5h44.4v-1.6l-28.9-14.9z", style: {
  fill: "#ffc49e",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "M1076.7 696.7h20.8c14.8 0 21.6-2 21.6-4.5 0-2.1-2.3-3.9-4.6-4.8l-12.1-4.3c0 3.7-2.2 6.8-10.7 6.8h-6c-5.5 0-12.6-.7-12.6-8.2l-1.7 8.9c-.5 3.3 2 6.1 5.3 6.1", style: {
  fill: "#ad6d4a",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("g", { transform: "translate(-993.619 -306.215)" }, /* @__PURE__ */ React.createElement("clipPath", { id: "b" }, /* @__PURE__ */ React.createElement("path", { d: "M1071.3 690.7c-.4 3.2 2.1 6.1 5.4 6.1h20.8c14.8 0 21.6-2 21.6-4.5 0-2.1-2.3-3.9-4.6-4.8l-12.1-4.3c0 3.7-2.2 6.8-10.7 6.8h-6c-5.5 0-12.6-.7-12.6-8.2z" })), /* @__PURE__ */ React.createElement("g", { clipPath: "url(#b)" }, /* @__PURE__ */ React.createElement("path", { d: "M1069.5 694.5h52.2v9.3h-52.2z", style: {
  fill: "#bd8958"
} }))), /* @__PURE__ */ React.createElement("path", { d: "M1056.7 472.8c-1.3.9-2.2 2.3-2.5 3.9l-6.4 39.5c-.6 3.5 2.1 6.7 5.7 6.7h7.6c3.2 0 5.9-2.7 5.8-5.9l-1.2-39.5c-.2-4.6-5.2-7.3-9-4.7", style: {
  fill: "#212121",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "M1062.6 479.2c0 1.5-1.2 2.6-2.6 2.6-1.5 0-2.6-1.2-2.6-2.6 0-1.5 1.2-2.6 2.6-2.6 1.4-.1 2.6 1.1 2.6 2.6Z", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "#ffc208",
  strokeWidth: "1.89px"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "m1072.8 466.9-7.4-2.6c-1.1-.4-1.7-1.7-1.3-2.8s1.7-1.7 2.8-1.3l7.4 2.7c1.1.4 1.7 1.7 1.3 2.8-.4 1-1.7 1.7-2.8 1.2", style: {
  fill: "#ffc49e",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "m1109.4 401.8-49.6 77.5", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "#212121",
  strokeWidth: "3.78px",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "m1062.8 475.2-6.9-2.5c-4-1.4-6.1-5.9-4.7-9.9s5.9-6.1 9.9-4.7l6.9 2.5c4 1.4 6.1 5.9 4.7 9.9-1.5 4-5.9 6.1-9.9 4.7", style: {
  fill: "#ffc49e",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "M1213.1 687.8c-7 0-9.5-.3-26.7-.4-.9 0-1.8 0-2.8-.1-3.5-.2-14.6-.6-20.3-.6-7.4 0-23-1.5-43.7-1.5-27.1 0 16.9.2-28.3.2-20.7 0-68.4 3-72.2 11.7-2.1 4.9 7.6 10 28.3 10 24.3 0 42.5-9 62.9-9 33.3 0 32.5 14.6 53.9 14.9 14.2.2 10.1-7.6 13.8-13.8 1.4-2.4 3.7-6 17.5-6 8.3 0 16.3 1.5 21.9 1.5 9.2 0 12.2-1.5 12.2-3.4.2-2-7.3-3.5-16.5-3.5", style: {
  fillOpacity: 0.23,
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "M1018.9 685.3h210.9V713h-210.9z", style: {
  fill: "none"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "M1179.5 559.5c0 1.8 1.4 3.2 3.2 3.2s3.2-1.4 3.2-3.2-1.4-3.2-3.2-3.2-3.2 1.4-3.2 3.2Z", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "#ffc208",
  strokeWidth: ".98px"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "m1156.6 601.6 14.8 88.1h12.3V686c0-1.4-1.2-2.6-2.6-2.6-2.4 0-4.4-1.9-4.5-4.4l-2.4-86.1zM1080.6 590.3s-1.1 17.9-8.4 29.6-20.4 21.5-17.6 41.9c1.8 13.3 3 19.9 3.6 23 .3 1.3 1.4 2.3 2.7 2.4l3.3.3c1.3.1 2.4 1.3 2.4 2.6v4.6h-13.4l-14.3-61.2 12-43.3h29.7z", style: {
  fill: "#5d4037",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "M1080.6 590.3s-1.1 17.9-8.4 29.6-20.4 21.5-17.6 41.9c1.8 13.3 3 19.9 3.6 23 .3 1.3 1.4 2.3 2.7 2.4l3.3.3c1.3.1 2.4 1.3 2.4 2.6v4.6h-13.4l-14.3-61.2 12-43.3h29.7z", style: {
  fill: "#231f20",
  fillOpacity: 0.58,
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "m1156.6 601.6 14.8 88.1h12.3V686c0-1.4-1.2-2.6-2.6-2.6-2.4 0-4.4-1.9-4.5-4.4l-2.4-86.1z", opacity: 0.12, style: {
  fill: "#231f20",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "M1072.5 592.8c25.6 0 28.1 23.6 74.1 23.6 22.6 0 32.7-22.3 32.7-49.3 0-22.9 13.4-26 27.1-26s14.1-7.4 14.1-7.4v-6.4l-15.1-6c-2.1-.8-3.7-2.5-4.5-4.5l-1.5-3.9s-18.3-8.9-28.6-3.4c-10.3 5.6-10.9 45.4-47.6 45.4s-28.3-4-51.8-4-43.8 8.5-43.8 24.7 5.4 28.9 5.4 44.1-10.9 31.4-10.9 31.4l10.9 50.1h15.5v-5c0-5.2-9.2.4-10.8-6.8-1.5-7.3-2.8-25-2.8-33.9 0-9 20.5-19.2 20.5-36.3 0-17.5 5.4-26.4 17.1-26.4", style: {
  fill: "#5d4037",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "m1138.7 613.2 14.8 88.1h12.3v-3.7c0-1.4-1.2-2.6-2.6-2.6-2.4 0-4.4-1.9-4.5-4.4l-2.4-86.1z", style: {
  fill: "#5d4037",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "M1040.6 566.3s-25.9 15-60.3-18.8c-.4-.4-1-.4-1.4.1-.3.3-.3.8-.1 1.1 2.3 4 16.5 26.3 45.1 26.3 13.9 0 16.6-6.8 16.6-6.8v-1.9z", style: {
  fill: "#5d4037",
  fillRule: "nonzero"
}, transform: "rotate(54.083 836.287 -561.59)" }), /* @__PURE__ */ React.createElement("path", { d: "M1180.8 506.7c2.4.1 4.4 2.1 4.4 4.6v10.2l.1 8.6c0 1.7-.9 3.3-2.4 4.1-1.7.9-3.7.7-5.2-.6l-11.3-9.9c-5-4.4-4.9-12.2.2-16.5.9-.7 2-1.1 3.2-1.1z", style: {
  fill: "#4f332e",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "m1217.6 526.1 2.9 4.6v-3.4z", style: {
  fill: "#231f20",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "M1197.2 521c0 1-.8 1.9-1.9 1.9-1 0-1.9-.8-1.9-1.9s.8-1.9 1.9-1.9 1.9.8 1.9 1.9", style: {
  fill: "#fff",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "M1163.5 533h5.1v35.5h-5.1z", style: {
  fill: "#231f20"
}, transform: "rotate(-62.064 414.753 1223.332)" }), /* @__PURE__ */ React.createElement("path", { d: "m1149.2 544.6 30.5 16.2-.2 2.4z", opacity: 0.12, style: {
  fill: "#231f20",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "m1159.5 498.3-15.8-41.8L1132 419c-2-6.4-7.9-10.7-14.6-10.7h-2.2", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "#ffc49e",
  strokeWidth: "12.3px"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "M1166.6 498.7c.8-.4 1.7-.1 2 .8l3.8 9c.3.8 0 1.9-.8 2.3s-1.7.1-2-.8l-3.8-9c-.3-.9 0-1.9.8-2.3M1157.2 506.7c.8-.4 1.7-.1 2 .8l4.7 11.5c.3.8 0 1.9-.8 2.3s-1.7.1-2-.8l-4.7-11.5c-.3-.9 0-1.9.8-2.3M1163.9 502.8c.8-.4 1.7-.1 2 .8l4.1 10.3c.3.8 0 1.9-.8 2.3s-1.7.1-2-.8l-4.1-10.3c-.3-.9 0-1.9.8-2.3M1160.8 505c.8-.4 1.7-.1 2 .8l4.7 11.8c.3.8 0 1.9-.8 2.3s-1.7.1-2-.8l-4.7-11.8c-.3-.9 0-1.9.8-2.3", style: {
  fill: "#ffc49e",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "m1168.6 499.5-.6-1.9c-.9-3-3.1-5.3-5.9-6.2l-8.5 10.3 4 10.4z", style: {
  fill: "#ffc49e",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "M1161.8 499.6c.2 3.1-1.9 5.8-4.7 6.1l-2.1.2.3 6.6c.1 1-.6 2-1.6 2.1-.9.1-1.7-.7-1.8-1.7l-.6-11.7c-.2-3.4 2.1-6.3 5.1-6.7 2.9-.2 5.3 2 5.4 5.1", style: {
  fill: "#ffc49e",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "m1161.8 489.1 4.3 4.9-11.7 6.3-.9-5 6.9-7.8z", style: {
  fill: "#ffc49e",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }), /* @__PURE__ */ React.createElement("path", { d: "M1113.6 401.8h5.8l1.3 14.7-7.1 1.7z", style: {
  fill: "#bd8958",
  fillRule: "nonzero"
}, transform: "translate(-993.619 -306.215)" }));
const ForwardRef = forwardRef(SvgPetCare);
const Memo = memo(ForwardRef);
export default Memo;
