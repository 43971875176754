import { createSelector } from 'reselect'

import { currentRetailerAccountIdSelector } from '../../app/selectors'
import { retailerAccountsSelector } from '../selectors'

export const alertsForCurrentRetailerAccountSelector = createSelector(
  [retailerAccountsSelector, currentRetailerAccountIdSelector],
  (retailerAccounts, currentRetailerAccountId) => {
    const retailerAccount = retailerAccounts[currentRetailerAccountId] || {}
    const alerts = retailerAccount.alerts || []
    return alerts
  },
)

export const selectAlertsForCurrentRetailerAccount = createSelector(
  [alertsForCurrentRetailerAccountSelector],
  (alertsForCurrentRetailerAccount) => {
    return {
      alerts: alertsForCurrentRetailerAccount,
    }
  },
)

export const selectAlertsUnreadCount = createSelector(
  [alertsForCurrentRetailerAccountSelector],
  (alertsForCurrentRetailerAccount) => {
    return {
      unreadAlertsCount: alertsForCurrentRetailerAccount.filter(
        (alert) => alert.status === 'unread',
      ).length,
    }
  },
)
