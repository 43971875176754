import * as React from "react";
import { forwardRef, memo } from "react";
const SvgBoxxBlue = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlSpace: "preserve", viewBox: "0 0 30.1 23.1", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "#1FBECA", d: "M14.5 21.7H4.9c-.1 0-.2-.1-.2-.2V9.8l5.6-1.3c.2-.1.4-.2.5-.3l3.8-4.9v18.3zm15.6-9.9-2.5-6.1c-.1-.2-.2-.3-.4-.4L15.8.1c-.1 0-.2-.1-.3-.1s-.3.1-.4.1l-11 5.2c-.2.1-.4.2-.5.4l-3.5 5c-.1.1 0 .2.1.2l3-.7v12.7q0 .3.3.3h23.6q.3 0 .3-.3V13l-8.9-3.4c-.1 0-.2-.1-.2-.2l-.8-4.1q0-.15 0 0L19.2 8l.2.2L29.9 12c.2.1.3 0 .2-.2" }));
const ForwardRef = forwardRef(SvgBoxxBlue);
const Memo = memo(ForwardRef);
export default Memo;
