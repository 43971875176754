import HBErrorBoundary from '@honeybadger-io/react'
import WarningIcon from '@mui/icons-material/Warning'
import Typography from '@mui/material/Typography'
import Honeybadger from 'honeybadger-js'
import I18n from 'i18n-js'
import { makeStyles } from 'tss-react/mui'

import AppConfig from '@src/config/AppConfig'
import { helpWidget } from '@src/lib/HelpWidget'

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: '25%',
  },
  icon: {
    fontSize: '3em',
  },
  support: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}))

//Honeybadger Config
const honeybadgerConfig = {
  apiKey: AppConfig.HONEYBADGER_API_KEY as string,
  environment: AppConfig.ENV,
  revision: AppConfig.COMMITHASH,
}
const honeybadger = Honeybadger.configure(honeybadgerConfig)

interface ErrorBoundaryProps {
  ErrorComponent?: React.ComponentType<React.PropsWithChildren>
}
const ErrorBoundary: React.FC<React.PropsWithChildren<ErrorBoundaryProps>> = ({
  ErrorComponent,
  children,
}) => {
  return (
    <HBErrorBoundary
      honeybadger={honeybadger}
      ErrorComponent={ErrorComponent || DefaultError}
    >
      {children}
    </HBErrorBoundary>
  )
}

export const DefaultError = () => {
  const { classes } = useStyles()
  const handleToggleBeacon = () => {
    helpWidget.open()
  }
  return (
    <div className={classes.wrapper}>
      <WarningIcon fontSize="large" color="action" className={classes.icon} />
      <Typography variant="h5" color="textSecondary">
        {I18n.t('shared.somethingWentWrong')}
      </Typography>
      <Typography color="textSecondary">
        {I18n.t('shared.tryAgain')}{' '}
        <span onClick={handleToggleBeacon} className={classes.support}>
          {I18n.t('shared.contactSupport')}
        </span>
      </Typography>
    </div>
  )
}

export default ErrorBoundary
