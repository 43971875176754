import { retailerAccountsCampaignsSearch } from '@promoboxx/redux-stores'
import { requestStatus } from '@promoboxx/redux-stores/src/lib/Utils'
import { createSelector } from 'reselect'

import { currentRetailerAccountIdSelector } from '../../../app/selectors'

export const currentRetailerAccountCampaignsSearchSelector = createSelector(
  [
    currentRetailerAccountIdSelector,
    retailerAccountsCampaignsSearch.selectors
      .campaignsSearchByRetailerAccountsSelector,
  ],
  (currentRetailerAccountId, campaignsSearchByRetailerAccountId) => {
    if (
      typeof currentRetailerAccountId === 'number' &&
      campaignsSearchByRetailerAccountId[currentRetailerAccountId]
    ) {
      return campaignsSearchByRetailerAccountId[currentRetailerAccountId]
    }
    return {}
  },
)

export const retailerAccountCampaignsSearchRequestsSelector = createSelector(
  [currentRetailerAccountCampaignsSearchSelector],
  (currentRetailerAccountCampaignsSearch) => {
    return currentRetailerAccountCampaignsSearch.searchCampaignsRequests || []
  },
)

/**
 * Will be true if all requests pending or a mixture of pending and rejected,
 * but not if any are complete or if all are rejected
 * @type {OutputSelector<any, any, (res: any) => boolean>}
 */
export const retailerAccountCampaignsSearchIsLoadingSelector = createSelector(
  [retailerAccountCampaignsSearchRequestsSelector],
  (requests) => {
    if (requests.length > 0) {
      let hasCompleteRequests = false
      let hasOnlyRejectedRequests = true
      requests.forEach((request) => {
        if (request.status === requestStatus.complete) {
          hasCompleteRequests = true
          return false
        } else if (request.status === requestStatus.pending) {
          hasOnlyRejectedRequests = false
        }
      })
      return !(hasCompleteRequests || hasOnlyRejectedRequests)
    }
    return false
  },
)

/**
 * Will return true if there are a mixture of complete and pending requests
 * @type {OutputSelector<any, boolean, (res: any) => boolean>}
 */
export const retailerAccountCampaignsSearchIsLoadingPageSelector =
  createSelector(
    [retailerAccountCampaignsSearchRequestsSelector],
    (requests) => {
      let hasPendingRequest = false
      let hasCompleteRequest = false
      requests.forEach((request) => {
        if (request.status === requestStatus.pending) {
          hasPendingRequest = true
        } else if (request.status === requestStatus.complete) {
          hasCompleteRequest = true
        }
      })
      return hasPendingRequest && hasCompleteRequest
    },
  )

/**
 * Returns true if all requests are rejected
 * @type {OutputSelector<any, any, (res: any) => boolean>}
 */
export const retailerAccountCampaignsSearchIsRejectedSelector = createSelector(
  [retailerAccountCampaignsSearchRequestsSelector],
  (requests) => {
    if (requests.length > 0) {
      let hasOnlyRejectedRequests = true
      requests.forEach((request) => {
        if (request.status !== requestStatus.rejected) {
          hasOnlyRejectedRequests = false
          return false
        }
      })
      return hasOnlyRejectedRequests
    }
    return false
  },
)

export const retailerAccountCampaignsSearchResultsSelector = createSelector(
  [currentRetailerAccountCampaignsSearchSelector],
  (currentRetailerAccountCampaignsSearch) => {
    const campaigns =
      currentRetailerAccountCampaignsSearch.searchCampaigns || []
    // Flatten pages of results
    return [].concat(...campaigns)
  },
)

export const retailerAccountCampaignsSearchTotalSelector = createSelector(
  [currentRetailerAccountCampaignsSearchSelector],
  (currentRetailerAccountCampaignsSearch) => {
    return currentRetailerAccountCampaignsSearch.searchCampaignsTotal
  },
)

export const retailerAccountCampaignsSearchFiltersSelector = createSelector(
  [currentRetailerAccountCampaignsSearchSelector],
  (currentRetailerAccountCampaignsSearch) => {
    return currentRetailerAccountCampaignsSearch.searchCampaignsFilters || {}
  },
)
