import AdCarousel from '@src/svgs/legacy/ad-carousel.svg?react'
import AdImage from '@src/svgs/legacy/ad-image.svg?react'
import AnimalNutrition from '@src/svgs/legacy/Animal Nutrition.svg?react'
import Appliances from '@src/svgs/legacy/Appliances.svg?react'
import Automotive from '@src/svgs/legacy/Automotive.svg?react'
import banner from '@src/svgs/legacy/banner.svg?react'
import BoxxBlue from '@src/svgs/legacy/boxx-blue.svg?react'
import calendar from '@src/svgs/legacy/calendar.svg?react'
import Check_circle from '@src/svgs/legacy/check_circle.svg?react'
import crown from '@src/svgs/legacy/crown.svg?react'
import Cycling from '@src/svgs/legacy/Cycling.svg?react'
import dk_man from '@src/svgs/legacy/dk_man.svg?react'
import dollarsign from '@src/svgs/legacy/dollarsign.svg?react'
import downloadables from '@src/svgs/legacy/downloadables.svg?react'
import email from '@src/svgs/legacy/email.svg?react'
import EmptyContentMan from '@src/svgs/legacy/empty-content-man.svg?react'
import facebook from '@src/svgs/legacy/facebook.svg?react'
import FbAudience from '@src/svgs/legacy/fb-audience.svg?react'
import FbMessenger from '@src/svgs/legacy/fb-messenger.svg?react'
import FootwearAthletic from '@src/svgs/legacy/Footwear Athletic.svg?react'
import FrequentUpdates from '@src/svgs/legacy/Frequent Updates.svg?react'
import Guitar from '@src/svgs/legacy/Guitar.svg?react'
import HappyCustomers from '@src/svgs/legacy/Happy Customers.svg?react'
import HB from '@src/svgs/legacy/HB.svg?react'
import info_confirmed from '@src/svgs/legacy/info_confirmed.svg?react'
import info_error from '@src/svgs/legacy/info_error.svg?react'
import info_notification from '@src/svgs/legacy/info_notification.svg?react'
import info_restricted from '@src/svgs/legacy/info_restricted.svg?react'
import InstagramSquare from '@src/svgs/legacy/instagram-square.svg?react'
import instagram from '@src/svgs/legacy/instagram.svg?react'
import kebab from '@src/svgs/legacy/kebab.svg?react'
import lock from '@src/svgs/legacy/lock.svg?react'
import LogoWhite from '@src/svgs/legacy/logo-white.svg?react'
import Luxury from '@src/svgs/legacy/Luxury.svg?react'
import ModalWallpaper from '@src/svgs/legacy/modal-wallpaper.svg?react'
import old_guy from '@src/svgs/legacy/old_guy.svg?react'
import Outdoor from '@src/svgs/legacy/Outdoor.svg?react'
import pbxx_logo_colored from '@src/svgs/legacy/pbxx_logo_colored.svg?react'
import PetCare from '@src/svgs/legacy/Pet Care.svg?react'
import Planning from '@src/svgs/legacy/Planning.svg?react'
import PremiumBadge from '@src/svgs/legacy/premium-badge.svg?react'
import RecreationalVehicles from '@src/svgs/legacy/Recreational Vehicles.svg?react'
import refer from '@src/svgs/legacy/refer.svg?react'
import rejected from '@src/svgs/legacy/rejected.svg?react'
import RetailerHouse from '@src/svgs/legacy/retailer-house.svg?react'
import social from '@src/svgs/legacy/social.svg?react'
import tall from '@src/svgs/legacy/tall.svg?react'
import tiktok from '@src/svgs/legacy/tiktok.svg?react'
import twitter from '@src/svgs/legacy/twitter.svg?react'
import youtube from '@src/svgs/legacy/youtube.svg?react'

const legacySvgMap = {
  'ad-carousel': AdCarousel,
  'ad-image': AdImage,
  'Animal Nutrition': AnimalNutrition,
  Appliances,
  Automotive,
  banner,
  'boxx-blue': BoxxBlue,
  calendar,
  'check_circle': Check_circle,
  crown,
  Cycling,
  dk_man,
  dollarsign,
  downloadables,
  email,
  'empty-content-man': EmptyContentMan,
  facebook,
  'fb-audience': FbAudience,
  'fb-messenger': FbMessenger,
  'Footwear Athletic': FootwearAthletic,
  'Frequent Updates': FrequentUpdates,
  Guitar,
  'Happy Customers': HappyCustomers,
  HB,
  info_confirmed,
  info_error,
  info_notification,
  info_restricted,
  'instagram-square': InstagramSquare,
  instagram,
  kebab,
  lock,
  'logo-white': LogoWhite,
  Luxury,
  'modal-wallpaper': ModalWallpaper,
  old_guy,
  Outdoor,
  pbxx_logo_colored,
  'Pet Care': PetCare,
  Planning,
  'premium-badge': PremiumBadge,
  'Recreational Vehicles': RecreationalVehicles,
  refer,
  rejected,
  'retailer-house': RetailerHouse,
  social,
  tall,
  twitter,
  youtube,
  tiktok,
}

export default legacySvgMap
