import { locations } from '@promoboxx/redux-stores'
import moment from 'moment'
import { createSelector } from 'reselect'

import history from '@src/lib/history'

import {
  brandsForCurrentRetailerAccountSelector,
  currentBrandSelector,
  brandNameLogoByIdSelector,
  retailerIdsByBrandIdSelector,
} from '../app/selectors'
import { slugsByRetailerIdForCurrentRetailerAccountSelector } from '../retailers/selectors'

const paidAdsSelector = (state) => (state.ad && state.ad.paidAds) || []

export const selectPaidAds = createSelector([paidAdsSelector], (paidAds) => {
  return {
    paidAds,
  }
})

export const filterPaidAdsByBrandSelector = createSelector(
  [
    brandsForCurrentRetailerAccountSelector,
    currentBrandSelector,
    paidAdsSelector,
  ],
  (brandsForCurrentRetailerAccount, currentBrand, paidAds) => {
    let brandIds = []

    if (currentBrand) {
      brandIds.push(String(currentBrand.id))
    } else {
      brandIds.push(
        ...brandsForCurrentRetailerAccount.map((brand) => String(brand.id)),
      )
    }

    return paidAds.filter((paidAd) =>
      brandIds.includes(String(paidAd.brand_id)),
    )
  },
)

export const sortBrandPaidAdsByStartDateSelector = createSelector(
  [filterPaidAdsByBrandSelector],
  (paidAds) => {
    return paidAds.sort((currPaidAd, nextPaidAd) => {
      const currPaidAdStartDate = moment(currPaidAd.starts_at)
      const nextPaidAdStartDate = moment(nextPaidAd.starts_at)

      if (currPaidAdStartDate.isAfter(nextPaidAdStartDate)) {
        return 1
      } else if (currPaidAdStartDate.isBefore(nextPaidAdStartDate)) {
        return -1
      }

      return 0
    })
  },
)

const getLocations = (retailerId, locationsByRetailerId) => {
  return (
    (locationsByRetailerId[retailerId] &&
      locationsByRetailerId[retailerId].response) ||
    []
  )
}

const buildActivateUrl = (slugs, id) => {
  return `/retailer/content/${slugs.retailerAccount}/${slugs.brand}/paid-ad/${
    id || ''
  }`
}

export const normalizePaidAdsSelector = createSelector(
  [
    brandNameLogoByIdSelector,
    sortBrandPaidAdsByStartDateSelector,
    retailerIdsByBrandIdSelector,
    locations.retailers.selectors.locationsByRetailerIdSelector,
    slugsByRetailerIdForCurrentRetailerAccountSelector,
  ],
  (
    brandNameLogoById,
    paidAds,
    retailerIdsByBrandId,
    locationsByRetailerId,
    slugsByRetailerId,
  ) => {
    const now = moment()
    return paidAds.map((paidAd) => {
      const retailerId = retailerIdsByBrandId[paidAd.brand_id]
      const locations = getLocations(retailerId, locationsByRetailerId)
      const activateUrl = buildActivateUrl(
        slugsByRetailerId[retailerId],
        paidAd.id,
      )
      let channels = []
      paidAd.campaign_channels.forEach((campaignChannel) => {
        channels.push({
          campaignId: campaignChannel.campaign_id,
          type: campaignChannel.channel,
          channelCampaignId: campaignChannel.channel_campaign_id,
          to: activateUrl,
        })
        if (campaignChannel.channel === 'facebook') {
          channels.push({
            campaignId: campaignChannel.campaign_id,
            type: 'instagram',
            channelCampaignId: campaignChannel.channel_campaign_id,
            to: activateUrl,
          })
        }
      })

      // To test the date-time counting down on a campaign, uncomment this line to give
      // yourself 20 seconds:
      // paidAd.starts_at = `${new Date(new Date().getTime() + 20000).toISOString().slice(0, -5)}Z`

      const normalizedPaidAd = {
        ...paidAd,
        id: paidAd.id,
        retailerId,
        locations,
        title: paidAd.title,
        description: paidAd.objective,
        startDate: paidAd.starts_at,
        endDate: paidAd.ends_at,
        type: 'paidAd',
        thumbnailSrcId: paidAd.thumbnail.source_id,
        channels,
        platforms: paidAd.platforms,
        activateUrl,
        isActivatable:
          paidAd.state === 'published' &&
          paidAd.campaign_invitation.state === 'invited' &&
          (paidAd.campaign_invitation.intended_amount_cents > 0 ||
            paidAd.type === 'retailer_funded') &&
          now.isBefore(paidAd.starts_at) &&
          now.isBefore(paidAd.ends_at),
        isActivated:
          paidAd.campaign_invitation &&
          (paidAd.campaign_invitation.state === 'accepted' ||
            paidAd.campaign_invitation.state === 'failed' || //This is activated, but has failed onthe backend along the way. We need Backend developer intervention.
            paidAd.campaign_invitation.state === 'pending'),
        creditAmount: paidAd.campaign_invitation
          ? paidAd.campaign_invitation.intended_amount_cents
          : 0,
        ...brandNameLogoById[paidAd.brand_id],
      }

      return normalizedPaidAd
    })
  },
)

export const paidAdsByIdSelector = createSelector(
  [normalizePaidAdsSelector],
  (paidAds) => {
    return paidAds.reduce((accumulator, paidAd) => {
      accumulator[paidAd.id] = paidAd
      return accumulator
    }, {})
  },
)

export const activatedPaidAdsSelector = createSelector(
  [normalizePaidAdsSelector],
  (normalizedPaidAds) => {
    const now = moment()
    const activatedPaidAds = normalizedPaidAds.filter(
      (paidAd) => now.isAfter(paidAd.startDate) && paidAd.isActivated,
    )
    return activatedPaidAds
  },
)

export const activatedPaidAdsCountSelector = createSelector(
  [activatedPaidAdsSelector],
  (paidAds) => {
    return paidAds.length
  },
)

export const upcomingActivatedPaidAdsSelector = createSelector(
  [normalizePaidAdsSelector],
  (paidAds) => {
    const now = moment()
    return paidAds.filter(
      (paidAd) =>
        now.isBefore(paidAd.endDate) &&
        now.isBefore(paidAd.startDate) &&
        paidAd.isActivated,
    )
  },
)

export const upcomingActivatedPaidAdsCountSelector = createSelector(
  [upcomingActivatedPaidAdsSelector],
  (paidAds) => {
    return paidAds.length
  },
)

export const availablePaidAdsSelector = createSelector(
  [normalizePaidAdsSelector],
  (paidAds) => {
    return paidAds.filter((paidAd) => paidAd.isActivatable)
  },
)

export const availablePaidAdsByDateSelector = createSelector(
  [availablePaidAdsSelector],
  (paidAds) => {
    return [...paidAds].sort((a, b) => {
      a = new Date(a.startDate).valueOf()
      b = new Date(b.startDate).valueOf()
      return b - a
    })
  },
)

export const availablePaidAdsCountSelector = createSelector(
  [availablePaidAdsSelector],
  (paidAds) => {
    return paidAds.length
  },
)

export const nonFeaturedAvailablePaidAdsSelector = createSelector(
  [availablePaidAdsSelector],
  (paidAds) => {
    return paidAds.slice(1, paidAds.length)
  },
)

export const nonFeaturedAvailablePaidAdsCountSelector = createSelector(
  [nonFeaturedAvailablePaidAdsSelector],
  (paidAds) => {
    return paidAds.length
  },
)

export const firstPremiumPaidAdSelector = createSelector(
  [availablePaidAdsSelector],
  (paidAds) => {
    return paidAds[0]
  },
)

export const selectPaidAdsById = createSelector(
  [paidAdsByIdSelector],
  (paidAdsById) => ({
    paidAdsById,
  }),
)

export const selectPaidAdByRouteId = createSelector(
  [normalizePaidAdsSelector],
  (paidAds) => {
    let pathname
    const { location } = history.instance()
    if (location) {
      pathname = location.pathname
    }

    if (pathname) {
      const paidAdId = pathname
        .substring(pathname.lastIndexOf('/') + 1)
        .split(/[?#]/)[0]
      const campaign = paidAds.find((paidAd) => paidAd.id === paidAdId)
      return {
        campaign,
      }
    }
    return null
  },
)

export const selectFeaturedPremiumPaidAdData = createSelector(
  [firstPremiumPaidAdSelector],
  (featuredAdData = {}) => {
    return { featuredAdData }
  },
)

export const selectAdWithStartDateLessThanTwoDays = createSelector(
  [firstPremiumPaidAdSelector],
  (firstAd) => {
    const twoDaysFromNow = moment().add(2, 'days')
    const expiringAd =
      firstAd &&
      firstAd.startDate &&
      moment(firstAd.startDate).isBefore(twoDaysFromNow)
        ? firstAd
        : null
    return { expiringAd }
  },
)

export const selectAvailablePaidAdCreditBalanceCents = createSelector(
  [availablePaidAdsSelector],
  (paidAds) => {
    let paidAdCreditBalanceCents = paidAds.reduce((balance, paidAd) => {
      return (balance += paidAd.creditAmount)
    }, 0)

    return {
      paidAdCreditBalanceCents,
    }
  },
)

export const selectNormalizedPaidAds = createSelector(
  [normalizePaidAdsSelector],
  (paidAds) => ({
    paidAds,
  }),
)

export const hasPaidAdsSelector = createSelector(
  [normalizePaidAdsSelector],
  (paidAds) => {
    return paidAds.length > 0
  },
)

export const selectHasPaidAds = createSelector(
  [hasPaidAdsSelector],
  (hasPaidAds) => {
    return {
      hasPaidAds,
    }
  },
)
