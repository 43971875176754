import _refiner from 'refiner-js'

import { IdentifiedUser, AnalyticsTrackEvent } from '@src/analytics'

import AppConfig from '../AppConfig'

import { Service } from './index'

const refiner: Service<Window['_refiner']> = {
  async init() {
    if (AppConfig.REFINER_PROJECT_ID) {
      _refiner('setProject', AppConfig.REFINER_PROJECT_ID)
    }
  },
  loaded() {
    return window._refiner !== undefined
  },
  instance() {
    return _refiner
  },
  identify(user: IdentifiedUser) {
    _refiner('identifyUser', {
      id: user?.id,
      email: user?.email,
    })
  },
  track(details: AnalyticsTrackEvent) {
    const { type } = details
    if (AppConfig.REFINER_PROJECT_ID) {
      _refiner('trackEvent', type)
    }
  },
}
export default refiner
