import { createReducer } from '../../../lib/Utils'

import { SET_TAGS_IN_FILTERS } from './actions'

const initialState = {}

export default createReducer(initialState, {
  [SET_TAGS_IN_FILTERS]: (state, action) => {
    const newState = { ...state, tags: action.payload }
    return newState
  },
})
