import { auth, ad, facebookAds } from '@promoboxx/redux-stores'
import { ofType } from 'redux-observable'
import { of, merge } from 'rxjs'
import { mergeMap, withLatestFrom } from 'rxjs/operators'

import * as actions from '../actions'

export const getJwtFromAccessToken = (actions$) => {
  return actions$.pipe(
    ofType(
      auth.actions.VALIDATE_AUTH_FULFILLED,
      auth.actions.SIGN_IN_FULFILLED,
    ),
    mergeMap((action) => {
      return merge(
        of({
          type: auth.actions.GET_JWT_FROM_ACCESS_TOKEN,
          payload: action.payload.headers,
        }),
      )
    }),
  )
}

export const getJwtFromAccessTokenRejected = (action$, state$) => {
  return action$.pipe(
    ofType(auth.actions.GET_JWT_FROM_ACCESS_TOKEN_REJECTED),
    withLatestFrom(state$),
    mergeMap(() => {
      const { search, hash } = window.location
      return of(actions.updateUrl(`/login${search}${encodeURIComponent(hash)}`))
    }),
  )
}

export const jwtRequestRejected = (action$) => {
  return action$.pipe(
    ofType(
      ad.actions.GET_PAID_ADS_REJECTED,
      facebookAds.actions.ACTIVATE_CAMPAIGN_REJECTED,
    ),
    mergeMap((action) => {
      if (action.payload.status === 401) {
        return merge(
          of({
            type: auth.actions.GET_JWT_FROM_ACCESS_TOKEN,
            payload: {
              retryPayload: action.payload.retryPayload,
            },
          }),
        )
      } else {
        return merge(
          of({
            type: 'NO_OP',
          }),
        )
      }
    }),
  )
}

export const retryFailedRequestAfterJwtRefresh = (action$) => {
  return action$.pipe(
    ofType(auth.actions.GET_JWT_FROM_ACCESS_TOKEN_FULFILLED),
    mergeMap((action) => {
      if (action.payload.retryPayload) {
        return merge(of(action.payload.retryPayload))
      } else {
        return merge(
          of({
            type: 'NO_OP',
          }),
        )
      }
    }),
  )
}
