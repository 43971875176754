import * as React from "react";
import { forwardRef, memo } from "react";
const SvgRetailerHouse = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 129 115", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "#999", d: "M7 7.5V15h115V0H7zM3.2 38.2C1.2 49.2 0 58.5 0 63.7V72h7v43h72V72h28v43h15V72h7v-8.3c0-5.1-1.2-14.7-3.2-25.5L122.6 21H6.5zM64 86v14H22V72h42z" }));
const ForwardRef = forwardRef(SvgRetailerHouse);
const Memo = memo(ForwardRef);
export default Memo;
