import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import I18n from 'i18n-js'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import useLatest from './useLatest'

interface SimpleConfirmDialogUiOptions {
  title?: React.ReactNode
  content?: React.ReactNode
  confirmLabel?: React.ReactNode
  cancelLabel?: React.ReactNode
}

const simpleConfirmDialogContext = createContext<{
  resolveRef: {
    current: (resolveFn: boolean | PromiseLike<boolean>) => void
  }

  ui: SimpleConfirmDialogUiOptions
  setUi: (options: SimpleConfirmDialogUiOptions) => void

  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}>({
  resolveRef: { current: () => {} },
  setUi: () => {},
  setIsOpen: () => {},
  isOpen: false,
  ui: {},
})

export const SimpleConfirmDialogProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [ui, setUi] = useState<SimpleConfirmDialogUiOptions>({})
  const currentResolve = useRef<
    (resolveFn: boolean | PromiseLike<boolean>) => void
  >(() => {})

  return (
    <simpleConfirmDialogContext.Provider
      value={{
        resolveRef: currentResolve,
        setUi,
        setIsOpen,
        isOpen,
        ui,
      }}
    >
      {children}
    </simpleConfirmDialogContext.Provider>
  )
}

export function useSimpleConfirmDialog() {
  const context = useContext(simpleConfirmDialogContext)
  const contextRef = useLatest(context)

  // Cancel the dialog when the component unmounts.
  useEffect(() => {
    const current = contextRef.current

    return () => {
      current.resolveRef.current(false)
      current.setIsOpen(false)
    }
  }, [contextRef])

  return {
    confirm: useCallback(
      (options: SimpleConfirmDialogUiOptions) => {
        contextRef.current.setUi(options)
        contextRef.current.setIsOpen(true)

        return new Promise<boolean>((resolve) => {
          contextRef.current.resolveRef.current = resolve
        })
      },
      [contextRef],
    ),
  }
}

export const SimpleConfirmDialog: React.FC = () => {
  const context = useContext(simpleConfirmDialogContext)

  const content = !context.ui.content ? (
    <DialogContentText>{I18n.t('shared.cannotBeUndone')}</DialogContentText>
  ) : typeof context.ui.content === 'string' ||
    typeof context.ui.content === 'number' ? (
    <DialogContentText>{context.ui.content}</DialogContentText>
  ) : (
    context.ui.content
  )

  return (
    <Dialog
      fullWidth
      open={context.isOpen}
      onClose={() => {
        context.resolveRef.current(false)
        context.setIsOpen(false)
      }}
      TransitionProps={{
        onExited: () => {
          context.setUi({})
        },
      }}
    >
      <DialogTitle>
        {context.ui.title || I18n.t('shared.areYouSure')}
      </DialogTitle>

      <DialogContent>{content}</DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            context.resolveRef.current(false)
            context.setIsOpen(false)
          }}
        >
          {context.ui.cancelLabel || I18n.t('shared.cancel')}
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            context.resolveRef.current(true)
            context.setIsOpen(false)
          }}
        >
          {context.ui.confirmLabel || I18n.t('shared.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
