import * as React from "react";
import { forwardRef, memo } from "react";
const SvgAutomotive = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlSpace: "preserve", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeMiterlimit: 10
}, viewBox: "0 0 233 409", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M928.6 576.3c0 9.3-7.5 16.8-16.8 16.8s-16.8-7.5-16.8-16.8 7.5-16.8 16.8-16.8 16.8 7.5 16.8 16.8", style: {
  fill: "#e0e0e0",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M924.6 565.3c-3.1-3.6-7.6-5.9-12.7-5.9-9.3 0-16.8 7.5-16.8 16.8 0 5.1 2.3 9.7 6 12.8.1-12.9 10.5-23.3 23.5-23.7", opacity: 0.12, style: {
  fill: "#231f20",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M928.6 576.3c0-4.2-1.5-8-4.1-11-12.9.4-23.3 10.8-23.6 23.8 2.9 2.5 6.7 4 10.9 4 9.3 0 16.8-7.5 16.8-16.8", style: {
  fill: "#e0e0e0",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M930.8 576.9c0 9.3-7.5 16.8-16.8 16.8s-16.8-7.5-16.8-16.8 7.5-16.8 16.8-16.8 16.8 7.5 16.8 16.8", style: {
  fill: "#e0e0e0",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M926.7 566c-3.1-3.6-7.6-5.9-12.7-5.9-9.3 0-16.8 7.5-16.8 16.8 0 5.1 2.3 9.7 6 12.8.2-12.9 10.6-23.4 23.5-23.7", opacity: 0.12, style: {
  fill: "#231f20",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M930.8 576.9c0-4.2-1.5-8-4.1-11-12.9.4-23.3 10.8-23.6 23.8 2.9 2.5 6.7 4 10.9 4 9.3 0 16.8-7.5 16.8-16.8", style: {
  fill: "#e0e0e0",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M844.7 593.7c-2.5 0-4.6-2.1-4.6-4.6v-24.4c0-2.5 2.1-4.6 4.6-4.6s4.6 2.1 4.6 4.6v24.4c0 2.6-2.1 4.6-4.6 4.6M862.1 593.7c-2.5 0-4.6-2.1-4.6-4.6v-24.4c0-2.5 2.1-4.6 4.6-4.6s4.6 2.1 4.6 4.6v24.4c0 2.6-2.1 4.6-4.6 4.6M879.4 593.7c-2.5 0-4.6-2.1-4.6-4.6v-24.4c0-2.5 2.1-4.6 4.6-4.6s4.6 2.1 4.6 4.6v24.4c0 2.6-2 4.6-4.6 4.6", style: {
  fill: "#424242",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M899.5 471.1c15.1 11.2 25.7 27.2 30.6 44.8M755.2 537.6l53.5-5.9c6-.7 11-4.7 13.1-10.3l15.3-42.9c1.3-3.8 4.9-6.3 8.9-6.3h8.2", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "#ddeefd",
  strokeWidth: "19.82px"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M853.7 455.4h33.9l4 7.1h-42.2z", style: {
  fill: "#4052b5",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M853.7 455.4h33.9l4 7.1h-42.2z", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "#1a247d",
  strokeWidth: "1.52px"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M909.5 412.9v-15.6c0-4.8-3.9-8.7-8.7-8.7 0-4.3-10.5-8.1-18.8-10.4-14.9-4.2-31.1.6-40.6 12.8-4.8 6.1-7.7 13.7-7.7 21.9", style: {
  fill: "#8a4e2f",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M833.8 412.9v-15.6c0-4.8 3.9-8.7 8.7-8.7 0-4.3 10.5-8.1 18.8-10.4 14.9-4.2 31.1.6 40.6 12.8 4.8 6.1 7.7 13.7 7.7 21.9", style: {
  fill: "#8a4e2f",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M833.8 412.9c0-31.2 18.3-41.6 38.7-41.6s37 16.9 37 41.6z", style: {
  fill: "#8a4e2f",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -648.157 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M871.4 487.6c-4 0-7.3-3.3-7.3-7.3V435h14.6v45.3c0 4.1-3.3 7.3-7.3 7.3", style: {
  fill: "#e0ad7f",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M871.6 456.9c-21 0-37.9-17-37.9-37.9 0-21 17-37.9 37.9-37.9 21 0 37.9 17 37.9 37.9s-17 37.9-37.9 37.9", style: {
  fill: "#e0ad7f",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M833.6 412.9c3.3 0 6 2.7 6 6s-2.7 6-6 6-6-2.7-6-6 2.7-6 6-6", style: {
  fill: "#e0ad7f",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M852.9 416.8c1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2-2.2-1-2.2-2.2 1-2.2 2.2-2.2M890.3 416.8c1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2-2.2-1-2.2-2.2 1-2.2 2.2-2.2", style: {
  fill: "#062640",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M909.5 412.9c3.3 0 6 2.7 6 6s-2.7 6-6 6-6-2.7-6-6 2.7-6 6-6", style: {
  fill: "#e0ad7f",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M859.6 431c6.6 10.8 17.3 10.7 23.9 0", style: {
  fill: "#fff",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "m839.2 392.5-1.6 27.4c-.1 1-.9 1.8-1.9 1.8-1.1 0-1.9-.9-1.9-1.9v-6.9l1.2-9.4zM904.1 392.5l1.6 27.4c.1 1 .9 1.8 1.9 1.8 1.1 0 1.9-.9 1.9-1.9v-6.9l-1.2-9.4z", style: {
  fill: "#8a4e2f",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M878.7 450.1c-.4.8-1 1.6-1.7 2.3-3.1 2.7-7.8 2.5-10.5-.6-.4-.5-.8-1-1.1-1.6 4 2.4 9.2 2.4 13.3-.1", opacity: 0.12, style: {
  fill: "#231f20",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "m908 551.7-3.3-89.2-24.7-.5-5.6 4.5-3 9.5-3.6-9.5-6-4h-22.2v89.2h77.2-79.7", style: {
  fill: "#ddeefd",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "m862.8 458.9-4.6 5.3 2.1 13.9 7.5-11.6zM879.9 458.7l2.8 5.5-.6 14.4-7.7-12.1z", style: {
  fill: "#ddeefd",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M879.4 493.1h17.9", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "#90caf9",
  strokeWidth: "1.09px"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "m851.6 765.2-14-214.9H908l-4.3 214.9h-20.8L877 591.9c-.1-1.4-1.2-2.6-2.7-2.6s-2.7 1.2-2.7 2.7l.8 173.2z", style: {
  fill: "#bd8958",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M907.5 558.6h-6.3l-1.8 207.7h4.3l4.3-214.8zM881 551.2c27 0 25.6-35.3 25.6-35.3l1.4 35.3z", opacity: 0.12, style: {
  fill: "#231f20",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M839.7 558.6h-1.5l-.3-4h1.8zM907.5 558.6h-6.3v-4h6.6z", style: {
  fill: "#4e342e",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M887.8 558.9c1.8 1.8 4.2 2.8 6.8 2.8h10.9M858.5 558.9c-1.8 1.8-4.2 2.8-6.8 2.8h-10.9", opacity: 0.4, style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "#231f20",
  strokeWidth: "1.09px",
  strokeLinecap: "round"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M863.6 554.6H878v4h-14.4z", style: {
  fill: "#4e342e"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M871.8 554.1c1.4 0 2.5 1.1 2.5 2.5s-1.1 2.5-2.5 2.5M870.5 556.6h-1", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "#fbc02d",
  strokeWidth: "1.09px",
  strokeLinecap: "round"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M844.1 554.6h14.4v4h-14.4zM882.2 554.6h14.4v4h-14.4z", style: {
  fill: "#4e342e"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M849.8 512.8c-.8.3-.5 1.5.4 1.3 5.2-.7 13.3-3.2 20.9-3.2 7.3 0 16 2.4 21.1 3.1.8.1 1.1-1 .3-1.4-5.2-2.1-13.8-5-21.4-5-7.7.1-16.2 3.1-21.3 5.2", opacity: 0.12, style: {
  fill: "#231f20",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "m836 525.1.9-57.1c.1-3.9 3.2-7 7.1-7h10.2l18.2 46.9v43.8H837z", style: {
  fill: "#1a247d",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "m908.3 524.1-1.2-56.1c-.1-3.9-3.2-7-7.1-7h-8.4l-20.2 46.9v43.8h36.9z", style: {
  fill: "#1a247d",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "m853.7 455.4 9.1 10.5 8.6 42-22-45.4M887.3 455.4l-7.1 10.5-8.6 42 20-45.4", style: {
  fill: "#1a247d",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "m863.2 468.2 8.2 39.7v44M879.2 468.2l-8.1 39.5M881.2 491v21.3M896.9 522.1c.6 10.6 3.3 17.1 8 19.7M850.9 522.1c-.6 10.6-3.3 17.1-8 19.7", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "#4052b5",
  strokeWidth: "1.52px",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "m874.4 466.5-3 9.5 5.2-6.1zM867.8 466.5l3.6 9.5-6.6-5.9z", opacity: 0.12, style: {
  fill: "#231f20",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M872 765.1h-20.7c0 9.3-16.4 11.5-26.6 12.7-4.5.5-7.8 5.4-7.8 9.9l55.1-2.4z", style: {
  fill: "#323232",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M816.1 785.9v2.9h16.5c8.5 0 11.2-1.8 20.7-1.8 5.8 0 0 0 0 0l3.1 1.8H872V783c-30.7 0-20.2 2.9-55.9 2.9", style: {
  fill: "#323232",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M903.6 765.1h-20.7c0 9.3-16.4 11.5-26.6 12.7-4.5.5-7.8 5.4-7.8 9.9l55.1-2.4z", style: {
  fill: "#323232",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M847.7 785.9v2.9h16.5c8.5 0 11.2-1.8 20.7-1.8 5.8 0 0 0 0 0l3.1 1.8h15.7V783c-30.9 0-20.3 2.9-56 2.9", style: {
  fill: "#323232",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "m754.5 542.1 15.6-1.7", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "#90caf9",
  strokeWidth: "1.09px"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "m959.4 560.7-18.7 40.6c-1.4 3-5 4.3-7.9 2.9l-55.6-26c-3-1.4-4.3-4.9-2.9-7.9l18.7-40.6c1.4-3 5-4.3 7.9-2.9l55.6 26c3 1.4 4.3 4.9 2.9 7.9", style: {
  fill: "#914941",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "m953.9 557.8-18.6 40.3-56.7-26.2 18.6-40.3z", style: {
  fill: "#fff",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M895.5 538c-2.1-1-4.6 0-5.6 2.1l-.5 1.1c-.7 1.5-2.1 2.3-3.7 2.5-2 .2-3.9 1.4-4.8 3.4s-.6 4.2.6 5.8c1 1.3 1.3 3 .6 4.4l-.5 1.1c-1 2.1 0 4.6 2.1 5.6l2 .9 11.8-25.9zm-11.3 12.1c-.9-.1-1.5-1-1.4-1.9s1-1.5 1.9-1.4 1.5 1 1.4 1.9-.9 1.6-1.9 1.4", style: {
  fill: "#e0e0e0",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M927 569.3c-1.2 3.1-3.4 7.2-4.8 10.1", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "#e0ad7f",
  strokeWidth: "19.82px"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M930.1 515.9c3 10.8 3.8 22.2 2.3 33.5-1.2 8.4-3.6 16.6-7.3 24.3", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "#ddeefd",
  strokeWidth: "19.82px"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M922.2 573.5c-7.6-2.2-15.6 2.2-17.8 9.8l-1 3.5 27.5 8 1-3.5c2.3-7.6-2.1-15.6-9.7-17.8", style: {
  fill: "#e0ad7f",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M921.6 588.7c-2.1-.6-4.3.6-4.9 2.7l-3.6 7.8c-.6 2.1.6 4.3 2.7 4.9s4.3-.6 4.9-2.7l3.6-7.8c.6-2.1-.6-4.3-2.7-4.9", style: {
  fill: "#e0ad7f",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M928.2 590.9c-2.1-.6-4.3.6-4.9 2.7l-2.6 5.9c-.6 2.1.6 4.3 2.7 4.9s4.3-.6 4.9-2.7l2.6-5.9c.7-2.1-.6-4.3-2.7-4.9", style: {
  fill: "#e0ad7f",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "m934.2 561.1-5.4 14.8", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "#90caf9",
  strokeWidth: "1.09px"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M914 586.4c-2.1-.6-4.3.6-4.9 2.7l-3.6 7.8c-.6 2.1.6 4.3 2.7 4.9s4.3-.6 4.9-2.7l3.6-7.8c.6-2-.6-4.2-2.7-4.9", style: {
  fill: "#e0ad7f",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M908.1 583.4c-2.1-.6-4.3.6-4.9 2.7l-3.6 7.8c-.6 2.1.6 4.3 2.7 4.9s4.3-.6 4.9-2.7l3.6-7.8c.6-2.1-.6-4.3-2.7-4.9", style: {
  fill: "#e0ad7f",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }), /* @__PURE__ */ React.createElement("path", { d: "M916.4 576.2c-1-2-3.3-2.8-5.3-1.9l-17 4.9c-2 1-2.8 3.3-1.9 5.3 1 2 3.3 2.8 5.3 1.9l17-4.9c2-.9 2.9-3.3 1.9-5.3M754.4 530.6l-.9-.5c.1-3-1.3-6.1-3.9-8l-14.7-10.7c-1.3-.9-3.1-.7-4.1.7-.9 1.3-.7 3.1.7 4.1l8.3 6-.2.2-4.8-1.4c-.1 0-.1-.1-.2-.1l-1-.3-4.1-1.2v.1l-14-3.8c-1.4-.4-2.9.5-3.3 1.9s.5 2.9 1.9 3.3l15.9 4.3.1 1.5-17.3-4.7c-1.4-.4-2.9.5-3.3 1.9s.5 2.9 1.9 3.3l19.2 5.3.1 1-16.3-4.4c-1.4-.4-2.9.5-3.3 1.9s.5 2.9 1.9 3.3l17.1 4.7c.4.1.7.1 1.1.1l.2 1.8-13.2-3.4c-1.4-.4-2.9.5-3.3 1.9s.5 2.9 1.9 3.3l15.2 3.9 3 1.1c2.1.8 4.3 1.1 6.5.9l10.9-.8 3.5-.2z", style: {
  fill: "#e0ad7f",
  fillRule: "nonzero"
}, transform: "matrix(.91295 0 0 .948 -646.331 -344.566)" }));
const ForwardRef = forwardRef(SvgAutomotive);
const Memo = memo(ForwardRef);
export default Memo;
