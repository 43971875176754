import * as React from "react";
import { forwardRef, memo } from "react";
const SvgTall = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 232.94 408.6", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "a" }, /* @__PURE__ */ React.createElement("path", { d: "M107.88 74.55h13.43v4.2h-13.43z", style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("clipPath", { id: "b" }, /* @__PURE__ */ React.createElement("path", { d: "M123.61 140.79h27.17v35.46h-27.17z", style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("clipPath", { id: "d" }, /* @__PURE__ */ React.createElement("path", { d: "M129.85 183.4h18.88v3.91h-18.88z", style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("clipPath", { id: "f" }, /* @__PURE__ */ React.createElement("path", { d: "M82.62 183.4h18.88v3.91H82.62z", style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("clipPath", { id: "h" }, /* @__PURE__ */ React.createElement("path", { d: "M91.84 132.53h43.79v6.41H91.84z", style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("clipPath", { id: "j" }, /* @__PURE__ */ React.createElement("path", { d: "M168.7 150.41h14.25v53.46H168.7z", style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("clipPath", { id: "l" }, /* @__PURE__ */ React.createElement("path", { d: "M141.32 175.38h8.66v205.95h-8.66z", style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("clipPath", { id: "n" }, /* @__PURE__ */ React.createElement("path", { d: "M141.32 175.38h8.66v205.95h-8.66z", style: {
  fill: "none"
} }))), /* @__PURE__ */ React.createElement("g", { style: {
  isolation: "isolate"
} }, /* @__PURE__ */ React.createElement("path", { d: "M161.07 405.72c0 1.59-24.92 2.88-55.66 2.88s-55.66-1.29-55.66-2.88 24.92-2.88 55.66-2.88 55.66 1.29 55.66 2.88", style: {
  fill: "#d1d3d4"
} }), /* @__PURE__ */ React.createElement("path", { d: "m29.61 119.79 26.73 30.78a3.86 3.86 0 0 0 6.56-1.24l18.68-52.56h15", style: {
  fill: "none",
  stroke: "#deeefa",
  strokeMiterlimit: 10,
  strokeWidth: "19.9235px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M11.32 108.27a2.78 2.78 0 0 1-3.7-1.27L.28 92.39a2.77 2.77 0 1 1 5-2.42l7.34 14.6a2.77 2.77 0 0 1-1.28 3.7M28.05 95.43a2.77 2.77 0 0 1-3.7-1.29l-9.13-18.57a2.77 2.77 0 1 1 5-2.42l9.13 18.58a2.78 2.78 0 0 1-1.28 3.7M16 101.73a2.78 2.78 0 0 1-3.7-1.28L4.24 83.84a2.77 2.77 0 1 1 5-2.42L17.28 98a2.77 2.77 0 0 1-1.28 3.7M21.54 98.15a2.79 2.79 0 0 1-3.71-1.29l-9.27-19.1a2.77 2.77 0 0 1 5-2.42l9.27 19.1a2.79 2.79 0 0 1-1.28 3.71", style: {
  fill: "#e0ae7f"
} }), /* @__PURE__ */ React.createElement("path", { d: "m7.62 107 1.18 3.1a16.45 16.45 0 0 0 11 10l15-16.63L27 86.7Z", style: {
  fill: "#e0ae7f"
} }), /* @__PURE__ */ React.createElement("path", { d: "M21.44 106.73a9.06 9.06 0 0 1 7.38-10.47l3.73-.65L30.72 85a3 3 0 0 1 6-1L40 102.69a9.88 9.88 0 0 1-8 11.42 9.06 9.06 0 0 1-10.47-7.38", style: {
  fill: "#e0ae7f"
} }), /* @__PURE__ */ React.createElement("path", { d: "M142.35 97a83.64 83.64 0 0 1 73.27 92.94", style: {
  fill: "none",
  stroke: "#e0ae7f",
  strokeMiterlimit: 10,
  strokeWidth: "19.9235px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M142.35 97a84.38 84.38 0 0 1 67.83 51.86 83.3 83.3 0 0 1 5.82 24.83", style: {
  fill: "none",
  stroke: "#deeefa",
  strokeMiterlimit: 10,
  strokeWidth: "19.9235px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M96.16 79.92h34.03l4.06 7.11H91.84z", style: {
  fill: "#465b65"
} }), /* @__PURE__ */ React.createElement("path", { d: "M96.16 79.92h34.03l4.06 7.11H91.84z", style: {
  fill: "none",
  stroke: "#bdbdbd",
  strokeMiterlimit: 10,
  strokeWidth: "1.52434px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M152.29 37.22V21.56a8.76 8.76 0 0 0-8.76-8.76A12.8 12.8 0 0 0 130.74 0h-17.41A37.23 37.23 0 0 0 76.1 37.22", style: {
  fill: "#8b4f2f"
} }), /* @__PURE__ */ React.createElement("path", { d: "M76.1 37.22C76.1 2.91 92.64 0 113.2 0h1.8a37.21 37.21 0 0 1 37.22 37.22Z", style: {
  fill: "#8b4f2f"
} }), /* @__PURE__ */ React.createElement("path", { d: "M113.91 112.32a7.34 7.34 0 0 1-7.34-7.34V59.42h14.68V105a7.34 7.34 0 0 1-7.34 7.34", style: {
  fill: "#e0ae7f"
} }), /* @__PURE__ */ React.createElement("path", { d: "M114.12 81.42a38.15 38.15 0 1 1 38.14-38.15 38.14 38.14 0 0 1-38.14 38.15", style: {
  fill: "#e0ae7f"
} }), /* @__PURE__ */ React.createElement("path", { d: "M76 37.22a6 6 0 1 1-6 6.05 6.05 6.05 0 0 1 6-6.05", style: {
  fill: "#e0ae7f"
} }), /* @__PURE__ */ React.createElement("path", { d: "M95.31 41.08a2.2 2.2 0 1 1-2.19 2.19 2.2 2.2 0 0 1 2.19-2.19M132.93 41.08a2.2 2.2 0 1 1-2.2 2.19 2.19 2.19 0 0 1 2.2-2.19", style: {
  fill: "#062640"
} }), /* @__PURE__ */ React.createElement("path", { d: "M152.26 37.22a6 6 0 1 1-6 6.05 6.05 6.05 0 0 1 6-6.05", style: {
  fill: "#e0ae7f"
} }), /* @__PURE__ */ React.createElement("path", { d: "M102.09 61.49c6.6 10.9 17.36 10.78 24.06 0", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M115.07 0c-14 0-26.17 9.89-26.17 9.89s12.79 20.35 24.43 8.72c13.37-13.38 28.49-1.74 28.49-1.74S131.35 0 115.07 0M79.59 26.75C76.68 32 77.85 43 77.85 43H76.1v-5.78l1.21-9.43ZM148.8 26.75C151.71 32 150.55 43 150.55 43h1.74v-5.78l-1.2-9.43Z", style: {
  fill: "#8b4f2f"
} }), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.11999499999999999,
  mixBlendMode: "multiply"
} }, /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#a)"
} }, /* @__PURE__ */ React.createElement("path", { d: "M121.3 74.55a7.4 7.4 0 0 1-1.74 2.3 7.51 7.51 0 0 1-10.6-.62 7 7 0 0 1-1.08-1.6 13 13 0 0 0 13.42-.08", style: {
  fill: "#231f20"
} }))), /* @__PURE__ */ React.createElement("path", { d: "m150.77 176.75-3.31-89.71-24.89-.51-8.96 17.77-9.33-17.27-22.34.01v89.71h77.67-80.15", style: {
  fill: "#deeefa"
} }), /* @__PURE__ */ React.createElement("path", { d: "m105.3 83.48-4.66 5.25 2.12 14.05 7.62-11.68zM122.46 83.2l2.85 5.53-.61 14.53-7.72-12.16z", style: {
  fill: "#deeefa"
} }), /* @__PURE__ */ React.createElement("path", { d: "M122.03 117.82h18", style: {
  fill: "none",
  stroke: "#95c8ec",
  strokeMiterlimit: 10,
  strokeWidth: "1.09207px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M94.07 381.33 80 175.38h70.75l-4.35 206h-20.92l-5.89-164.15a2.67 2.67 0 0 0-5.34.1l.76 164Z", style: {
  fill: "#377fad"
} }), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.11999499999999999,
  mixBlendMode: "multiply"
} }, /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#b)"
} }, /* @__PURE__ */ React.createElement("path", { d: "M123.61 176.24c27.16 0 25.72-35.46 25.72-35.46l1.44 35.46Z", style: {
  fill: "#231f20"
} }))), /* @__PURE__ */ React.createElement("path", { d: "M82.07 183.72h-1.5l-.31-4.05h1.81zM150.2 183.72h-6.34v-4.05h6.67z", style: {
  fill: "#4f352f"
} }), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.39999399999999996
} }, /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#d)"
} }, /* @__PURE__ */ React.createElement("path", { d: "M130.4 183.94h0a9.6 9.6 0 0 0 6.8 2.82h11", style: {
  fill: "none",
  stroke: "#231f20",
  strokeLinecap: "round",
  strokeMiterlimit: 10,
  strokeWidth: "1.09207px"
} }))), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.39999399999999996
} }, /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#f)"
} }, /* @__PURE__ */ React.createElement("path", { d: "M101 183.94h0a9.6 9.6 0 0 1-6.8 2.82h-11", style: {
  fill: "none",
  stroke: "#231f20",
  strokeLinecap: "round",
  strokeMiterlimit: 10,
  strokeWidth: "1.09207px"
} }))), /* @__PURE__ */ React.createElement("path", { d: "M106.12 179.67h14.46v4.05h-14.46z", style: {
  fill: "#4f352f"
} }), /* @__PURE__ */ React.createElement("path", { d: "M114.31 179.14a2.56 2.56 0 1 1 0 5.11M113.02 181.69h-1", style: {
  fill: "none",
  stroke: "#f9c02d",
  strokeLinecap: "round",
  strokeMiterlimit: 10,
  strokeWidth: "1.09207px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M86.49 179.67h14.46v4.05H86.49zM124.83 179.67h14.46v4.05h-14.46z", style: {
  fill: "#4f352f"
} }), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.11999499999999999,
  mixBlendMode: "multiply"
} }, /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#h)"
} }, /* @__PURE__ */ React.createElement("path", { d: "M92.27 137.58a.71.71 0 0 0 .36 1.35c5.27-.68 13.4-3.2 21-3.2 7.36 0 16.06 2.47 21.21 3.14a.71.71 0 0 0 .35-1.36c-5.26-2.09-13.92-5-21.56-5s-16.26 3-21.34 5.05", style: {
  fill: "#231f20"
} }))), /* @__PURE__ */ React.createElement("path", { d: "m78.38 150.01 1.02-64.46h17.27l18.28 47.2v44H79.4z", style: {
  fill: "#bdbdbd"
} }), /* @__PURE__ */ React.createElement("path", { d: "m151.01 149-1.35-63.45h-15.41l-20.32 47.2v44h37.08zM96.16 79.92l9.11 10.53 8.66 42.3-22.09-45.72", style: {
  fill: "#bdbdbd"
} }), /* @__PURE__ */ React.createElement("path", { d: "m129.93 79.92-7.09 10.53-8.66 42.3 20.07-45.72", style: {
  fill: "#bdbdbd"
} }), /* @__PURE__ */ React.createElement("path", { d: "m105.3 90.59 8.63 42.16v44.18M122.31 90.34l-8.63 42.15M123.84 115.73v21.37M139.62 147q1 16 8 19.8M93.33 147q-1 16-8.06 19.8", style: {
  fill: "none",
  stroke: "#465b65",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "1.52434px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M124.85 119.79h-2.03l1.02-3.04zM141.11 150.27h-2.04l1.02-3.05zM94.38 150.27h-2.03l1.01-3.05z", style: {
  fill: "none",
  stroke: "#f05323",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "1.52434px"
} }), /* @__PURE__ */ React.createElement("path", { d: "m116.98 91.1-3.55 14.73 3.04-2.54 2.67-8.79z", style: {
  fill: "#deeefa"
} }), /* @__PURE__ */ React.createElement("path", { d: "m110.38 91.1 3.68 14.73-3.05-2.54-3.68-8.64z", style: {
  fill: "#deeefa"
} }), /* @__PURE__ */ React.createElement("path", { d: "M146.61 146.13h60.58v60.58h-60.58z", style: {
  fill: "#bd8958"
}, transform: "rotate(-13.6 176.967 176.455)" }), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.11999499999999999,
  mixBlendMode: "multiply"
} }, /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#j)"
} }, /* @__PURE__ */ React.createElement("path", { d: "m169.77 151.48 12.12 51.31", style: {
  fill: "none",
  stroke: "#231f20",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2.13863px"
} }))), /* @__PURE__ */ React.createElement("path", { d: "M213.43 174.92a14.41 14.41 0 0 0-10.62 17.4l.87 3.59 28-6.78-.87-3.59a14.42 14.42 0 0 0-17.41-10.62", style: {
  fill: "#e0ae7f"
} }), /* @__PURE__ */ React.createElement("path", { d: "M206 190.56a4 4 0 0 0-2.93 4.81l.75 8.66a4 4 0 1 0 7.75-1.88l-.75-8.66a4 4 0 0 0-4.82-2.93", style: {
  fill: "#e0ae7f"
} }), /* @__PURE__ */ React.createElement("path", { d: "M209.72 180.18a4 4 0 0 0-5.54 1L191.82 194a4 4 0 0 0 6.58 4.51l12.36-12.76a4 4 0 0 0-1-5.55M220.47 188.42a4 4 0 0 0-2.94 4.81l.75 8.66A4 4 0 1 0 226 200l-.75-8.65a4 4 0 0 0-4.81-2.94", style: {
  fill: "#e0ae7f"
} }), /* @__PURE__ */ React.createElement("path", { d: "M227.35 187a4 4 0 0 0-2.94 4.82l.66 6.43a4 4 0 1 0 7.76-1.88l-.67-6.43a4 4 0 0 0-4.81-2.94", style: {
  fill: "#e0ae7f"
} }), /* @__PURE__ */ React.createElement("path", { d: "M114.56 381.24H93.73c0 9.34-16.51 11.55-26.72 12.73-4.49.52-7.88 5.44-7.88 10l55.43-2.4Z", style: {
  fill: "#333232"
} }), /* @__PURE__ */ React.createElement("path", { d: "M58.34 402.12V405H74.9c8.55 0 11.26-1.76 20.77-1.76l3.16 1.76h15.73v-5.8c-30.92 0-20.27 2.92-56.22 2.92", style: {
  fill: "#333232"
} }), /* @__PURE__ */ React.createElement("path", { d: "M146.32 381.24h-20.83c0 9.34-16.51 11.55-26.73 12.73-4.48.52-7.88 5.44-7.88 10l55.44-2.4Z", style: {
  fill: "#333232"
} }), /* @__PURE__ */ React.createElement("path", { d: "M90.1 402.12V405h16.56c8.54 0 11.25-1.76 20.76-1.76l3.16 1.76h15.74v-5.8c-30.92 0-20.28 2.92-56.22 2.92", style: {
  fill: "#333232"
} }), /* @__PURE__ */ React.createElement("path", { d: "m26.04 122.34 11.13 11.14", style: {
  fill: "none",
  stroke: "#95c8ec",
  strokeMiterlimit: 10,
  strokeWidth: "1.09207px"
} }), /* @__PURE__ */ React.createElement("path", { d: "m20.64 123.83-8.14-7.99 21.05-10.05 1.83 8.07-12.15 12.56zM212.71 190.29a4 4 0 0 0-2.94 4.82l.75 8.66a4 4 0 1 0 7.76-1.88l-.75-8.66a4 4 0 0 0-4.82-2.94", style: {
  fill: "#e0ae7f"
} }), /* @__PURE__ */ React.createElement("path", { d: "m217.65 158.07 2.69 15.62", style: {
  fill: "none",
  stroke: "#95c8ec",
  strokeMiterlimit: 10,
  strokeWidth: "1.09207px"
} }), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.11999499999999999,
  mixBlendMode: "multiply"
} }, /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#l)"
} }, /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#n)"
} }, /* @__PURE__ */ React.createElement("path", { d: "M149.4 182.62h-6.34l-1.74 198.71h4.3l4.36-205.95z", style: {
  fill: "#231f20"
} }))))));
const ForwardRef = forwardRef(SvgTall);
const Memo = memo(ForwardRef);
export default Memo;
