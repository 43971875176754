import * as React from "react";
import { forwardRef, memo } from "react";
const SvgTwitter = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 22 22", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("circle", { cx: 11, cy: 11, r: 11, fill: "#1DA1F2" }), /* @__PURE__ */ React.createElement("path", { fill: "#FFF", d: "M8.84 16.5c5.108 0 7.901-4.232 7.901-7.901q0-.18-.008-.359a5.7 5.7 0 0 0 1.385-1.438 5.5 5.5 0 0 1-1.594.437 2.8 2.8 0 0 0 1.22-1.536c-.536.318-1.13.55-1.763.674A2.777 2.777 0 0 0 11.25 8.91a7.88 7.88 0 0 1-5.724-2.9 2.775 2.775 0 0 0 .86 3.707 2.76 2.76 0 0 1-1.258-.348v.036c0 1.345.957 2.468 2.228 2.722a2.8 2.8 0 0 1-1.254.048 2.78 2.78 0 0 0 2.594 1.928 5.57 5.57 0 0 1-4.112 1.15A7.86 7.86 0 0 0 8.84 16.5" }));
const ForwardRef = forwardRef(SvgTwitter);
const Memo = memo(ForwardRef);
export default Memo;
