import { GET_STATS_FULFILLED } from '@promoboxx/redux-stores/src/stores/stats/actions'

import { campaignTotals } from '@src/components/reporting/statsTotals'

import { createReducer } from '../../lib/Utils'

import * as actions from './actions'

const initialState = {}

export default createReducer(initialState, {
  [actions.SET_CAMPAIGN_LIST_SIZE]: (state, action) => {
    const { size } = action.payload
    return {
      ...state,
      campaignListSize: size,
    }
  },
  [actions.CONTENT_INITIALIZED]: (state) => {
    return {
      ...state,
      contentInitialized: true,
    }
  },
  [GET_STATS_FULFILLED]: (state, action) => {
    const quickStatsByCampaignId = { ...state.quickStatsByCampaignId }
    action.payload.response.stats.forEach((stat) => {
      const campaignId = stat.data.campaign_id
      if (campaignId && campaignId.length === 36) {
        quickStatsByCampaignId[campaignId] = campaignTotals(
          stat.data.ads,
        ).totals
      } else {
        quickStatsByCampaignId[campaignId] = {
          views: stat.data.ads.views || 0,
          actions: stat.data.ads.actions || 0,
          clicks: stat.data.ads.clicks || 0,
        }
      }
    })
    const newState = { ...state, quickStatsByCampaignId }

    return newState
  },
})
