import {
  retailerAccountsCampaignsSearch,
  retailersCampaignsSearch,
} from '@promoboxx/redux-stores'
import { ofType } from 'redux-observable'
import { of, merge } from 'rxjs'
import { mergeMap, debounceTime, withLatestFrom } from 'rxjs/operators'

import { SELECT_RETAILER_ACCOUNT, SELECT_BRAND } from '../../app/actions'
import {
  currentRetailerAccountIdSelector,
  isAllBrandsSelector,
} from '../../app/selectors'
import { defaultCampaignParams } from '../../content/consts'
import { currentRetailerIdSelector } from '../../retailers/selectors'

import * as actions from './actions'
import { tagsForCurrentRetailerAccountSelector } from './selectors'

export const filterParamsChanged = (action$, state$) => {
  return action$.pipe(
    ofType(actions.FILTER_PARAMS_CHANGED),
    debounceTime(500),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const searchParamsObject = { ...defaultCampaignParams, ...action.payload }
      const isAllBrands = isAllBrandsSelector(state)
      const retailerAccountId = currentRetailerAccountIdSelector(state)
      const retailerId = currentRetailerIdSelector(state)
      let search
      if (isAllBrands) {
        search =
          retailerAccountsCampaignsSearch.actions.searchCampaignsByRetailerAccount(
            {
              ...searchParamsObject,
              retailerAccountId,
            },
          )
      } else {
        search = retailersCampaignsSearch.actions.searchCampaignsByRetailer({
          ...searchParamsObject,
          retailerId,
        })
      }
      return [search]
    }),
  )
}

export const setTagsInFilters = (action$, state$) => {
  return action$.pipe(
    ofType(
      retailerAccountsCampaignsSearch.actions
        .SEARCH_CAMPAIGNS_BY_RETAILER_ACCOUNT_FULFILLED,
      retailersCampaignsSearch.actions.SEARCH_CAMPAIGNS_BY_RETAILER_FULFILLED,
    ),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      // TODO: if total amount of campaigns is greater than the page size
      // get the rest of the pages
      let tags = tagsForCurrentRetailerAccountSelector(state)
      if (!tags) {
        tags = []
        action.payload.response.forEach((campaign) => {
          if (campaign.tags && campaign.tags.length > 0) {
            campaign.tags.forEach((tag) => {
              if (!tags.includes(tag)) {
                tags.push(tag)
              }
            })
          }
        })
        return merge(of({ type: actions.SET_TAGS_IN_FILTERS, payload: tags }))
      }
      return merge(of({ type: actions.FILTER_TAGS_ALREADY_SET }))
    }),
  )
}

export const resetTagsInFilters = (action$) => {
  return action$.pipe(
    ofType(SELECT_RETAILER_ACCOUNT, SELECT_BRAND),
    mergeMap(() => {
      return merge(
        of({
          type: actions.SET_TAGS_IN_FILTERS,
        }),
      )
    }),
  )
}
