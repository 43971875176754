import { useMutation, gql } from '@apollo/client'
import {
  GET_JWT_FROM_ACCESS_TOKEN_FULFILLED,
  ADD_JWT_TO_STATE,
  VALIDATE_AUTH_FULFILLED,
  VALIDATE_AUTH_REJECTED,
} from '@promoboxx/redux-stores/src/stores/auth/actions'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { finishSetup } from '@src/config/I18n'
import loadCrowdinTranslations from '@src/i18n/loadCrowdinTranslations'
import { userSelector } from '@src/stores/app/selectors'

import { jwtRef } from '../../config/ApolloClient'
import { actions } from '../../stores'
import Loader from '../shared/Loader'

import parsePbxxJwt from './parsePbxxJwt'
import { saneGetUser } from './saneLogin'

const EXTEND_JWT = gql`
  mutation extendJwt($jwt: String!) {
    extendJwt(jwt: $jwt) {
      jwt
    }
  }
`

const AuthHandler = (props) => {
  const dispatch = useDispatch()
  const [extendJwt] = useMutation(EXTEND_JWT)
  const jwt = useSelector((state) => state.auth.jwt)
  const [authValidationComplete, setAuthValidationComplete] = useState(false)
  const user = useSelector(userSelector)
  const [crowdinLoaded, setCrowdinLoaded] = useState(false)
  const [idleTimeout, setIdleTimeout] = useState(false)
  // We need to set this here rather than in an effect, as the effect might run
  // _after_ children that are trying to use `jwtRef` have mounted.
  jwtRef.current = jwt

  useEffect(() => {
    if (jwt) {
      try {
        const decoded = parsePbxxJwt(jwt) //This will throw if your JWT is not well formed, etc
        if (decoded) {
          const now = new Date().valueOf() / 1000
          const ttl = (decoded.exp - now - 100) * 1000 // 100s before expiration
          const notExpired = now < decoded.exp
          if (notExpired && !idleTimeout) {
            setIdleTimeout(
              setTimeout(async () => {
                const { data } = await extendJwt({
                  variables: {
                    jwt,
                  },
                })

                if (data && data.extendJwt) {
                  dispatch({
                    type: GET_JWT_FROM_ACCESS_TOKEN_FULFILLED,
                    payload: { response: data.extendJwt },
                  })
                }
                setIdleTimeout(false)
              }, ttl),
            )
          } else if (!notExpired) {
            clearTimeout(idleTimeout)
            dispatch(actions.auth.signOut())
          }
        }
      } catch (error) {
        console.error(error)
      }
    }
  }, [jwt, idleTimeout, dispatch, extendJwt])

  useEffect(() => {
    async function run() {
      const response = await saneGetUser()

      if (response) {
        jwtRef.current = response.jwt
        dispatch({
          type: ADD_JWT_TO_STATE,
          payload: {
            jwt: response.jwt,
          },
        })

        // Pretty sure this still triggers something to get a jwt, even though
        // we've already got one. Good ol' programming key flows of an app via
        // side effects ...
        dispatch({
          type: VALIDATE_AUTH_FULFILLED,
          payload: {
            // Random selectors modify data in place :/, and gql responses are
            // read-only.
            user: JSON.parse(JSON.stringify(response.user)),
            headers: {
              'access-token': response.authHeaders.accessToken,
              'token-type': response.authHeaders.tokenType,
              'client': response.authHeaders.client,
              'expiry': response.authHeaders.expiry,
              'uid': response.authHeaders.uid,
            },
          },
        })
      } else {
        dispatch({
          type: VALIDATE_AUTH_REJECTED,
        })
      }

      setAuthValidationComplete(true)
    }

    if (!authValidationComplete) {
      run()
    }
  }, [authValidationComplete, dispatch])

  useEffect(() => {
    if (authValidationComplete && crowdinLoaded) {
      finishSetup(user.language_preference)
    }
  }, [user, authValidationComplete, crowdinLoaded])

  useEffect(() => {
    loadCrowdinTranslations()
      .then(() => setCrowdinLoaded(true))
      .catch((error) => {
        console.error(error)
        setCrowdinLoaded(true)
      })
  }, [])

  if (authValidationComplete === true && crowdinLoaded) {
    return props.children
  } else {
    return <Loader />
  }
}

export default AuthHandler
