import * as Facebook from 'fb-sdk-wrapper'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import AppConfig from '../../../config/AppConfig'
import Loader from '../../shared/Loader'

const AppInitializer = (props) => {
  const { children, appReady } = props
  const [facebookInitiated, setFacebookInitiated] = useState(false)

  /*
  Downstream components can import the `fb-sdk-wrapper` and immediately use it
  This whole process was timed and takes 0ms to actually complete once the FB SDK js is loaded and cached.
  */
  useEffect(() => {
    Facebook.load()
      .then(() => {
        Facebook.init({
          appId: AppConfig.FACEBOOK_APP_ID,
        })
        setFacebookInitiated(true)
      })
      .catch((error) => {
        console.error(error)
        setFacebookInitiated(true) //Load app even if we fail here?
      })
  }, [])

  if (appReady === true && facebookInitiated === true) {
    return children
  } else {
    return <Loader />
  }
}

AppInitializer.propTypes = {
  appReady: PropTypes.bool,
  children: PropTypes.node,
}

export default AppInitializer
