import { createSelector } from 'reselect'

import {
  currentRetailerAccountSelector,
  currentBrandIdSelector,
} from '../app/selectors'

export const currentRetailerIdSelector = createSelector(
  [currentBrandIdSelector, currentRetailerAccountSelector],
  (currentBrandId, currentRetailerAccount) => {
    if (currentBrandId === null || typeof currentBrandId === 'undefined') {
      return null
    }
    if (!currentRetailerAccount) {
      return null
    }
    const retailer = currentRetailerAccount.retailers.find((retailer) => {
      return retailer.brand_id === currentBrandId
    })

    return (retailer && retailer.id) || null
  },
)

export const retailersForCurrentRetailerAccount = createSelector(
  [currentRetailerAccountSelector],
  (currentRetailerAccount) => {
    if (currentRetailerAccount) {
      return currentRetailerAccount.retailers
    }
    return []
  },
)

export const slugsByRetailerIdForCurrentRetailerAccountSelector =
  createSelector([currentRetailerAccountSelector], (currentRetailerAccount) => {
    let retailersById = {}
    if (currentRetailerAccount) {
      currentRetailerAccount.retailers.reduce((byRetailerIds, retailer) => {
        byRetailerIds[retailer.id] = {
          retailerAccount: currentRetailerAccount.slug,
          brand: retailer.brand.slug,
        }
        return byRetailerIds
      }, retailersById)
    }

    return retailersById
  })
