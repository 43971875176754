import './config/I18n'
import 'rxjs'
import './style/app.scss'

import { setServiceBaseUrl, setApiBaseUrl } from '@promoboxx/redux-stores'
import { init as darklyFeatureFlagsInit } from '@promoboxx/redux-stores/src/stores/darkly-feature-flags/actions'
import { setConfig } from '@promoboxx/social-preview'
import { setFilterStore } from '@promoboxx/use-filter/dist/store'
import memoryStore from '@promoboxx/use-filter/dist/store/memoryStore'
import Honeybadger from 'honeybadger-js'
import jsCookie from 'js-cookie'
import { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import analytics from '@src/analytics'
import ErrorBoundary from '@src/components/shared/ErrorBoundary'
import services from '@src/config/services'
import { initHistory } from '@src/lib/history'

import App from './components/App'
import { deleteLegacyAuthHeaders } from './components/auth/omniauthHelpers'
import AppConfig, { initConfig } from './config/AppConfig'
import { store, persistor } from './config/Redux'
import { injectAntiAdblock } from './lib/anti-adblock'

setApiBaseUrl(AppConfig.API_BASE)
setServiceBaseUrl(AppConfig.SERVICE_BASE)

injectAntiAdblock({
  jsCookie,
})

// social-preview
setConfig({
  APP_BASE: AppConfig.SOCIAL_PREVIEW_APP_BASE,
})

const AppInstance = () => {
  useEffect(() => {
    //Analytics
    analytics.track({
      event: 'Event',
      type: 'app-launched',
    })

    //LaunchDarkly
    const user = {
      key: 'anonymous@promoboxx.com',
      anonymous: true,
    }
    store.dispatch(
      darklyFeatureFlagsInit({
        id: AppConfig.LAUNCH_DARKLY_APP_ID,
        user,
      }),
    )
  }, [])

  return (
    <Provider store={store}>
      <App persistor={persistor} />
    </Provider>
  )
}

async function main() {
  await initConfig().catch((err) => {
    console.error(err)
    return
  })

  initHistory()

  deleteLegacyAuthHeaders()
  setServiceBaseUrl(AppConfig.SERVICE_BASE)
  setApiBaseUrl(AppConfig.API_BASE)

  setConfig({
    APP_BASE: AppConfig.SOCIAL_PREVIEW_APP_BASE,
  })

  injectAntiAdblock({
    jsCookie,
  })

  //Honeybadger
  const honeybadgerConfig = {
    api_key: AppConfig.HONEYBADGER_API_KEY,
    environment: AppConfig.ENV,
    revision: AppConfig.COMMITHASH,
  }
  const honeybadger = Honeybadger.configure(honeybadgerConfig)

  // redux-stores
  setServiceBaseUrl(AppConfig.SERVICE_BASE)
  setApiBaseUrl(AppConfig.API_BASE)

  // social-preview
  setConfig({
    APP_BASE: AppConfig.SOCIAL_PREVIEW_APP_BASE,
  })

  // change this to urlParamStore once useImprovedBrand FF is removed
  setFilterStore(memoryStore)

  await services.init() //After this point you can confirm that the window.analytics object is hydrated.

  const root = createRoot(document.getElementById('root'))

  // TODO
  // - some magic with .pug files, translating them into bog-standard JS
  // - search for things using `htmlWebpackPlugin.options.config.`, that will now be `AppConfig.`
  root.render(
    <ErrorBoundary honeyBadger={honeybadger}>
      <AppInstance />
    </ErrorBoundary>,
  )
}

main()
