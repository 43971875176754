import {
  ad,
  auth,
  campaigns,
  contentDiscovery,
  facebookAds,
  facebookPages,
  feature_flags,
  localAdBalances,
  locations,
  retailerAccounts,
  retailers,
  retailersCampaignsSearch,
  retailerAccountsCampaignsSearch,
  retailersFacebookPages,
  stats,
} from '@promoboxx/redux-stores'

import * as app from './app/selectors'
import * as appBrands from './brands/selectors'
import * as content from './content/selectors'
import * as retailerFacebookPages from './facebookPages/selectors'
import * as forgotPassword from './forgotPassword/selectors'
import * as mainMenu from './mainMenu/selectors'
import * as paidAds from './paidAds/selectors'
import * as requestAccess from './requestAccess/selectors'
import * as retailerAccountAlerts from './retailerAccounts/alerts/selectors'
import * as retailerAccountAllocationDetails from './retailerAccounts/allocationDetails/selectors'
import * as retailerAccountFilters from './retailerAccounts/filters/selectors'
import * as appRetailerAccounts from './retailerAccounts/selectors'

const selectors = {
  ad: ad.selectors,
  app,
  auth: auth.selectors,
  brands: {
    ...appBrands,
  },
  campaigns: campaigns.selectors,
  content,
  contentDiscovery,
  facebookAds: facebookAds.selectors,
  facebookPages: {
    ...facebookPages.selectors,
    ...retailerFacebookPages,
  },
  featureFlags: feature_flags.selectors,
  forgotPassword,
  mainMenu,
  localAdBalances: localAdBalances.selectors,
  locations: {
    retailers: locations.retailers.selectors,
  },
  paidAds,
  requestAccess,
  retailers: retailers.selectors,
  retailersCampaignsSearch: retailersCampaignsSearch.reducers,
  retailerAccountAlerts,
  retailerAccountAllocationDetails,
  retailerAccountFilters,
  retailerAccounts: {
    ...appRetailerAccounts,
    ...retailerAccounts.selectors,
  },
  retailerAccountsCampaignsSearch: retailerAccountsCampaignsSearch.selectors,
  retailersFacebookPages: retailersFacebookPages.selectors,
  stats: stats.selectors,
}

export default selectors
