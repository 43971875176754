import * as React from "react";
import { forwardRef, memo } from "react";
const SvgDollarsign = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 22 22", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "#3DCC55", fillRule: "evenodd", d: "M11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11S17.075 0 11 0m.869 16.907v1.969H9.977V17.04c-1.293-.057-2.548-.405-3.281-.83l.578-2.258c.811.444 1.95.85 3.205.85 1.1 0 1.853-.425 1.853-1.197 0-.734-.618-1.197-2.046-1.68-2.066-.694-3.475-1.66-3.475-3.532 0-1.699 1.197-3.03 3.262-3.436V3.125h1.892v1.698c1.293.058 2.162.328 2.799.637l-.56 2.181c-.502-.212-1.39-.656-2.78-.656-1.254 0-1.66.54-1.66 1.08 0 .638.676 1.043 2.317 1.661 2.297.81 3.223 1.872 3.223 3.61 0 1.717-1.216 3.184-3.435 3.57" }));
const ForwardRef = forwardRef(SvgDollarsign);
const Memo = memo(ForwardRef);
export default Memo;
