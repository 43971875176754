import * as React from "react";
import { forwardRef, memo } from "react";
const SvgOutdoor = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlSpace: "preserve", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinecap: "round",
  strokeMiterlimit: 10
}, viewBox: "0 0 233 409", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "m247.9 273.2-13.3-40.6", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "#434343",
  strokeWidth: 4
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "m233.1 235.7-26.9 23.2 34.7-.2z", style: {
  fill: "#e5ac23",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M311.4 393.5h-66.7c-7.3 0-13.3-6-13.3-13.3V378c0-7.3 6-13.3 13.3-13.3h66.7c7.3 0 13.3 6 13.3 13.3v2.1c0 7.4-6 13.4-13.3 13.4", style: {
  fill: "#4656a6",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M310 368.4h-60.7c-7.3 0-13.3-6-13.3-13.3v-77c0-7.3 6-13.3 13.3-13.3H310c7.3 0 13.3 6 13.3 13.3v77c0 7.3-6 13.3-13.3 13.3", style: {
  fill: "#5e4138",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M318.1 393.7H238l8.7-93.6c1.4-16.3 15-28.8 31.3-28.8s30 12.5 31.3 28.8z", style: {
  fill: "#f05323",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M321 224.4h-82v-11.8c0-4.6 3.7-8.3 8.3-8.3h65.4c4.6 0 8.3 3.7 8.3 8.3z", style: {
  fill: "#06996d",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M270.5 275.7h17.3v14.6h-17.3z", style: {
  fill: "#e0ac7c"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M348.1 320h21v11.5h-21z", style: {
  fill: "#f05323"
}, transform: "rotate(-84.791 156.323 275.172)scale(.80985)" }), /* @__PURE__ */ React.createElement("path", { d: "m182.2 395.8 14.6-56.3 12.7 3.1-14.6 56.4zM195.1 409.7l-1.7-8.6 5.2-1 1.7 8.6c.3 1.4-.7 2.8-2.1 3.1-1.5.2-2.9-.7-3.1-2.1", style: {
  fill: "#e0ac7c",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M198.3 403c1.3-5.7-2.3-11.3-8-12.6s-11.3 2.3-12.6 8zM176.2 404.9l1.5-6.5 20.6 4.6-1.5 6.5c-.2 1-1.2 1.6-2.1 1.4l-17.1-3.8c-1-.3-1.6-1.3-1.4-2.2", style: {
  fill: "#e0ac7c",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "m189.9 416.3 1.9-8.6 5.2 1.2-1.9 8.6c-.3 1.4-1.7 2.3-3.2 2-1.5-.4-2.4-1.8-2-3.2", style: {
  fill: "#e0ac7c",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "m185.3 412.4 1.9-8.6 5.2 1.2-1.9 8.6c-.3 1.4-1.7 2.3-3.2 2-1.4-.4-2.3-1.8-2-3.2", style: {
  fill: "#e0ac7c",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "m180.5 409.6 1.9-8.6 5.2 1.2-1.9 8.6c-.3 1.4-1.7 2.3-3.2 2-1.4-.4-2.3-1.8-2-3.2", style: {
  fill: "#e0ac7c",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "m175.8 406.6 1.9-8.6 5.2 1.2-1.9 8.6c-.3 1.4-1.7 2.3-3.2 2-1.4-.4-2.3-1.8-2-3.2", style: {
  fill: "#e0ac7c",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "m211.2 340.1 2.9-9.5c.4-1.7 1.4-3.1 2.9-4l34.9-30.4c3-2.6 3.7-7.1 1.6-10.5-2.7-4.3-8.6-5.2-12.4-1.9l-32.9 28.7c-5.3 3.2-9 8.4-10.4 14.4l-2.7 9.4z", style: {
  fill: "#f05323",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M197.5 329.4H209v21h-11.5z", style: {
  fill: "#f05323"
}, transform: "rotate(-72.235 13.574 304.897)scale(.80981)" }), /* @__PURE__ */ React.createElement("path", { d: "m182.7 389.5 15.1 3.8", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "#434343",
  strokeWidth: 3,
  strokeLinecap: "butt"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "m421.4 339.3-57.7-7.2 1.4-12.9 57.8 7.1zM433.5 324.8l-8.3 2.8-1.7-5 8.3-2.8c1.4-.5 2.9.3 3.3 1.7.5 1.3-.3 2.8-1.6 3.3", style: {
  fill: "#e0ac7c",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M349.9 600.2 437 315", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "#434343",
  strokeWidth: 4
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M426.5 322.5c-5.8-.5-11 3.8-11.5 9.6s3.7 10.9 9.6 11.5z", style: {
  fill: "#e0ac7c",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "m431.2 344.1-6.6-.6 1.9-21 6.6.6c1 .1 1.7 1 1.6 1.9l-1.6 17.5c-.1 1-1 1.7-1.9 1.6", style: {
  fill: "#e0ac7c",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "m440.7 329.1-8.8-.8.5-5.3 8.8.8c1.5.1 2.5 1.4 2.4 2.9-.2 1.4-1.5 2.5-2.9 2.4", style: {
  fill: "#e0ac7c",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "m437.4 334.1-8.8-.8.5-5.3 8.8.8c1.5.1 2.5 1.4 2.4 2.9s-1.4 2.5-2.9 2.4", style: {
  fill: "#e0ac7c",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "m435.2 339.2-8.8-.8.5-5.3 8.8.8c1.5.1 2.5 1.4 2.4 2.9s-1.4 2.5-2.9 2.4", style: {
  fill: "#e0ac7c",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M440.8 299.4 437 315l10.2-6.9", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "#434343",
  strokeWidth: 4
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "m432.9 344.3-8.8-.8.5-5.3 8.8.8c1.5.1 2.5 1.4 2.4 2.9-.1 1.4-1.4 2.5-2.9 2.4", style: {
  fill: "#e0ac7c",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "m406.9 413.6-4.5-8.7", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "#434343",
  strokeWidth: 4
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "m362.4 317.8-9.8-1.6c-1.7-.2-3.2-1-4.3-2.3l-34.7-30.7c-3-2.7-7.5-2.8-10.6-.2-4 3.2-4.1 9.2-.3 12.6l32.7 28.9c3.9 4.8 9.5 7.8 15.7 8.5l9.7 1.5z", style: {
  fill: "#f05323",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "m253.8 456.6.7 64.8 1 76", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "#e0ac7c",
  strokeWidth: 14,
  strokeLinecap: "butt"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M266.1 516.7c0 4.3-3.5 7.8-7.8 7.8s-7.8-3.5-7.8-7.8 3.5-7.8 7.8-7.8 7.8 3.5 7.8 7.8", style: {
  fill: "#e0ac7c",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "m294.5 455.5 3.2 56.6-.8 76.4", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "#e0ac7c",
  strokeWidth: 14,
  strokeLinecap: "butt"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M310.2 393.3h-70.7l.6 73.8h31.1v-45.8c0-2.1 1.7-3.8 3.8-3.8s3.8 1.7 3.8 3.8v45.8h31.1z", style: {
  fill: "#656565",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M308 512.1c0 4.3-3.5 7.8-7.8 7.8s-7.8-3.5-7.8-7.8 3.5-7.8 7.8-7.8 7.8 3.5 7.8 7.8", style: {
  fill: "#e0ac7c",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M237.1 459.7h35.4v9.4h-35.4zM276.8 459.7h35.4v9.4h-35.4z", style: {
  fill: "#969696"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M369.9 630.3c0 2.9-26.6 5.2-59.5 5.2-32.8 0-59.5-2.3-59.5-5.2s26.6-5.2 59.5-5.2 59.5 2.4 59.5 5.2", style: {
  fill: "#010101",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M287.9 583.8h18.4v18.4h-18.4z", style: {
  fill: "#4656a6"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M287.9 588.5v2.7l15.6-2.7z", style: {
  fill: "#010101",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M304 581.1h-13.8c-2 0-3.7 1.6-3.7 3.7s1.6 3.7 3.7 3.7H304c2 0 3.7-1.6 3.7-3.7s-1.6-3.7-3.7-3.7", style: {
  fill: "#4086c6",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M304 576.5h-13.8c-2 0-3.7 1.6-3.7 3.7s1.6 3.7 3.7 3.7H304c2 0 3.7-1.6 3.7-3.7s-1.6-3.7-3.7-3.7", style: {
  fill: "#4086c6",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M336.4 616.6c-11.6-1.4-26-6.1-26-16.7 0 0-.6-5.8-6.2-5.8s-6.5 5.8-6.5 5.8c-5.6-5.6-11.1-5.8-11.1-5.8V628h58.8v-2.5c0-8.2-5.2-8.5-9-8.9", style: {
  fill: "#8b4f2f",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M346.3 627.9v3.9h-3.2c0-1.1-.8-1.9-1.9-1.9-1 0-1.9.9-1.9 1.9h-3.5c0-1.1-.8-1.9-1.9-1.9-1 0-1.9.9-1.9 1.9h-3.5c0-1.1-.8-1.9-1.9-1.9-1 0-1.9.9-1.9 1.9h-3.5c0-1.1-.8-1.9-1.9-1.9-1 0-1.9.9-1.9 1.9h-3.5c0-1.1-.8-1.9-1.9-1.9-1 0-1.9.9-1.9 1.9h-3.5c0-1.1-.8-1.9-1.9-1.9s-1.9.9-1.9 1.9h-3.5c0-1.1-.8-1.9-1.9-1.9-1 0-1.9.9-1.9 1.9H292c0-1.1-.8-1.9-1.9-1.9s-1.9.9-1.9 1.9h-3.5v-3.9z", style: {
  fill: "#fecd82",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M305.5 602.2h5.1M308.4 605.9h5.1M312 609.7h5.1", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "#06996d",
  strokeWidth: 2
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M286.6 605.7V594c14.3 15.1-4.9 32.4 8.3 31.2 18.6-1.8 42.8-.6 50.4.3v2.5h-58.8v-22.3z", style: {
  fill: "#231f20",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M254.9 396.7h39.9v6.4h-39.9zM302.6 396.7h7.5v6.4h-7.5zM239.5 396.7h7.5v6.4h-7.5z", style: {
  fill: "#50342f"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M244.5 584.4h18.4v18.4h-18.4z", style: {
  fill: "#4656a6"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M244.5 589.1v2.7l15.6-2.7z", style: {
  fill: "#010101",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M260.6 581.7h-13.8c-2 0-3.7 1.6-3.7 3.7s1.6 3.7 3.7 3.7h13.8c2 0 3.7-1.6 3.7-3.7s-1.7-3.7-3.7-3.7", style: {
  fill: "#4086c6",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M260.6 575.9h-13.8c-2 0-3.7 1.6-3.7 3.7s1.6 3.7 3.7 3.7h13.8c2 0 3.7-1.6 3.7-3.7s-1.7-3.7-3.7-3.7", style: {
  fill: "#4086c6",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M293 617.2c-11.6-1.4-26-6.1-26-16.7 0 0-.6-5.8-6.2-5.8s-6.5 5.8-6.5 5.8c-5.6-5.6-11.1-5.8-11.1-5.8v33.9H302v-2.5c-.1-8.2-5.2-8.5-9-8.9", style: {
  fill: "#8b4f2f",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M302.9 628.5v3.9h-3.2c0-1.1-.8-1.9-1.9-1.9-1 0-1.9.9-1.9 1.9h-3.5c0-1.1-.8-1.9-1.9-1.9-1 0-1.9.9-1.9 1.9h-3.5c0-1.1-.8-1.9-1.9-1.9-1 0-1.9.9-1.9 1.9h-3.5c0-1.1-.8-1.9-1.9-1.9-1 0-1.9.9-1.9 1.9h-3.5c0-1.1-.8-1.9-1.9-1.9-1 0-1.9.9-1.9 1.9h-3.5c0-1.1-.8-1.9-1.9-1.9-1 0-1.9.9-1.9 1.9h-3.5c0-1.1-.8-1.9-1.9-1.9-1 0-1.9.9-1.9 1.9h-3.5c0-1.1-.8-1.9-1.9-1.9-1 0-1.9.9-1.9 1.9h-3.5v-3.9z", style: {
  fill: "#fecd82",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M262.1 602.8h5M264.9 606.5h5.1M268.6 610.3h5.1", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "#06996d",
  strokeWidth: 2
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M243.2 606.3v-11.7c14.3 15.1-4.9 32.4 8.3 31.2 18.6-1.8 42.8-.6 50.4.3v2.5h-58.8v-22.3z", style: {
  fill: "#231f20",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M316.2 227.7c0 20.5-16.6 37.1-37.1 37.1S242 248.2 242 227.7s16.6-37.1 37.1-37.1c20.5.1 37.1 16.7 37.1 37.1", style: {
  fill: "#e0ac7c",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M235.9 227.7c0-3.4 2.8-6.2 6.2-6.2s6.2 2.8 6.2 6.2-2.8 6.2-6.2 6.2-6.2-2.8-6.2-6.2M310 227.7c0-3.4 2.8-6.2 6.2-6.2s6.2 2.8 6.2 6.2-2.8 6.2-6.2 6.2-6.2-2.8-6.2-6.2", style: {
  fill: "#e0ac7c",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M262.7 227.1c0 1.5-1.2 2.8-2.8 2.8-1.5 0-2.8-1.2-2.8-2.8 0-1.5 1.2-2.8 2.8-2.8 1.6.1 2.8 1.3 2.8 2.8", style: {
  fill: "#062640",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M265.4 243.8c2.8 4.8 8 10.1 14 10.1s11.2-5.3 14-10.1z", style: {
  fill: "#fff",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M300.6 227.1c0 1.5-1.2 2.8-2.8 2.8-1.5 0-2.8-1.2-2.8-2.8 0-1.5 1.2-2.8 2.8-2.8 1.5.1 2.8 1.3 2.8 2.8", style: {
  fill: "#062640",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M243.2 227.7c-.8 0-1.4-.6-1.5-1.4-.7-7.4-.1-35.7 37.4-35.7s38.7 27.9 38.2 35.1c-.1.7-.7 1.3-1.4 1.3s-1.3-.5-1.4-1.2c-.8-5.3-.8-26-35.3-26-34.6 0-33.8 20.9-34.4 26.5-.2.9-.8 1.4-1.6 1.4", style: {
  fill: "#062640",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M269.9 239.8c-2.7 1.5-4 1.9-7.3 1.8-6-.1-6.5 7.6-6.5 7.6 10.9-6.5 21.7 3 24.1-5.6.9-3.1-3.7-7.4-10.3-3.8", style: {
  fill: "#5e4138",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M288.9 239.8c2.7 1.5 4 1.9 7.3 1.8 6-.1 6.5 7.6 6.5 7.6-10.9-6.5-21.7 3-24.1-5.6-.9-3.1 3.7-7.4 10.3-3.8", style: {
  fill: "#5e4138",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M283.8 237c0 2.6-2.1 4.7-4.7 4.7s-4.7-2.1-4.7-4.7 2.1-4.7 4.7-4.7 4.7 2.1 4.7 4.7", style: {
  fill: "#e0ac7c",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M317.3 205.4c0-22.7-17.5-36.3-38.2-36.6s-36.4 14.8-36.7 35.5z", style: {
  fill: "#06996d",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "m272.7 285.1 2.8 5.1 4.7 9.6 4.7-9.8 3-4.9z", style: {
  fill: "#e0ac7c",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "m275.5 290.2 4.7 9.6-4.7-.2c-5.6-.2-10.6-3.2-13.4-7.8-1.8-3-3.9-5.9-6.5-8.3l-1.3-1.2zM284.9 290.2l-4.7 9.6 4.7-.2c5.6-.2 10.6-3.2 13.4-7.8 1.8-3 3.9-5.9 6.5-8.3l1.3-1.2-11.5-.1z", style: {
  fill: "#231f20",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "m270.7 280.9-5.2 5.8 2.4 15.5 7.6-12zM290.5 280.9l3.6 6.3-1.1 15.6-8.1-12.8z", style: {
  fill: "#f05323",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M315.2 235.2c-.3-15.6-.3-17.6-.3-17.8-.2-1.7-3.6-21.3-33.3-21.3-31.1 0-37.6 7.4-39.7 21.3 0 .2 0 2.2-.3 17.8-.7 37.4 14.8 47.8 26 46.4q10.8-1.35 21.6 0c11.2 1.4 26.7-9 26-46.4m-9.9 14.6h-.6c-1.8 0-9.5-5.4-11.2-6-2.1-.7-4.6 9.2-13.9 9.2-9.4 0-12-9.9-14.1-9.2-1.7.6-11.3 6.1-13.2 6h-.6c-4.7-.1-5.1-2.9-4.9-14.5s6.9-30.7 13.3-30.7 6.1 7.8 2.8 7.8-4.6-2.4-4.6-2.4-1.2 12 19.2 12 18.2-15.2 23.2-15.2 9.3 16.9 9.5 28.6c.1 11.5-.2 14.3-4.9 14.4", style: {
  fill: "#5e4138",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "m246.1 199.1 71.1 3.2s-.9-6.7-2.4-9.4c-42.5-20.8-68.7 6.2-68.7 6.2", style: {
  fill: "#231f20",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M320.6 215.6c-24.5-13-51.7-13.7-81.7-1.9l.3-5.8c0-.4.2-1.3.5-2.3 1.3-4.3 4.6-7.7 8.8-9.2 21.7-7.9 43-7.8 63.7 1.5 3.8 1.7 6.7 4.9 7.9 8.9.4 1.3.7 2.5.7 3.1z", style: {
  fill: "#06996d",
  fillRule: "nonzero"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M274.8 397c1.6 0 2.8 1.3 2.8 2.8 0 1.6-1.3 2.8-2.8 2.8M273.4 399.9h-1.1", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "#f9c02d",
  strokeWidth: "1.09px"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M280.2 299.8v93.5M306.9 317.7h-18.7v6.2c0 2 1.6 3.6 3.6 3.6h11.5c2 0 3.6-1.6 3.6-3.6zM269.2 317.7h-18.7v6.2c0 2 1.6 3.6 3.6 3.6h11.5c2 0 3.6-1.6 3.6-3.6z", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "#231f20",
  strokeWidth: 2,
  strokeLinecap: "butt"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }), /* @__PURE__ */ React.createElement("path", { d: "M316.7 332.2c-5.5-9.6-10.8-27.5-10.8-52.7M240.1 332.2c5.5-9.6 10.8-27.5 10.8-52.7", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "#5e4138",
  strokeWidth: 7,
  strokeLinecap: "butt"
}, transform: "matrix(.80983 0 0 .80983 -133.187 -123.195)" }));
const ForwardRef = forwardRef(SvgOutdoor);
const Memo = memo(ForwardRef);
export default Memo;
