import * as React from "react";
import { forwardRef, memo } from "react";
const SvgEmptyContentMan = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 186.05 403.14", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "clip-path", transform: "translate(-22.66 -1.65)" }, /* @__PURE__ */ React.createElement("path", { d: "m25.76 147 73.6 12.05a.84.84 0 0 0 .95-.59L114 112.13a1.63 1.63 0 0 0-1.3-2.08l-84-13.75a1.63 1.63 0 0 0-1.9 1.55l-1.79 48.28a.84.84 0 0 0 .75.87", className: "cls-1" })), /* @__PURE__ */ React.createElement("style", null, ".cls-1{fill:#fff}.cls-4{fill:#e0ad7f}.cls-5{fill:#5d4037}.cls-6{fill:#a1887f}.cls-7{fill:#424242}.cls-8{fill:#4b4b4b}.cls-9{fill:#231f20;opacity:.12;mix-blend-mode:multiply}.cls-11{fill:#062640}")), /* @__PURE__ */ React.createElement("g", { style: {
  isolation: "isolate"
} }, /* @__PURE__ */ React.createElement("g", { id: "Layer_1", "data-name": "Layer 1" }, /* @__PURE__ */ React.createElement("ellipse", { cx: 111.53, cy: 400.28, rx: 74.52, ry: 2.87, style: {
  opacity: 0.2
} }), /* @__PURE__ */ React.createElement("circle", { cx: 31.72, cy: 152.07, r: 1.95, className: "cls-4" }), /* @__PURE__ */ React.createElement("path", { d: "M57.11 155.22s-3.1 4.94-12.42 4.94-12.83-8.13-12.83-8.13Z", className: "cls-4" }), /* @__PURE__ */ React.createElement("path", { d: "M95.34 373.51h21.37c0 9.58 16.94 11.85 27.42 13.07 4.6.53 8.08 5.58 8.08 10.22H95.34Z", className: "cls-5" }), /* @__PURE__ */ React.createElement("path", { d: "M135.02 385.3a4.67 4.67 0 0 1-5.7 2.84l-6.75-2a15.69 15.69 0 0 1-11-12.62h5.16c-.04 6.26 9.19 10.5 18.29 11.78", className: "cls-6" }), /* @__PURE__ */ React.createElement("path", { d: "M152.19 396.79v3.49h-3a1.74 1.74 0 0 0-3.47 0h-3.27a1.74 1.74 0 1 0-3.47 0h-3.24a1.74 1.74 0 1 0-3.47 0H129a1.74 1.74 0 0 0-3.47 0h-3.27a1.74 1.74 0 1 0-3.47 0h-3.27a1.74 1.74 0 0 0-3.47 0h-3.27a1.74 1.74 0 0 0-3.47 0h-3.27a1.74 1.74 0 1 0-3.47 0h-3.23v-3.49ZM89.99 82.91H66.56a15.81 15.81 0 0 0-15.73 14.22l-3.71 33.79H89.7Z", className: "cls-7" }), /* @__PURE__ */ React.createElement("path", { d: "M60.94 130.91H46.66v-3.6h14.28z", className: "cls-8" }), /* @__PURE__ */ React.createElement("path", { d: "M101 89.72H80.3V79.66H101z", className: "cls-7" }), /* @__PURE__ */ React.createElement("path", { d: "M101 89.72H80.22V79.66H101z", className: "cls-9" }), /* @__PURE__ */ React.createElement("path", { d: "M126.99 35.72A35.71 35.71 0 0 0 91.27 0H89.5a35.71 35.71 0 0 0-35.71 35.72z", className: "cls-5" }), /* @__PURE__ */ React.createElement("path", { d: "m132.41 130.92 6.32 73.43q.16 1.83.16 3.68a42.88 42.88 0 0 1-12.55 30.32l-4.1 4.1V208.6l4.23-13.47-6.49-64.21Z", className: "cls-4" }), /* @__PURE__ */ React.createElement("path", { d: "m139.12 376.21 9.72-179.43h-72l9.72 179.43h21l-.11-145.07a5.36 5.36 0 0 1 5.36-5.25 5.36 5.36 0 0 1 5.36 5.25l-.11 145.07Z", style: {
  fill: "#1a247d"
}, transform: "translate(-22.66 -1.65)" }), /* @__PURE__ */ React.createElement("path", { d: "M62.21 195.13h-8.03l9.72 179.43h4.82", className: "cls-9" }), /* @__PURE__ */ React.createElement("path", { d: "M90.52 82.91h23.42a15.81 15.81 0 0 1 15.73 14.22l3.71 33.79H90.82Z", className: "cls-7" }), /* @__PURE__ */ React.createElement("path", { d: "M104.13 195.13h-50l9.52-93.78a20.53 20.53 0 0 1 20.42-18.46h12.21a20.53 20.53 0 0 1 20.42 18.46l9.47 93.76h-22", className: "cls-7" }), /* @__PURE__ */ React.createElement("path", { d: "M60.87 128.82c1.48 21.08 6.79 41.23 25.5 54.69 8.77 6.31 18.89 9.92 29.45 11.62H54.17Z", className: "cls-9" }), /* @__PURE__ */ React.createElement("circle", { cx: 90.59, cy: 90.32, r: 11.33, className: "cls-7" }), /* @__PURE__ */ React.createElement("path", { d: "M90.59 107.77a7 7 0 0 0 7-7V57.02H83.55v43.72a7 7 0 0 0 7.04 7.03", className: "cls-4" }), /* @__PURE__ */ React.createElement("path", { d: "M63.76 373.51h21.37c0 9.58 16.94 11.85 27.42 13.07 4.6.53 8.08 5.58 8.08 10.22H63.76Z", className: "cls-5" }), /* @__PURE__ */ React.createElement("rect", { width: 73.2, height: 73.2, x: 76.45, y: 6.58, className: "cls-4", rx: 36.6, ry: 36.6, transform: "rotate(-180 101.72 42.345)" }), /* @__PURE__ */ React.createElement("rect", { width: 11.61, height: 11.61, x: 121.18, y: 35.71, className: "cls-4", rx: 5.81, ry: 5.81 }), /* @__PURE__ */ React.createElement("rect", { width: 4.21, height: 4.21, x: 106.33, y: 39.42, className: "cls-11", rx: 2.1, ry: 2.1 }), /* @__PURE__ */ React.createElement("rect", { width: 4.21, height: 4.21, x: 70.24, y: 39.42, className: "cls-11", rx: 2.1, ry: 2.1 }), /* @__PURE__ */ React.createElement("rect", { width: 11.61, height: 11.61, x: 47.99, y: 35.71, className: "cls-4", rx: 5.81, ry: 5.81 }), /* @__PURE__ */ React.createElement("path", { d: "M101.93 55.66c-6.45 13.7-16.75 13.74-23.08 0", className: "cls-1" }), /* @__PURE__ */ React.createElement("path", { d: "m62.21 195.13 6.51 179.43", style: {
  fill: "#231f20"
} }), /* @__PURE__ */ React.createElement("path", { d: "M121.13 17.93a145.3 145.3 0 0 0-61.21 0h-.32l15.33-12 15.66-3.38 11.34 2.38 11 6.08ZM124.34 24.88v16.64h2.6v-5.8s-.14-6.21-2.6-10.84M56.39 24.88v16.64h-2.6v-5.8s.19-6.21 2.6-10.84", className: "cls-5" }), /* @__PURE__ */ React.createElement("path", { d: "M101.51 71.71a68.4 68.4 0 0 0 11.77 1 68.5 68.5 0 0 0 11.31-.95v6.14a36.56 36.56 0 0 1-23.08 0Z", style: {
  opacity: 0.2,
  fill: "#231f20",
  mixBlendMode: "multiply"
}, transform: "translate(-22.66 -1.65)" }), /* @__PURE__ */ React.createElement("path", { d: "m85.89 88.29 4.85 10.07v15.45l-17.9-17.89z", className: "cls-7" }), /* @__PURE__ */ React.createElement("path", { d: "m95.44 88.29-4.85 13.36v12.16l17.9-17.89z", className: "cls-7" }), /* @__PURE__ */ React.createElement("path", { d: "m97.5 90.56-3.53 9.72c1.17-3.24 3.28-10.16 8-7.56l.9-9.81Z", className: "cls-9" }), /* @__PURE__ */ React.createElement("path", { d: "m101 79.67-5.56 8.62 5.7 4.14a1 1 0 0 0 1.59-.8l.1-8.72ZM80.34 79.67l5.56 8.62-5.83 4.23a1 1 0 0 1-1.58-.72l-.77-8.89Z", className: "cls-8" }), /* @__PURE__ */ React.createElement("circle", { cx: 88.56, cy: 99.18, r: 1.1, className: "cls-9" }), /* @__PURE__ */ React.createElement("circle", { cx: 85, cy: 92.18, r: 1.1, className: "cls-9" }), /* @__PURE__ */ React.createElement("path", { d: "M134.15 130.91h-14.64v-3.6h14.64z", className: "cls-8" }), /* @__PURE__ */ React.createElement("path", { d: "M103.46 385.3a4.67 4.67 0 0 1-5.7 2.84l-6.75-2a15.69 15.69 0 0 1-11-12.62h5.16c-.04 6.26 9.17 10.5 18.29 11.78", className: "cls-6" }), /* @__PURE__ */ React.createElement("path", { d: "M120.64 396.79v3.49h-3a1.74 1.74 0 0 0-3.47 0h-3.27a1.74 1.74 0 1 0-3.47 0h-3.27a1.74 1.74 0 1 0-3.47 0h-3.27a1.74 1.74 0 0 0-3.47 0h-3.27a1.74 1.74 0 1 0-3.47 0h-3.27a1.74 1.74 0 0 0-3.47 0H77.2a1.74 1.74 0 0 0-3.47 0h-3.28a1.74 1.74 0 1 0-3.47 0h-3.22v-3.49Z", className: "cls-7" }), /* @__PURE__ */ React.createElement("path", { d: "m3.1 145.35 73.6 12.05a.84.84 0 0 0 .95-.59l13.69-46.33a1.63 1.63 0 0 0-1.3-2.08l-84-13.75a1.63 1.63 0 0 0-1.9 1.55l-1.79 48.28a.84.84 0 0 0 .75.87", className: "cls-1" }), /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#clip-path)"
} }, /* @__PURE__ */ React.createElement("path", { d: "m67.54 120.94-1.14 2.26a.29.29 0 0 1-.48 0l-1.56-2a.29.29 0 0 0-.51.19L64 124a.29.29 0 0 1-.39.28l-2.35-.94a.29.29 0 0 0-.35.42l1.39 2.12a.29.29 0 0 1-.2.44l-2.5.36a.29.29 0 0 0-.09.54l2.26 1.14a.29.29 0 0 1 0 .48l-2 1.56a.29.29 0 0 0 .19.51l2.53-.14a.29.29 0 0 1 .28.39l-.94 2.35a.29.29 0 0 0 .42.35l2.12-1.39a.29.29 0 0 1 .44.2l.36 2.5a.29.29 0 0 0 .54.09l1.18-2.26a.29.29 0 0 1 .48 0l1.56 2a.29.29 0 0 0 .51-.19l-.14-2.53a.29.29 0 0 1 .39-.28l2.35.94a.29.29 0 0 0 .35-.42L71 130.34a.29.29 0 0 1 .2-.44l2.5-.36a.29.29 0 0 0 .09-.54l-2.26-1.14a.29.29 0 0 1 0-.48l2-1.56a.29.29 0 0 0-.19-.51l-2.53.14a.29.29 0 0 1-.28-.39l.94-2.35a.29.29 0 0 0-.42-.35l-2.12 1.39a.29.29 0 0 1-.44-.2l-.36-2.5a.29.29 0 0 0-.59-.11", style: {
  fill: "#e4e5e6"
}, transform: "translate(-22.66 -1.65)" }), /* @__PURE__ */ React.createElement("path", { d: "M40.25 86.9s13.26 54.95 76.44 70.1l9.63-58.83Z", style: {
  mixBlendMode: "multiply",
  fill: "#eff0f0"
}, transform: "translate(-22.66 -1.65)" })), /* @__PURE__ */ React.createElement("rect", { width: 79.89, height: 3.03, x: 22.23, y: 151.8, className: "cls-1", rx: 1.51, ry: 1.51, transform: "rotate(-171.44 50.784 153.33)" }), /* @__PURE__ */ React.createElement("rect", { width: 3.35, height: 11.72, x: 69.72, y: 147.86, className: "cls-4", rx: 1.67, ry: 1.67, transform: "rotate(180 60.07 152.895)" }), /* @__PURE__ */ React.createElement("rect", { width: 3.35, height: 13.95, x: 56.33, y: 142.84, className: "cls-4", rx: 1.67, ry: 1.67, transform: "rotate(-180 46.675 148.99)" }), /* @__PURE__ */ React.createElement("rect", { width: 3.35, height: 14.51, x: 65.26, y: 143.4, className: "cls-4", rx: 1.67, ry: 1.67, transform: "rotate(-180 55.605 149.825)" }), /* @__PURE__ */ React.createElement("rect", { width: 3.35, height: 16.18, x: 60.8, y: 140.61, className: "cls-4", rx: 1.67, ry: 1.67, transform: "rotate(-180 51.14 147.875)" }))));
const ForwardRef = forwardRef(SvgEmptyContentMan);
const Memo = memo(ForwardRef);
export default Memo;
