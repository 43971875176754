import { getPaidAds } from '@promoboxx/redux-stores/src/stores/ad/actions'
import { ofType } from 'redux-observable'
import { of, merge } from 'rxjs'
import { mergeMap, withLatestFrom } from 'rxjs/operators'

import * as actions from '../app/actions'
import { selectRetailerIdsForCurrentBrandOrCurrentRetailerAccount } from '../app/selectors'

export const getPaidAdCredits = (action$, state$) => {
  return action$.pipe(
    ofType(actions.SELECT_RETAILER_ACCOUNT, actions.SELECT_BRAND),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const retailerIds =
        selectRetailerIdsForCurrentBrandOrCurrentRetailerAccount(state)
      return merge(
        of(
          getPaidAds({
            ...retailerIds,
            fields: ['campaign_channels', 'invitations'],
          }),
        ),
      )
    }),
  )
}
