import { createStore, applyMiddleware, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createEpicMiddleware } from 'redux-observable'
import { persistStore, persistReducer } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import { createFilter } from 'redux-persist-transform-filter'

import awaitActionTypeMiddleware from '../lib/awaitActionTypeMiddleware'
import epics from '../stores/epics'
import reducers from '../stores/reducers'

import AppConfig from './AppConfig'

//App reducers

//App epics

//Middlewhere

//Filter state to store/blacklist.
//By default, anything missing below *will* be persisted.
//Anything in the list, will not be persisted, unless a specific key is in the array.
//Example: createFilter('app', []) - Nothing will be persisted for anything in 'app'
//Example: createFilter('app', ['some_field']) - Only 'some_field' will be persisted
const filters = [
  createFilter('ad', []),
  createFilter('app', []),
  createFilter('appRetailerAccounts', []),
  createFilter('auth', []),
  createFilter('content', []),
  createFilter('contentDiscovery', []),
  createFilter('darklyFeatureFlags', []),
  createFilter('facebookAds', []),
  createFilter('facebookPages', []),
  createFilter('feature_flags', []),
  createFilter('forgotPassword', []),
  createFilter('locations', []),
  createFilter('paidAds', []),
  createFilter('requestAccess', []),
  createFilter('retailerAccountsFilters', []),
  createFilter('retailers', []),
  createFilter('retailerAccountsCampaignsSearch', []),
  createFilter('retailersCampaignsSearch', []),
  createFilter('retailersContentCampaignsSearch', []),
  createFilter('retailersContentDiscovery', []),
  createFilter('retailersFacebookPages', []),
  createFilter('router', []),
  createFilter('stats', []),
  createFilter('automations', []),
  createFilter('filterInfo', []),
  createFilter('retailerAccounts', []),
]
const epicMiddleware = createEpicMiddleware()
const middleware = applyMiddleware(epicMiddleware, awaitActionTypeMiddleware())

//Persist config
const persistConfig = {
  key: 'react-base',
  storage,
  transforms: [...filters],
  stateReconciler: autoMergeLevel2,
}

//Combine Reducers
const combinedReducers = combineReducers({
  ...reducers,
})

const initialState = {}
const persistedReducer = persistReducer(persistConfig, combinedReducers)
const actionsBlacklist = AppConfig.DEBUG_ANALYTICS ? undefined : 'TRACK_'
const composeEnhancers = composeWithDevTools({
  actionsBlacklist,
})
const store = createStore(
  persistedReducer,
  initialState,
  composeEnhancers(middleware),
)
const persistor = persistStore(store)

epicMiddleware.run(epics)

export { store, persistor }
