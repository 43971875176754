export const SUPPORTED_LANGUAGES = ['en-us']

const normalizeLocaleName = (locale?: string) => {
  let normalized = locale?.toLowerCase().replace(/_+/g, '-') || ''

  if (!SUPPORTED_LANGUAGES.includes(normalized)) {
    // need to cast here due to `noUncheckedIndexedAccess`.
    normalized = SUPPORTED_LANGUAGES[0] as string
  }

  return normalized
}

export default normalizeLocaleName
