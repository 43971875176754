import { gql } from '@apollo/client'
import { Query } from '@promoboxx/graphql-gateway-types'
import jsCookie from 'js-cookie'

import { apolloClient, jwtRef } from '@src/config/ApolloClient'

import parsePbxxJwt, { PbxxJwt, isPbxxJwtExpired } from './parsePbxxJwt'

interface AuthHeadersCookie {
  'access-token': string
  'client': string
  'expiry': string
  'token-type': string
  'uid': string
}

async function getJwt() {
  let jwt = await getOmniauthJwt()

  if (!jwt) {
    jwt = await getJwtFromAuthHeaders()
  }

  return jwt
}

export async function saneGetUser() {
  const jwtString = await getJwt()

  if (!jwtString) {
    return
  }

  const jwt = parsePbxxJwt(jwtString)
  if (!jwt || isPbxxJwtExpired(jwt)) {
    return
  }

  jwtRef.current = jwtString

  const user = await getUserFromJwt(jwt)
  const authHeaders = getAuthHeaders()

  if (!user || !authHeaders) {
    return
  }

  return {
    user,
    authHeaders,
    jwt: jwtString,
  }
}

function getAuthHeaders() {
  const authHeadersString = jsCookie.get('auth_headers')

  if (!authHeadersString) {
    return
  }

  // The auth_headers cookie might be malformed JSON.
  try {
    const authHeaders: AuthHeadersCookie = JSON.parse(authHeadersString)

    if (new Date(Number(authHeaders.expiry) * 1000) < new Date()) {
      return
    }

    return {
      accessToken: authHeaders['access-token'],
      tokenType: authHeaders['token-type'],
      client: authHeaders.client,
      expiry: authHeaders.expiry,
      uid: authHeaders.uid,
    }
  } catch (err) {
    console.error(err)
  }

  return
}

async function getOmniauthJwt() {
  const omniauthJwt = jsCookie.get('omniauth_session_jwt')

  // This is probably overkill since the code that uses `getJwt` already checks
  // if it's expired, but it allows for the case where the user has an expired
  // omniauth jwt, but a valid auth_headers cookie.
  if (isPbxxJwtExpired(parsePbxxJwt(omniauthJwt))) {
    return
  }

  return omniauthJwt
}

async function getJwtFromAuthHeaders() {
  const authHeaders = getAuthHeaders()

  if (!authHeaders) {
    return
  }

  try {
    const { data } = await apolloClient().query<Query>({
      query: GET_JWT,
      variables: {
        accessToken: authHeaders.accessToken,
        tokenType: authHeaders.tokenType,
        client: authHeaders.client,
        expiry: authHeaders.expiry,
        uid: authHeaders.uid,
      },
    })

    return data.getJwt?.jwt
  } catch (err) {
    console.error(err)

    return
  }
}

async function getUserFromJwt(jwt: PbxxJwt) {
  const { data } = await apolloClient().query<Query>({
    query: GET_USER,
    variables: {
      id: String(jwt.sub),
      fields: USER_FIELDS,
    },
  })

  if (data.getUser) {
    return data.getUser
  }

  return
}

export const RETAILER_ACCOUNT_FIELDS = [
  'facebook_pages',
  'facebook_pages.remote_token_status',
  'twitter_accounts',
  'retailers',
  'retailers.facebook_pages',
  'retailers.brand',
  'retailers.brand.digital_asset_tags',
  'retailers.brand.industry_list',
  'retailers.brand.settings',
  'retailers.sso_enabled',
  'retailers.twitter_accounts',
]

export const USER_FIELDS = [
  'access_invitations',
  'access_invitations.accessible',
  'access_invitations.sender',
  'access_requests',
  'actionable_access_requests',
  'orphaned_retailers',
  'permissions',
  'registration_keys',
  'registration_keys.brand',
  'retailer_accounts',
  'retailer_accounts.facebook_pages',
  ...RETAILER_ACCOUNT_FIELDS.map((x) => `retailer_accounts.${x}`),
  // Requesting either of these fields causes them to be the _only_ thing
  // returned.
  // "user_roles",
]

export const RETAILER_ACCOUNT_FIELDS_FRAGMENT = gql`
  fragment RetailerAccountFieldsFragment on RetailerAccount {
    id
    name
    slug
    multi_brand
    website
    phone
    phone_extension
    logo_file_name
    logo_content_type
    logo_file_size
    logo_updated_at
    logo_url
    logo_thumbnail_url
    logo_medium_url
    formatted_phone
    formatted_phone_extension
    show_multi_brand_ad
    business_id

    facebook_pages {
      id
      name
      picture_url
      instagram_business_account_id
      business_manager_permissions
      remote_token_status {
        is_valid
      }
    }

    twitter_accounts {
      description
      follower_count
      has_been_authenticated
      id
      name
      nickname
      profile_image_url
    }

    retailers {
      id
      name
      phone
      website
      created_at
      updated_at
      logo_file_name
      logo_content_type
      logo_file_size
      logo_updated_at
      slug
      brand_id
      deleted_at
      phone_extension
      state
      created_by_id
      updated_by_id
      primary_user_id
      retailer_import_id
      hash_id
      logo_url
      logo_thumbnail_url
      logo_medium_url
      multi_brand
      retailer_account_id
      location_count
      sso_enabled
      paid_ad_radius

      facebook_pages {
        id
        name
        picture_url
        business_manager_permissions
        instagram_business_account_id
      }

      twitterAccounts {
        description
        follower_count
        has_been_authenticated
        id
        name
        nickname
        profile_image_url
      }

      brand {
        id
        name
        addr1
        addr2
        city
        state
        zip
        website
        about
        created_at
        updated_at
        logo_file_name
        logo_content_type
        logo_file_size
        logo_updated_at
        slug
        retailer_alias
        campaign_alias
        division_alias
        abbr
        invite_from_name
        engagement_flow_header_text
        support_email_address
        logo_url
        phone
        email
        dealer_funded_management_fee_percentage
        retailer_dashboard_message
        retailer_self_fund_auto_enabled
        status
        header_background_color
        header_foreground_color
        show_manage_brands_link
        holding_company_id
        ads_enabled
        auto_share_facebook
        auto_share_instagram
        auto_share_twitter
        instagram_enabled
        lock_retailer_data
        external_auth_login_url
        waiting_room_message
        show_logo
        instagram_ads_enabled
        default_enrollment_campaign_id
        wallet_enabled
        division_report_summary_enabled
        default_registration_key_id
        fb_restrict21plus
        activity_feeds_enabled
        activity_reports_enabled
        brand_report_summary_enabled
        campaigns_open_enrollment_by_default
        custom_header_css
        default_timeout_in_minutes
        facebook_id
        retailer_report_summary_enabled
        show_message_campaigns
        twitter_id
        demo
        campaign_builder_enabled
        sso_name
        sso_url
        include_in_engagement_reminder
        new_designer_enabled
        lock_user_creation
        uuid
        hubspot_portal_id
        offboard_redirect
        region_data_stored_in_custom_fields
        accelerate_campaign_automation_enabled
        hubspot_company_id
        hubspot_company_url
        community
        digital_asset_tags
        locales

        settings {
          template_campaigns_enabled
          frame_campaigns_enabled
          simple_campaigns_enabled
          product_campaigns_enabled
          data_campaigns_enabled
          retailer_share_scheduling_enabled
          assets_enabled
          match_enabled
          mobile_ads_enabled
          facebook_ads_enabled
          google_ads_enabled
          retailer_match_funding_enabled
          instamatch_enabled
          brand_content_discovery
          retailer_content_discovery
          share_content_filters
          ss_facebook_allow_prefill
          ss_facebook_allow_append_link_to_message
          ss_facebook_allow_custom_link
          ss_allow_custom_link
          network_monitoring
          show_expired_brand_message
          allow_match_custom_message
          limit_single_retailer_location
          ss_landing_page_campaign
          paid_campaigns_enabled
          campaign_append_to_retailer_website_enabled
          retailer_connection_requests_industry_suggestions_enabled
          retailer_connection_requests_pic_suggestions_enabled
        }
      }
    }
  }
`

export const USER_FIELDS_FRAGMENT = gql`
  ${RETAILER_ACCOUNT_FIELDS_FRAGMENT}
  fragment UserFieldsFragment on User {
    id
    email
    created_at
    updated_at
    first_name
    last_name
    title
    company
    time_zone
    mobile_phone_number
    clickwrap_accepted_at
    created_by_id
    updated_by_id
    type
    slug
    deactivated_by
    time_zone_offset
    time_zone_abbr
    time_zone_iana_name
    user_roles
    language_preference

    access_invitations {
      id
    }

    access_requests {
      id
    }

    actionable_access_requests {
      id
    }

    facebook_accounts {
      id
      user_id
      uid
      name

      permissions {
        read_insights
        pages_show_list
        ads_management
        business_management
        pages_read_engagement
        pages_manage_metadata
        pages_read_user_content
        pages_manage_ads
        pages_manage_posts
        pages_manage_engagement
        public_profile
        manage_pages
        publish_pages
      }

      token_expires_at
      send_notifications
      created_at
      updated_at
      deleted_at
      token_is_valid

      manageable_pages {
        id
        name
        tasks
        fan_count
        link
        is_published
      }

      profile_url
      picture_url
    }

    retailer_accounts {
      ...RetailerAccountFieldsFragment
    }

    uuid
  }
`

const GET_USER = gql`
  ${USER_FIELDS_FRAGMENT}
  query getUserSaneLogin($id: ID!, $fields: [String!]) {
    getUser(id: $id, fields: $fields) {
      ...UserFieldsFragment
    }
  }
`

export const GET_JWT = gql`
  query getJwtSaneLogin(
    $accessToken: String!
    $tokenType: String!
    $client: String!
    $expiry: String!
    $uid: String!
  ) {
    getJwt(
      accessToken: $accessToken
      tokenType: $tokenType
      client: $client
      expiry: $expiry
      uid: $uid
    ) {
      jwt
    }
  }
`
