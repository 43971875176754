import { styled, keyframes } from '@mui/material/styles'
import PropTypes from 'prop-types'
const rotateAnimation = keyframes({
  from: {
    transform: 'rotate(0deg)',
  },
  to: {
    transform: 'rotate(360deg)',
  },
})

const facebookAnimation = keyframes({
  '0%, 80%, 100%': {
    boxShadow: '0 0',
    height: '4em',
  },
  '40%': {
    boxShadow: '0 -2em',
    height: '5em',
  },
})
const DefaultLoading = styled('div')(({ theme, size, color }) => ({
  '&, &:after': {
    borderRadius: '50%',
    width: '4em',
    height: '4em',
  },
  'margin': '0 auto',
  'fontSize': `${size}px`,
  'position': 'relative',
  'textIndent': '-9999em',
  'borderTop': `${size / 4}px solid rgba(0, 0, 0, 0.2)`,
  'borderRight': `${size / 4}px solid rgba(0, 0, 0, 0.2)`,
  'borderBottom': `${size / 4}px solid rgba(0, 0, 0, 0.2)`,
  'borderLeft': `${size / 4}px solid ${color}`,
  'WebkitTransform': 'translateZ(0)',
  'msTransform': 'translateZ(0)',
  'transform': 'translateZ(0)',
  'WebkitAnimation': `${rotateAnimation} 1.1s infinite linear`,
  'animation': `${rotateAnimation} 1.1s infinite linear`,
}))

const LinesLoading = styled('div')(({ theme, size, color }) => ({
  color,
  'textIndent': '-9999em',
  'margin': '0 auto',
  'position': 'relative',
  'fontSize': `${size}px`,
  'WebkitTransform': 'translateZ(0)',
  'msTransform': 'translateZ(0)',
  'transform': 'translateZ(0)',
  '&, &:before, &:after': {
    background: color,
    WebkitAnimation: `${facebookAnimation} 1.2s infinite ease-in-out`,
    animation: `${facebookAnimation} 1.2s infinite ease-in-out`,
    width: '1em',
    height: '4em',
  },
  '&:before, &:after': {
    position: 'absolute',
    bottom: 0,
    top: 0,
    content: '""',
  },
  '&:before': {
    left: '-1.5em',
    WebkitAnimationDelay: '-0.12s',
    animationDelay: '-0.12s',
  },
  '&:after': {
    left: '1.5em',
    WebkitAnimationDelay: '0.12s',
    animationDelay: '0.12s',
  },
}))

const Loading = (props) => {
  switch (props.type) {
    case 'lines':
      return <LinesLoading color={props.color} size={props.size} />
    case 'default':
    default:
      return <DefaultLoading color={props.color} size={props.size} />
  }
}
Loading.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  type: PropTypes.oneOf(['default', 'lines']),
}

Loading.defaultProps = {
  type: 'default',
  color: '#F4F4F4',
  size: 20,
}

export default Loading
