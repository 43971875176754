import { User } from '@promoboxx/graphql-gateway-types'

import { Livechat } from '@src/lib/Zendesk'

const open = () => {
  if (window.$zopim && typeof window.$zopim === 'function') {
    const livechat = window.$zopim['livechat'] as Livechat
    livechat.window.show()
  }
}
const close = () => {
  if (window.$zopim && typeof window.$zopim === 'function') {
    const livechat = window.$zopim['livechat'] as Livechat
    livechat.window.hide()
  }
}
const hideAll = () => {
  if (window.$zopim && typeof window.$zopim === 'function') {
    const livechat = window.$zopim['livechat'] as Livechat
    livechat.hideAll()
  }
}
const identify = (user: User) => {
  //Classic functionality..
  if (window.$zopim && typeof window.$zopim === 'function') {
    const brands = user?.permissions?.filter(
      (permission) =>
        permission.action === 'manage' &&
        permission.permissionable_type === 'Brand',
    )
    const divisions = user?.permissions?.filter(
      (permission) =>
        permission.action === 'manage' &&
        permission.permissionable_type === 'Division',
    )
    let tag = 'Retailer'
    if (brands?.length) {
      tag = 'Brand'
    } else if (divisions?.length) {
      tag = 'Division'
    }

    //Zendesk loginUser
    const livechat = window.$zopim['livechat'] as Livechat
    livechat.setName(`${user.first_name} ${user.last_name}`)
    livechat.setEmail(user.email || '')
    livechat.addTags(tag)
  } else if (window.zE && typeof window.zE === 'function') {
    //Zendesk locale
    if (user.language_preference) {
      window.zE('messenger:set', 'locale', user.language_preference)
    }
  }
}

export const helpWidget = {
  open,
  close,
  hideAll,
  identify,
}
