import { alpha, GlobalStyles } from '@mui/material'

const ForceScrollbar = () => {
  return (
    <GlobalStyles
      styles={(theme) => {
        // These colors and values are from the theme, but still arbitrary. Not
        // many people getting worked up about scrollbars in 2023, so themes
        // don't really cover the concepts needed.
        const trackBackgroundColor = theme.palette.background.paper
        const thumbBackgroundColor = theme.palette.text.primary
        const initialOpacity = 0.4
        const borderWidth = 2
        const width = 8

        return `
.MuiMenu-paper,
.MuiAutocomplete-paper .MuiAutocomplete-listbox,
.force-scrollbar {
  &::-webkit-scrollbar {
    width: ${width + borderWidth}px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${trackBackgroundColor};
    border-radius: ${theme.shape.borderRadius}px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 9999px;
    background-color: ${alpha(thumbBackgroundColor, initialOpacity)};
    border: ${borderWidth}px solid ${trackBackgroundColor};

    &:hover {
      background-color: ${alpha(
        thumbBackgroundColor,
        initialOpacity + theme.palette.action.focusOpacity,
      )};
    }

    &:active {
      background-color: ${alpha(
        thumbBackgroundColor,
        initialOpacity +
          theme.palette.action.focusOpacity +
          theme.palette.action.activatedOpacity,
      )};
    }
  }
}
`
      }}
    />
  )
}

export default ForceScrollbar
