import {
  ActionStat,
  ClickStat,
  EmailStat,
  InstagramStat,
  ViewStat,
} from '@promoboxx/graphql-gateway-types'

// Unknown because we want to accept any object, and we only ever tally numbers.
type SummableObject = Record<string, unknown>

// Ideally this would be `StatsAd | StatsShare`, but there's a few extra
// properties on those that we don't need.
interface StatsAdOrShare {
  actions?: ActionStat | null
  channel?: string | null
  clicks?: ClickStat | null
  email?: EmailStat | null
  instagram?: InstagramStat | null
  views?: ViewStat | null
}

export function statsCounter(
  summableObject: SummableObject | null | undefined,
) {
  if (summableObject == null) {
    return null
  }

  let counter = 0

  for (const key in summableObject) {
    const value = summableObject[key]

    if (typeof value === 'number') {
      counter += value
    }
  }

  return counter
}

export function channelTotals<
  Type extends keyof Omit<StatsAdOrShare, 'channel'>,
>(options: { channel: string; type: Type; stats: StatsAdOrShare[] }) {
  const statsForChannel = options.stats.filter(
    (shares) => shares.channel === options.channel,
  )
  const statsForType = statsForChannel.map((share) => share[options.type])

  let total = 0

  for (const stat of statsForType) {
    total += statsCounter(stat) || 0
  }

  return total
}

export function emailTotals<
  Type extends keyof Omit<EmailStat, '__typename'>,
>(options: { type: Type; stats: StatsAdOrShare[] }) {
  const emailStats = options.stats.filter(
    (shares) => shares.channel === 'email',
  )

  let total = 0

  for (const stat of emailStats) {
    total += stat.email?.[options.type] || 0
  }

  return total
}

interface StatsTotalsAndItemized {
  totals: {
    views: number | null
    actions: number | null
    clicks: number | null
  }

  itemized: {
    views: Record<string, number>
    actions: Record<string, number>
    clicks: Record<string, number>
  }
}

export function campaignTotals(stats: StatsAdOrShare[] | undefined | null) {
  const cache: StatsTotalsAndItemized = {
    totals: {
      views: null,
      actions: null,
      clicks: null,
    },

    itemized: {
      views: {},
      actions: {},
      clicks: {},
    },
  }

  if (stats) {
    for (const stat of stats) {
      tallyItemizedAndTotals(stat, 'views', cache)
      tallyItemizedAndTotals(stat, 'actions', cache)
      tallyItemizedAndTotals(stat, 'clicks', cache)
    }
  }

  return cache
}

function tallyItemizedAndTotals(
  stat: StatsAdOrShare,
  countableType: 'views' | 'actions' | 'clicks',
  cache: StatsTotalsAndItemized,
) {
  if (!stat[countableType]) {
    return
  }

  // Unknown because TypeScript doesn't like `stat[countableType][key]` (key is
  // a string, stat[countableType] is a proper type without string keys).
  // We still verify that the value is a number before tallying it.
  const untyped: Record<string, unknown> = stat[countableType] || {}

  for (const key in untyped) {
    const value = untyped[key]

    if (typeof value !== 'number') {
      continue
    }

    cache.itemized[countableType][key] =
      (cache.itemized[countableType][key] || 0) + value
    cache.totals[countableType] = (cache.totals[countableType] || 0) + value
  }
}
