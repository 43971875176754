import {
  ad,
  analytics,
  auth,
  campaigns,
  contentDiscovery as contentDiscoveryReduxStores,
  darklyFeatureFlags,
  facebookAds,
  facebookPages,
  feature_flags,
  localAdBalances,
  locations,
  retailers,
  retailerAccounts,
  retailersCampaignsSearch,
  retailersContentDiscovery,
  retailersFacebookPages,
  retailerAccountsCampaignsSearch,
  stats,
} from '@promoboxx/redux-stores'

import * as app from './app/actions'
import * as content from './content/actions'
import * as forgotPassword from './forgotPassword/actions'
import * as login from './login/actions'
import * as requestAccess from './requestAccess/actions'
import * as filters from './retailerAccounts/filters/actions'
import * as contentDiscovery from './retailers/contentDiscovery/actions'

const actions = {
  ad: ad.actions,
  analytics: analytics.actions,
  app,
  auth: auth.actions,
  campaigns: campaigns.actions,
  content,
  contentDiscovery: {
    ...contentDiscovery,
    ...contentDiscoveryReduxStores,
  },
  darklyFeatureFlags: darklyFeatureFlags.actions,
  facebookAds: facebookAds.actions,
  facebookPages: facebookPages.actions,
  feature_flags: feature_flags.actions,
  forgotPassword,
  localAdBalances: localAdBalances.actions,
  locations: {
    retailers: locations.retailers.actions,
  },
  login,
  requestAccess,
  retailers: retailers.actions,
  retailersCampaignsSearch: retailersCampaignsSearch.actions,
  retailersContentDiscovery: retailersContentDiscovery.actions,
  retailersFacebookPages: retailersFacebookPages.actions,
  retailerAccounts: retailerAccounts.actions,
  retailerAccountsFilters: filters,
  retailerAccountsCampaignsSearch: retailerAccountsCampaignsSearch.actions,
  stats: stats.actions,
}

export default actions
