import {
  IdentifiedUser,
  AnalyticsTrackEvent,
  AnalyticsPageEvent,
} from '@src/analytics'

import AppConfig from '../AppConfig'

import { Service } from './index'

declare global {
  interface Window {
    gtag?: any
  }
}

const googleAnalytics: Service<Window['gtag']> = {
  loaded() {
    return window.gtag !== undefined
  },
  instance() {
    return window.gtag
  },
  identify(details: IdentifiedUser) {
    if (this.loaded() && AppConfig.GOOGLE_ANALYTICS_KEY) {
      window.gtag('config', AppConfig.GOOGLE_ANALYTICS_KEY, {
        user_id: details?.id,
        ...details,
      })
    }
  },
  track(details: AnalyticsTrackEvent) {
    const { type, event, data, ...rest } = details
    if (this.loaded() && AppConfig.GOOGLE_ANALYTICS_KEY) {
      window.gtag('event', event, {
        user_id: details?.id,
        type,
        ...data,
        ...rest,
      })
    }
  },
  page(details: AnalyticsPageEvent) {
    if (this.loaded() && AppConfig.GOOGLE_ANALYTICS_KEY) {
      window.gtag('config', AppConfig.GOOGLE_ANALYTICS_KEY, {
        page_path: details?.path,
        ...details,
      })
    }
  },
}
export default googleAnalytics
