import * as React from "react";
import { forwardRef, memo } from "react";
const SvgCrown = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 12 10", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "#000", fillRule: "evenodd", d: "M10.54 4.52c-.135 0-.263-.028-.386-.068L9.151 7.655H3.03L2.026 4.449a1.2 1.2 0 0 1-.354.057A1.28 1.28 0 0 1 .4 3.22c0-.71.57-1.286 1.272-1.286a1.28 1.28 0 0 1 1.272 1.286c0 .47-.253.877-.624 1.102.483.496 1.237 1.036 2.06.729.678-.253 1.32-1.667 1.537-2.45a1.29 1.29 0 0 1-1.1-1.291C4.817.586 5.387 0 6.09 0s1.274.586 1.274 1.309c0 .662-.48 1.202-1.1 1.29.219.784.86 2.198 1.537 2.451.826.308 1.583-.237 2.066-.734-.35-.23-.584-.63-.584-1.09 0-.714.563-1.293 1.258-1.293s1.259.58 1.259 1.294-.564 1.293-1.26 1.293M3.254 8.322h5.701c.525 0 .95.308.95.835s-.425.437-.95.437h-5.7c-.526 0-.951.09-.951-.437s.426-.835.95-.835" }));
const ForwardRef = forwardRef(SvgCrown);
const Memo = memo(ForwardRef);
export default Memo;
