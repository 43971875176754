import AppConfig from '../AppConfig'

import { Service } from './index'
declare global {
  interface Window {
    OptanonWrapper?: () => void
    oneTrust?: any
  }
}

const oneTrust: Service<Window['oneTrust']> = {
  init() {
    if (
      !AppConfig.ONE_TRUST_KEY ||
      new URL(window.location.toString()).searchParams.get(
        'no_cookie_banner',
      ) === 'true' ||
      new URL(window.location.toString()).searchParams.get(
        'suppress_overlays',
      ) === 'true'
    ) {
      return Promise.resolve()
    }

    const script = document.createElement('script')
    script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
    script.type = 'text/javascript'
    script.charset = 'UTF-8'
    script.setAttribute('data-domain-script', AppConfig.ONE_TRUST_KEY)

    window.OptanonWrapper = () => {}

    document.head.appendChild(script)

    return new Promise((resolve) => {
      script.onload = () => {
        resolve()
      }
    })
  },
  loaded() {
    return window.oneTrust !== undefined
  },
  instance() {
    return window.oneTrust
  },
}

export default oneTrust
