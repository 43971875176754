import * as React from "react";
import { forwardRef, memo } from "react";
const SvgFbAudience = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 30 30", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("g", { fill: "none", fillRule: "evenodd", transform: "translate(-.667 -.667)" }, /* @__PURE__ */ React.createElement("circle", { cx: 15.667, cy: 15.667, r: 15, style: {
  fill: "#594195"
} }), /* @__PURE__ */ React.createElement("path", { fill: "#FFF", fillRule: "nonzero", d: "m19.189 21.778 1.493 1.445c.072.07.169.109.271.109h1.973a.38.38 0 0 0 .382-.372v-1.907a.36.36 0 0 0-.112-.262l-1.385-1.341c1.159-1.282 1.856-2.931 1.856-4.731 0-4.079-3.582-7.386-8-7.386s-8 3.307-8 7.386c0 1.8.698 3.45 1.856 4.73l-1.386 1.342a.37.37 0 0 0-.112.262v1.909c0 .205.172.371.384.371h1.972a.4.4 0 0 0 .271-.108l1.495-1.447zm-8.212-7.23c0-2.345 1.962-4.015 4.69-4.015s4.689 1.671 4.689 4.015c0 1.143-.428 2.11-1.317 2.843-.889.762-1.993 1.142-3.372 1.142-1.38 0-2.483-.381-3.372-1.142-.889-.733-1.318-1.7-1.318-2.843" })));
const ForwardRef = forwardRef(SvgFbAudience);
const Memo = memo(ForwardRef);
export default Memo;
