import * as React from "react";
import { forwardRef, memo } from "react";
const SvgHb = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlSpace: "preserve", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, viewBox: "0 0 233 409", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M269.37 586.76s-5.2-27.5.74-72.83c0 0 4.46-55 4.46-78l19.32 3s.75 52-6.69 84c0 0-3 18.58-3.71 65.4zM333.43 588.25s5.2-27.5-.75-72.83c0 0-4.45-55-4.45-78l-19.33 3s-.74 52 6.69 84c0 0 3 18.58 3.72 65.41z", style: {
  fill: "#fae3c8",
  fillRule: "nonzero"
}, transform: "matrix(.84669 0 0 .84669 -144.981 -136.984)" }), /* @__PURE__ */ React.createElement("path", { d: "M267.88 579.7s-.74 20.44 2.48 23.91c0 0 1.48 23.29-.25 30.72 0 0-2 4.95 6.19 5.2l11.15-.25s3.47-6.68-3.22-10.9c0 0 1.74-18.08-.49-28.73l3-18.09s-15.14.74-18.86-1.86M335 579.7s.74 20.44-2.48 23.91c0 0-1.49 23.29.25 30.72 0 0 2 4.95-6.19 5.2l-11.15-.25s-3.47-6.68 3.22-10.9c0 0-1.74-18.08.49-28.73l-3-18.09s15.16.74 18.86-1.86", style: {
  fill: "#ecba6a",
  fillRule: "nonzero"
}, transform: "matrix(.84669 0 0 .84669 -144.981 -136.984)" }), /* @__PURE__ */ React.createElement("path", { d: "M334.41 389.33c-.15-5.77-.3-21.3-.3-27.37 0-39.93 9.83-73.22-31-77.64q-.42-.03-.84 0c-13.73.29-24.62 5.26-29.21 20.76-4.21 14.25-2.57 36.45-2.57 56.87v7.25c0 10.6-.06 30.19.58 39l.09 1.28h63.48v-1.39c.1-5.86-.06-12.09-.23-18.76", style: {
  fill: "#fae3c8",
  fillRule: "nonzero"
}, transform: "matrix(.84669 0 0 .84669 -144.981 -136.984)" }), /* @__PURE__ */ React.createElement("path", { d: "M337.06 387.6v-60.94c0-16.22-10.1-18.6-26.32-18.6h-16.85c-16.22 0-25.38 2.38-25.38 18.6v60.94z", style: {
  fill: "#f37e7b",
  fillRule: "nonzero"
}, transform: "matrix(.84669 0 0 .84669 -144.981 -136.984)" }), /* @__PURE__ */ React.createElement("path", { d: "M342.45 471.94c0-1.54.05-3.09.05-4.65 0-52.45-8.11-129.85-41.92-116.87-31.63 12.15-36.69 64.42-36.69 116.87v4.65z", style: {
  fill: "#f37e7b",
  fillRule: "nonzero"
}, transform: "matrix(.84669 0 0 .84669 -144.981 -136.984)" }), /* @__PURE__ */ React.createElement("path", { d: "M310.42 285.68a6.01 6.01 0 0 1-3.69 5.53l-3.62.79-3-1a5.89 5.89 0 0 1-4.29-5.53l.18-12.41c.038-2.368 2.012-4.288 4.38-4.26l5.94.09c2.347.032 4.26 1.972 4.26 4.32v.06z", style: {
  fill: "#fae3c8",
  fillRule: "nonzero"
}, transform: "matrix(.84669 0 0 .84669 -144.981 -136.984)" }), /* @__PURE__ */ React.createElement("path", { d: "M310.56 273.942a5.104 5.104 0 0 0-5.102-5.102h-4.376a5.104 5.104 0 0 0-5.102 5.102v4.376a5.104 5.104 0 0 0 5.102 5.102h4.376a5.104 5.104 0 0 0 5.102-5.102z", style: {
  fill: "#ecd6bd"
}, transform: "scale(.84669)rotate(-89.17 135.562 282.1)" }), /* @__PURE__ */ React.createElement("path", { d: "m309.84 311.22-10.92-.61-.17-.84A53.84 53.84 0 0 0 288.11 287a1.125 1.125 0 0 1 .09-1.58 1.11 1.11 0 0 1 1.57.1 54.8 54.8 0 0 1 11 23l7 .39c.29-3.86 1.79-16.33 9.26-22.48a1.112 1.112 0 0 1 1.41 1.72c-7.92 6.53-8.5 21.81-8.51 22zM323.62 313.14a1.1 1.1 0 0 1-1.06-.79c-.15-.49-3.56-12 2.63-20.79.2-.345.57-.558.969-.558.614 0 1.12.506 1.12 1.12 0 .267-.096.525-.269.728-5.57 7.88-2.35 18.74-2.32 18.85q.05.16.049.327a1.113 1.113 0 0 1-1.119 1.113M286.7 312.93h-.08a1.105 1.105 0 0 1-1-1.19c0-.12.75-11.43-6.39-17.89a1.125 1.125 0 0 1-.07-1.58 1.11 1.11 0 0 1 1.57-.07c7.95 7.2 7.15 19.2 7.11 19.7a1.114 1.114 0 0 1-1.14 1.03", style: {
  fill: "#f37e7b",
  fillRule: "nonzero"
}, transform: "matrix(.84669 0 0 .84669 -144.981 -136.984)" }), /* @__PURE__ */ React.createElement("path", { d: "M302.75 277.55h-.01c-20.391 0-37.17-16.779-37.17-37.17s16.779-37.17 37.17-37.17 37.17 16.779 37.17 37.17v.01c-.005 20.383-16.777 37.155-37.16 37.16", style: {
  fill: "#fae3c8",
  fillRule: "nonzero"
}, transform: "matrix(.84669 0 0 .84669 -144.981 -136.984)" }), /* @__PURE__ */ React.createElement("path", { d: "M291 257.77c6.427 6.425 17.003 6.425 23.43 0", style: {
  fill: "#fff",
  fillRule: "nonzero"
}, transform: "matrix(.84669 0 0 .84669 -144.981 -136.984)" }), /* @__PURE__ */ React.createElement("path", { d: "M284 237.8h.01a2.884 2.884 0 0 1 2.87 2.87 2.884 2.884 0 0 1-2.87 2.87 2.884 2.884 0 0 1-2.87-2.87v-.01a2.874 2.874 0 0 1 2.86-2.86M319.71 237.8h.01a2.884 2.884 0 0 1 2.87 2.87 2.884 2.884 0 0 1-2.87 2.87 2.884 2.884 0 0 1-2.87-2.87v-.01a2.874 2.874 0 0 1 2.86-2.86", style: {
  fill: "#053350",
  fillRule: "nonzero"
}, transform: "matrix(.84669 0 0 .84669 -144.981 -136.984)" }), /* @__PURE__ */ React.createElement("path", { d: "M265.59 234.49c3.237 0 5.9 2.663 5.9 5.9s-2.663 5.9-5.9 5.9-5.9-2.663-5.9-5.9 2.663-5.9 5.9-5.9M339.91 234.49h.01c3.237 0 5.9 2.663 5.9 5.9s-2.663 5.9-5.9 5.9-5.9-2.663-5.9-5.9c0-3.233 2.657-5.895 5.89-5.9", style: {
  fill: "#fae3c8",
  fillRule: "nonzero"
}, transform: "matrix(.84669 0 0 .84669 -144.981 -136.984)" }), /* @__PURE__ */ React.createElement("path", { d: "M292.29 192.23c-8.29-3.13-10.56-15.53-2.54-20.23 5.71-3.13 22.06-6.39 28.09-3.35 5.5 2.45 17.94 13 14.81 21.28-2.48 6.06-13.18 7.87-19.3 7.06M339.82 257.62c.17-.33.23-.73.58-.91l-.36-.47a4.3 4.3 0 0 0-1.21 2.48c0 .14.16.29.28.15.248-.298.444-.636.58-1 .187-.444.447-.853.77-1.21.2-.24-.07-.66-.36-.46-.45.31-.51.76-.7 1.24-.1.24.31.41.42.18", style: {
  fill: "#9c6159",
  fillRule: "nonzero"
}, transform: "matrix(.84669 0 0 .84669 -144.981 -136.984)" }), /* @__PURE__ */ React.createElement("path", { d: "M269.78 251.73c9-14.74 2.45-47.44 29.84-32.93 5.47 3 8.69 6.5 15.45 5.09 5.05-.35 9.76-6.32 15.26-3.86 9.34 4.29 6.63 29.91 7.73 30.51v-.56c12.11-23 4.24-50.16-22.29-57.94-23.23-6.52-52.29 6.86-51.68 33.73.24 7.84 1.49 18.52 5.71 24.84", style: {
  fill: "#9c6159",
  fillRule: "nonzero"
}, transform: "matrix(.84669 0 0 .84669 -144.981 -136.984)" }), /* @__PURE__ */ React.createElement("path", { d: "M270 285.51c.47-7.32 5.19-13.53 5.54-20.94.18-3.57-.78-7.19-1.61-10.62-1-4-1-8.17-1.91-12.16a.51.51 0 0 0-.5-.41.51.51 0 0 0-.5.41c-2 6.72 2.6 14.68 3.23 21.42.73 7.82-4.43 14.56-4.56 22.3a.15.15 0 0 0 .29 0zM339.45 282.85c-1.74-8.16-4.27-16-2.28-24.41 1.31-5.53 5.66-11.77 3.88-17.6-.11-.34-.7-.41-.76 0a92 92 0 0 1-2 10.64c-.89 3.14-2.25 6.22-2.53 9.51a46 46 0 0 0 3.63 21.89c2.978 6.891.08 0 .07 0z", style: {
  fill: "#9c6159",
  fillRule: "nonzero"
}, transform: "matrix(.84669 0 0 .84669 -144.981 -136.984)" }), /* @__PURE__ */ React.createElement("path", { d: "M272.44 287.47c.46-7.37 2.82-13.69 2-21.17-.79-7-3.21-13.68-4-20.74a.63.63 0 0 0-.625-.551.63.63 0 0 0-.625.551c-.35 6.63 2.48 13.05 3.83 19.45 1.62 7.73-.81 14.78-.78 22.46 0 .049.041.09.09.09a.09.09 0 0 0 .09-.09zM337.53 288.67c-1.56-7.34 1.73-14.6 2.45-21.85.45-4.55.38-9.21.62-13.77.23-4.26 1-8.87.1-13.07a.481.481 0 0 0-.91 0c-.9 4.19-.53 8.78-.73 13.07-.21 4.59-.24 9.17-.28 13.77-.07 7.37-3.94 14.62-1.68 22a.224.224 0 0 0 .215.163.225.225 0 0 0 .215-.283zM286.37 170.31c-2.6 1.32-4.79 3.2-5.54 6.12-.68 2.64.34 5.42 1.69 7.65 2.89 4.77 9 9.4 14.82 8.8.22 0 .16-.33 0-.35a20.23 20.23 0 0 1-13.62-7.6 11.45 11.45 0 0 1-2.57-7.25c.12-3.29 2.72-5.62 5.41-7.08a.17.17 0 0 0-.16-.29z", style: {
  fill: "#9c6159",
  fillRule: "nonzero"
}, transform: "matrix(.84669 0 0 .84669 -144.981 -136.984)" }), /* @__PURE__ */ React.createElement("path", { d: "M290.66 191.07c-7.29-1-13.74-8.69-21.39-4.89-.1 0 0 .23.07.19 4.32-1.36 7.73-.45 11.66 1.5 3.12 1.55 6.12 3.24 9.64 3.7.32 0 .33-.46 0-.5zM319.85 194.35c5.11.13 14.2 1.14 16.52-5.07 1.33-3.56.57-11.71-4.5-12.06-.19 0-.19.25 0 .31 4.57 1.8 5.91 8.08 3.59 12.21-2.85 5.08-10.74 4.12-15.57 4.2a.21.21 0 0 0 0 .41z", style: {
  fill: "#9c6159",
  fillRule: "nonzero"
}, transform: "matrix(.84669 0 0 .84669 -144.981 -136.984)" }), /* @__PURE__ */ React.createElement("path", { d: "M324.32 195.15c6.17 0 12.74-.42 16.39 5.48.07.1.25 0 .2-.09-2.27-6.76-11-5.78-16.59-5.73a.17.17 0 0 0-.17.17c0 .093.077.17.17.17M270.45 248.44c-5.86-2.14-9.47-8.3-9.87-14.34-.45-6.78 3.47-11.44 6.57-17 .07-.12-.1-.27-.2-.15-4.16 5.27-7.48 11.32-6.77 18.24.61 5.84 4.56 11.61 10.16 13.61.25.09.35-.29.11-.38z", style: {
  fill: "#9c6159",
  fillRule: "nonzero"
}, transform: "matrix(.84669 0 0 .84669 -144.981 -136.984)" }), /* @__PURE__ */ React.createElement("path", { d: "M264.11 223.4c-2.8-4.92-.81-11.47 1.81-16 2.75-4.76 8-6.22 11.86-9.76.13-.13 0-.34-.2-.26-4.81 2.67-10.09 5.88-12.71 10.87-2.32 4.42-3.82 10.81-1.05 15.35a.17.17 0 0 0 .132.063.17.17 0 0 0 .158-.233zM339.78 250.74A18.42 18.42 0 0 0 350 238.36c1.28-5.4-2.32-10.36-4.05-15.19a.13.13 0 0 0-.24.069v.001c.88 4.8 4.17 8.59 3.93 13.68-.29 5.93-5.14 10.84-10 13.51-.2.11 0 .4.18.31z", style: {
  fill: "#9c6159",
  fillRule: "nonzero"
}, transform: "matrix(.84669 0 0 .84669 -144.981 -136.984)" }), /* @__PURE__ */ React.createElement("path", { d: "M330.73 298.89s7.63 9.47 14 19.39 13.56 24.34 19.07 44.63c5.93-.45 15.67 11.73 4.66 15.33s-18.22 3.16-16.1-12.17c-5.09-18.49-13.56-42.38-18.64-50.5s-6.38-13.97-2.99-16.68M278.34 295.18c-5.51.45-15.62 11.07-18 21.95-3.19 14.83-10.57 61.56-10.57 61.56s-3.81 17.14 5.93 17.59 22-.45 16.1-14.43c-8.47-17.58-5.47-50.75-1.48-63.39 2.48-7.73 13.52-23.73 8.02-23.28", style: {
  fill: "#fae3c8",
  fillRule: "nonzero"
}, transform: "matrix(.84669 0 0 .84669 -144.981 -136.984)" }), /* @__PURE__ */ React.createElement("path", { d: "m37.23 211.85-.97.86-27.33 4.58 20.77-38.06 1.04.38z", style: {
  fill: "#fff",
  fillRule: "nonzero"
}, transform: "matrix(.84669 0 0 .84669 54.498 2.635)" }), /* @__PURE__ */ React.createElement("path", { d: "m36.26 212.71-29.64 6.96L0 186.19l29.7-6.96z", style: {
  fill: "#751113",
  fillRule: "nonzero"
}, transform: "matrix(.84669 0 0 .84669 54.498 2.635)" }), /* @__PURE__ */ React.createElement("path", { d: "m36.27 212.69-29.65 6.98 25.9-.76 28.9-6.26z", style: {
  fill: "#b97d2b",
  fillRule: "nonzero"
}, transform: "matrix(.84669 0 0 .84669 54.498 2.635)" }), /* @__PURE__ */ React.createElement("path", { d: "m36.27 212.69.96-.84 24.18.01.01.79z", style: {
  fill: "#fff",
  fillRule: "nonzero"
}, transform: "matrix(.84669 0 0 .84669 54.498 2.635)" }), /* @__PURE__ */ React.createElement("path", { d: "M153.47 226.03 98.3 181.54l3.43-3.85z", style: {
  fill: "#99918b",
  fillRule: "nonzero"
}, transform: "matrix(.84669 0 0 .84669 54.498 2.635)" }), /* @__PURE__ */ React.createElement("path", { d: "m102.8 178.73-3.34 3.64-8.98-5.25 6.27-6.85z", style: {
  fill: "#fff",
  fillRule: "nonzero"
}, transform: "matrix(.84669 0 0 .84669 54.498 2.635)" }), /* @__PURE__ */ React.createElement("path", { d: "M325.79 324.28a5.2 5.2 0 0 0-1.08-1.83c.11.35.14.93.21 1.16a8 8 0 0 0-.46-.95c.14.47.25 1 .43 1.43a8.3 8.3 0 0 0-.83-1.49q.319.621.48 1.3c-.13-.19-.33-.4-.47-.62.022.537.141 1.065.35 1.56-.58-.53-.72-1.35-1.27-1.93.045.704.251 1.388.6 2-.13-.23-.43-.56-.55-.74q.159.678.22 1.37c-.41-.66-.87-1.3-1.26-2 .24.74.56 1.48.79 2.18a7.3 7.3 0 0 1-1.11-1.53q.498.857.83 1.79a11.4 11.4 0 0 1-1.43-2c.334.783.547 1.613.63 2.46a4.5 4.5 0 0 0-.75-1.32c.151.438.258.891.32 1.35-.2-.32-.48-.59-.67-.91.09.46.08 1 .15 1.5-.14-.14-.31-.25-.46-.38 0 .31-.09.71 0 1-.16-.21-.41-.37-.56-.56q.018.505.12 1a3 3 0 0 0-.18-.36c.172.378.274.785.3 1.2-.2-.23-.63-.49-.84-.73q.13.433.2.88c-.39-.36-.85-.8-1.25-1.2.296.647.654 1.263 1.07 1.84a8 8 0 0 1-1.65-1.74 3.84 3.84 0 0 1 .81 2.07c-.26-.39-.66-.7-.92-1.08.18.443.304.906.37 1.38a2.9 2.9 0 0 1-.79-.57c.17.44 0 .76 0 1.08-.07-.06-.26-.24-.34-.29a7 7 0 0 0 .15.85.33.33 0 0 1-.24 0c.12.26.18.56.29.82-.26-.31-.56-.54-.79-.87q.269.65.64 1.25c-.75 0-1.44-.72-1.85-1.39.23.38.46.85.68 1.19a4.1 4.1 0 0 1-1.07-.84c.527.507.851 1.191.91 1.92-.22-.24-.54-.45-.75-.7q.25.487.44 1a1.7 1.7 0 0 1-1.26-1q.414.736.75 1.51a3.4 3.4 0 0 1-1-.27q.35.267.65.59a3.24 3.24 0 0 1-1.63.33c.346.322.782.531 1.25.6l12.1 6.41 6.28-6.85z", style: {
  fill: "#bb5b27",
  fillRule: "nonzero"
}, transform: "matrix(.84669 0 0 .84669 -144.981 -136.984)" }));
const ForwardRef = forwardRef(SvgHb);
const Memo = memo(ForwardRef);
export default Memo;
