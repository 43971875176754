import { facebookPages } from '@promoboxx/redux-stores'
import { createSelector } from 'reselect'

import { currentRetailerAccountIdSelector } from '../app/selectors'
import {
  firstPremiumPaidAdSelector,
  selectPaidAdByRouteId,
} from '../paidAds/selectors'

export const facebookPagesSelector = (state) => state.facebookPages || {}
export const retailerPagesSelector = (state) =>
  state.retailersFacebookPages || {}
export const facebookPagesByRetailerSelector = (state) =>
  (state.retailersFacebookPages || {}).byRetailerId || {}
export const facebookPagesById = createSelector(
  [facebookPagesByRetailerSelector],
  (facebookPagesByRetailer) => {
    const pages = {}
    for (const retailerId in facebookPagesByRetailer) {
      for (const facebookPage of facebookPagesByRetailer[retailerId]) {
        pages[facebookPage.id] = facebookPage
      }
    }
    return pages
  },
)

export const selectFacebookPagesForCurrentRetailerAccount = createSelector(
  [
    currentRetailerAccountIdSelector,
    facebookPages.selectors.facebookPagesByRetailerAccountSelector,
  ],
  (currentRetailerAccount, facebookPagesByRetailerAccount) => {
    return {
      facebookPages:
        facebookPagesByRetailerAccount[currentRetailerAccount] || [],
    }
  },
)

export const selectValidFacebookPagesForCurrentRetailerAccount = createSelector(
  [selectFacebookPagesForCurrentRetailerAccount],
  (allFacebookPages) => {
    const facebookPages = allFacebookPages.facebookPages.filter(
      (page) =>
        page.instagram_actor_id !== null &&
        page.business_manager_permissions === true,
    )

    return {
      facebookPages,
    }
  },
)

export const selectFacebookPagesStatus = createSelector(
  [facebookPagesSelector],
  (facebookPages) => {
    return {
      facebookPagesStatus: facebookPages.facebookPagesStatus,
    }
  },
)

export const retailerIdForFeaturedPaidAdSelector = createSelector(
  [firstPremiumPaidAdSelector],
  (firstPremiumPaidAd) => {
    return firstPremiumPaidAd && firstPremiumPaidAd.retailerId
  },
)

export const retailerIdForModalPaidAdSelector = createSelector(
  [selectPaidAdByRouteId],
  (selectPaidAdByRoute) => {
    return (
      selectPaidAdByRoute &&
      selectPaidAdByRoute.campaign &&
      selectPaidAdByRoute.campaign.retailerId
    )
  },
)

export const retailerFacebookPagesSelector = createSelector(
  [
    retailerIdForFeaturedPaidAdSelector,
    retailerIdForModalPaidAdSelector,
    facebookPagesByRetailerSelector,
  ],
  (
    retailerIdForFeaturedPaidAd,
    retailerIdForModalPaidAd,
    facebookPagesByRetailer,
  ) => {
    const retailerId = retailerIdForModalPaidAd || retailerIdForFeaturedPaidAd
    return facebookPagesByRetailer[retailerId] || []
  },
)

export const selectValidFacebookPagesForRetailer = createSelector(
  [retailerFacebookPagesSelector],
  (retailerFacebookPages) => {
    const facebookPages = retailerFacebookPages.filter(
      (page) =>
        page.instagram_actor_id !== null &&
        page.business_manager_permissions === true,
    )

    return {
      facebookPages,
    }
  },
)

export const selectRetailerFacebookPagesStatus = createSelector(
  [retailerPagesSelector],
  (facebookPages) => {
    return {
      facebookPagesStatus: facebookPages.facebookPagesStatus,
    }
  },
)
