import * as React from "react";
import { forwardRef, memo } from "react";
const SvgInstagram = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", "xmlns:bx": "https://boxy-svg.com", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 22 22", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("radialGradient", { xlinkHref: "#a", id: "b", cx: 309.149, cy: 299.561, r: 11, gradientTransform: "matrix(2.7373 0 0 2.31903 -537.667 -393.54)", gradientUnits: "userSpaceOnUse" }), /* @__PURE__ */ React.createElement("linearGradient", { "bx:pinned": "true", id: "a" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#fd5"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.1, style: {
  stopColor: "#fd5"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.5, style: {
  stopColor: "#ff543e"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#c837ab"
} }))), /* @__PURE__ */ React.createElement("g", { transform: "translate(-309.48 -276.36)" }, /* @__PURE__ */ React.createElement("circle", { cx: 320.484, cy: 287.357, r: 11, style: {
  fill: "url(#b)"
} }), /* @__PURE__ */ React.createElement("path", { fill: "#FFF", d: "M320.485 280.926c-1.734 0-1.951.008-2.632.04-.68.03-1.143.139-1.549.298-.42.164-.775.384-1.13.741a3.2 3.2 0 0 0-.737 1.14c-.158.409-.266.876-.296 1.56-.03.686-.039.906-.039 2.652 0 1.747.008 1.965.039 2.651s.139 1.152.296 1.56c.164.424.382.783.736 1.14.355.358.711.578 1.13.742.407.16.87.268 1.55.299s.898.039 2.63.039c1.734 0 1.95-.008 2.632-.039.679-.031 1.143-.14 1.55-.299a3.1 3.1 0 0 0 1.129-.742c.326-.322.577-.71.737-1.139.156-.409.264-.876.296-1.56.03-.687.039-.905.039-2.652 0-1.746-.008-1.966-.039-2.652-.032-.684-.14-1.151-.296-1.56a3.15 3.15 0 0 0-.737-1.14 3.1 3.1 0 0 0-1.13-.741c-.407-.16-.871-.267-1.55-.299-.681-.031-.898-.039-2.631-.039zm-.573 1.16h.573c1.703 0 1.905.005 2.578.036.622.029.96.134 1.185.222.298.116.51.256.734.48.223.226.361.44.477.74.087.227.192.567.22 1.194.03.678.037.881.037 2.598s-.007 1.92-.037 2.597c-.029.627-.133.968-.22 1.194a2 2 0 0 1-.477.739 2 2 0 0 1-.734.48c-.225.09-.563.194-1.185.222-.672.031-.875.038-2.578.038-1.704 0-1.906-.007-2.58-.038-.621-.029-.96-.133-1.184-.221a2 2 0 0 1-.734-.481 2 2 0 0 1-.478-.74c-.087-.226-.191-.566-.22-1.193-.03-.678-.036-.882-.036-2.599s.006-1.92.037-2.598c.028-.627.132-.967.22-1.194.115-.3.254-.514.477-.74.206-.212.457-.376.734-.479.225-.089.563-.193 1.185-.222.589-.027.817-.035 2.006-.036zm3.979 1.067a.77.77 0 0 0-.766.771.77.77 0 0 0 .766.772.77.77 0 0 0 .766-.772.77.77 0 0 0-.766-.771m-3.406.902c-1.81 0-3.278 1.478-3.278 3.302s1.468 3.302 3.278 3.302 3.276-1.478 3.276-3.302-1.467-3.302-3.277-3.302zm0 1.159c1.174 0 2.127.96 2.127 2.143a2.135 2.135 0 0 1-2.127 2.144 2.135 2.135 0 0 1-2.128-2.144c0-1.184.953-2.143 2.128-2.143", mask: "url(#f)" })));
const ForwardRef = forwardRef(SvgInstagram);
const Memo = memo(ForwardRef);
export default Memo;
