import { RetailerAccount, User } from '@promoboxx/graphql-gateway-types'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import AppConfig from '@src/config/AppConfig'
import useCurrentAuth from '@src/lib/useCurrentAuth'
import { currentRetailerAccountSelector } from '@src/stores/app/selectors'

import analytics, { IdentifiedUser } from './index'

const AnalyticsLoader: React.FC<{}> = () => {
  const { pathname, search } = useLocation()

  const refererRef = useRef<string>()
  const { currentUser: user, retailerAccounts } = useCurrentAuth()
  const currentRetailerAccount: RetailerAccount | undefined = useSelector(
    currentRetailerAccountSelector,
  )

  useEffect(() => {
    if (user) {
      const identifiedUser = getStandardizedUser(
        user,
        retailerAccounts,
        currentRetailerAccount,
      )
      analytics.identify(identifiedUser)
    }
    // clear any stale data to prevent leaks, segment will hold onto stale data if we don't reset it
    return () => {
      analytics.reset()
    }
  }, [user, retailerAccounts, currentRetailerAccount])

  useEffect(() => {
    analytics.setCommonInfo({
      retailerAccountId: currentRetailerAccount?.id,
      retailerAccountName: currentRetailerAccount?.name,
    })
  }, [currentRetailerAccount])

  // Track page loads.
  useEffect(() => {
    analytics.page({
      type: 'Page View',
      path: pathname,
      referer: refererRef.current,
      title: document.title,
      url: window.location.href,
      search,
    })

    refererRef.current = pathname
  }, [pathname, search])

  // Easy tracking of elements with `data-track-identifier` attribute, or `data-testid` since anything we test we probably want to track.
  useEffect(() => {
    function listener(event: MouseEvent) {
      if (!(event.target instanceof HTMLElement)) {
        return
      }

      const elementWithTrackingAttribute = event.target.closest(
        '[data-track-identifier], [data-testid]',
      )

      if (!elementWithTrackingAttribute) {
        return
      }

      const identifier =
        elementWithTrackingAttribute.getAttribute('data-track-identifier') ||
        elementWithTrackingAttribute.getAttribute('data-testid')

      if (!identifier) {
        return
      }

      analytics.track({
        event: 'Click',
        type: identifier,
      })
    }

    document.addEventListener('click', listener, {
      capture: true,
    })

    return () => {
      document.removeEventListener('click', listener, {
        capture: true,
      })
    }
  }, [])

  return null
}

export default AnalyticsLoader

// this function is used to standardize the user object for analytics based off the old analytics redux implementation
const getStandardizedUser = (
  user: User,
  retailerAccounts?: RetailerAccount[],
  currentRetailerAccount?: RetailerAccount,
) => {
  let brandNames: string[] = []
  let brandIds: string[] = []
  let industryList: string[] = []
  let retailerAccountNames: string[] = []
  let retailerAccountIds: string[] = []
  let retailerIds: string[] = []

  let retailerContentDiscovery = false
  let enableBrandRequests = false
  let enablePicInDiscoverBrands = false
  let enableIndustrySuggestions = false

  if (retailerAccounts) {
    retailerAccounts.forEach((retailerAccount) => {
      if (retailerAccount) {
        const retailerAccountName = retailerAccount?.name || ''
        const retailerAccountId = retailerAccount.id
        if (!retailerAccountIds.includes(retailerAccountId)) {
          retailerAccountNames.push(retailerAccountName)
          retailerAccountIds.push(String(retailerAccountId))
        }
        if (retailerAccount.retailers) {
          retailerAccount.retailers.forEach((retailer) => {
            if (retailer && retailer.brand) {
              const brandName = retailer?.brand?.name || ''
              const brandId = String(retailer.brand.id)
              // This whole snippet was written by GitHub Copilot
              retailer.brand.industry_list &&
                retailer.brand.industry_list.forEach((industry) => {
                  if (!industryList.includes(industry)) {
                    industryList.push(industry)
                  }
                })
              const retailerId = String(retailer.id)
              if (!brandIds.includes(brandId)) {
                brandNames.push(brandName)
                brandIds.push(brandId)
              }
              retailerIds.push(retailerId)
            }
          })
        }
      }
    })
  }

  if (currentRetailerAccount) {
    retailerContentDiscovery =
      currentRetailerAccount.retailers?.some(
        (retailer) => retailer.brand?.settings?.brand_content_discovery,
      ) || false
    enableBrandRequests =
      currentRetailerAccount.retailers?.every(
        (retailer) => retailer.brand?.brand_requests_enabled,
      ) || false
    enablePicInDiscoverBrands =
      currentRetailerAccount.retailers?.every(
        (retailer) =>
          retailer.brand?.settings
            ?.retailer_connection_requests_pic_suggestions_enabled,
      ) || false
    enableIndustrySuggestions =
      currentRetailerAccount.retailers?.every(
        (retailer) =>
          retailer.brand?.settings
            ?.retailer_connection_requests_industry_suggestions_enabled,
      ) || false
  }

  const identifiedUser: IdentifiedUser = {
    id: user?.id || '',
    email: user?.email || '',
    createdAt: user?.created_at || '',
    type: user?.type || '',
    env: AppConfig.ENV,
    app: 'retailer',
    uuid: user?.uuid || '',
    timeZone: user?.time_zone || '',
    timeZoneAbbr: user?.time_zone_abbr || '',
    name: `${user?.first_name} ${user?.last_name}`,
    firstName: user?.first_name || '',
    lastName: user?.last_name || '',
    languagePreference: user?.language_preference || 'en-us' || '',
    roles: user?.user_roles || [],
    industryList,
    brandNames,
    brandIds,
    retailerAccountNames,
    retailerAccountIds,
    retailerIds,
    retailerContentDiscovery,
    enableBrandRequests,
    enablePicInDiscoverBrands,
    enableIndustrySuggestions,
  }
  return identifiedUser
}
