import {
  retailerAccounts,
  retailersFacebookPages,
} from '@promoboxx/redux-stores'
import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, withLatestFrom } from 'rxjs/operators'

import history from '@src/lib/history'

import * as actions from '../actions'
import * as selectors from '../selectors'

const createRetailerFacebookPageActions = (retailerIds, triggerActions) => {
  retailerIds.forEach((retailerId) => {
    triggerActions.push({
      type: retailersFacebookPages.actions.GET_RETAILER_FACEBOOK_PAGES,
      payload: {
        retailerId,
      },
    })
  })
}

export const fetchRetailerDetailsOnSelectRetailer = (actions$, state$) => {
  return actions$.pipe(
    ofType(actions.SELECT_RETAILER_ACCOUNT),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { id } = action.payload
      const triggerActions = []
      const retailerIds =
        selectors.retailerIdsForCurrentBrandOrCurrentRetailerAccountSelector(
          state,
        )

      triggerActions.push({
        type: retailerAccounts.actions.GET_RETAILER_ALERTS,
        payload: {
          id,
          fields: ['subjectable'],
        },
      })

      triggerActions.push({
        type: retailerAccounts.actions.GET_ALLOCATION_DETAILS,
        payload: { id },
      })

      createRetailerFacebookPageActions(retailerIds, triggerActions)

      return triggerActions
    }),
  )
}

export const handleRetailerAccountSelect = (actions$, state$) => {
  return actions$.pipe(
    ofType(actions.SELECT_RETAILER_ACCOUNT),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      if (action.payload.skipUrlUpdate) {
        return of(actions.skipRetailerAccountUrlUpdate())
      } else {
        const pathname = history.instance().location.pathname
        const retailerAccount = selectors.retailerAccountFromUrlSelector(state)
        const brand = selectors.brandFromUrlSelector(state)
        const currentSlugs =
          selectors.currentRetailerAccountAndBrandSlugSelector(state)
        const oldUrlPart = `${retailerAccount.slug}/${
          (brand && brand.slug) || 'all'
        }`
        const newUrlPart = `${currentSlugs.retailerAccount}/all`
        const newPath = pathname.replace(oldUrlPart, newUrlPart)
        return of(actions.updateUrl(newPath))
      }
    }),
  )
}
