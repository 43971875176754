import { createSelector } from 'reselect'

export const tagsForCurrentRetailerAccountSelector = (state) =>
  state.retailerAccountsFilters && state.retailerAccountsFilters.tags

export const selectTagsForCurrentRetailerAccount = createSelector(
  [tagsForCurrentRetailerAccountSelector],
  (tags = []) => {
    const tagsItems = tags.map((tag) => {
      return {
        label: tag,
        value: tag,
      }
    })
    return {
      tagsItems,
    }
  },
)
