import { jwtDecode, JwtPayload } from 'jwt-decode'

function parsePbxxJwt(token?: string | null) {
  if (token) {
    return jwtDecode<PbxxJwt>(token)
  }

  return
}

export function isPbxxJwtExpired(jwt?: PbxxJwt | undefined) {
  if (!jwt) {
    return true
  }

  if (!jwt.exp) {
    return true
  }

  return new Date(jwt.exp * 1000) < new Date()
}

export interface PbxxJwt extends JwtPayload {
  permission: {
    retailers: number[]
    retailer_accounts: number[]
    brands: number[]
    divisions: number[]
    brands_uuids: string[]
    business_ids: string[]
  }
  roles: string[]
  initiating_user: number
  initiating_user_uuid: string
  sub_uuid: string
}

export default parsePbxxJwt
