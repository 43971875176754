import * as adActions from '@promoboxx/redux-stores/src/stores/ad/actions'
import { getStats } from '@promoboxx/redux-stores/src/stores/stats/actions'
import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { withLatestFrom, mergeMap } from 'rxjs/operators'

import { retailerIdsForCurrentBrandOrCurrentRetailerAccountSelector } from '../../app/selectors'
import { activatedPaidAdsSelector } from '../../paidAds/selectors'
import * as actions from '../actions'

export const getCampaignStats = (action$, state$) => {
  return action$.pipe(
    ofType(adActions.GET_PAID_ADS_FULFILLED),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const retailerIds =
        retailerIdsForCurrentBrandOrCurrentRetailerAccountSelector(state)
      const paidAds = activatedPaidAdsSelector(state)
      const campaignIds = paidAds.map((paidAd) => paidAd.id)
      const params = {
        retailerIds,
        filterIds: campaignIds,
      }

      if (campaignIds.length === 0) {
        return of({
          type: actions.NO_STATS_TO_FETCH,
        })
      }

      return of(
        getStats({
          statsType: 'retailer_campaigns',
          params,
        }),
      )
    }),
  )
}
