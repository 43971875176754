import cloudinary from 'cloudinary-core'

import AppConfig from '../config/AppConfig'

/** @type {import('cloudinary-core').Cloudinary} */
let cl

export const getImageMetadata = (publicId, options = {}) => {
  return cl.url(publicId, {
    flags: 'getinfo',
    ...options,
  })
}

export const initCloudinary = () => {
  cl = new cloudinary.Cloudinary({
    cloud_name: AppConfig.CLOUD_NAME,
    secure: true,
  })
}

const cloudinaryRef = {
  instance: () => cl,
  /**
   * @param  {Parameters<import('cloudinary-core').Cloudinary['url']>} args
   */
  getCloudinaryUrl: (...args) => cl.url(...args),
  /**
   * @param  {Parameters<import('cloudinary-core').Cloudinary['video_url']>} args
   */
  getCloudinaryVideoUrl: (...args) => cl.video_url(...args),
  getImageMetadata,
  /**
   * @param  {Parameters<import('cloudinary-core').Cloudinary['video_thumbnail_url']>} args
   */
  getVideoThumbnailUrl: (...args) => cl.video_thumbnail_url(...args),
}

export default cloudinaryRef
