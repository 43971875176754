import {
  IdentifiedUser,
  AnalyticsTrackEvent,
  AnalyticsPageEvent,
} from '@src/analytics'

import cloudinary from './cloudinary'
import datadog from './datadog'
import fullstory from './fullstory'
import googleAnalytics from './googleAnalytics'
import oneTrust from './oneTrust'
import refiner from './refiner'
import segmentAnalytics from './segmentAnalytics'
import stripe from './stripe'
import userpilot from './userpilot'
import zendesk from './zendesk'
export interface Service<Instance = unknown> {
  instance: () => Instance | undefined
  loaded: () => boolean
  init?: () => Promise<void>
  identify?: (data: IdentifiedUser) => void
  track?: (data: AnalyticsTrackEvent) => void
  page?: (data: AnalyticsPageEvent) => void
}

const services: { [key: string]: Service } = {
  datadog,
  oneTrust,
  segmentAnalytics,
  zendesk,
  googleAnalytics,
  fullstory,
  refiner,
  stripe,
  cloudinary,
  userpilot,
}

const timeOutLimit = (serviceName: string) => {
  return new Promise((_, reject) => {
    setTimeout(
      reject,
      5000,
      new Error(`[services] [init] [${serviceName}] - timeout limit reached`),
    )
  })
}

const service = {
  init() {
    const initializations = []
    for (const serviceName in services) {
      const service = services[serviceName]
      if (service?.init) {
        initializations.push(
          Promise.race([timeOutLimit(serviceName), service.init()]).catch(
            (err) => console.error(err),
          ),
        )
      }
    }

    return Promise.allSettled(initializations)
  },

  identify(user: IdentifiedUser) {
    for (const serviceName in services) {
      const service = services[serviceName]
      if (service?.loaded() && service?.identify) {
        try {
          service?.identify(user)
        } catch (err) {
          console.error(err)
        }
      }
    }
  },

  track(data: AnalyticsTrackEvent) {
    for (const serviceName in services) {
      const service = services[serviceName]
      if (service?.loaded() && service?.track) {
        try {
          service.track(data)
        } catch (err) {
          console.error(err)
        }
      }
    }
  },

  page(data: AnalyticsPageEvent) {
    for (const serviceName in services) {
      const service = services[serviceName]
      if (service?.loaded() && service?.page) {
        try {
          service.page(data)
        } catch (err) {
          console.error(err)
        }
      }
    }
  },
}

export default service
