import { auth } from '@promoboxx/redux-stores'

import { createReducer } from '../../lib/Utils'

import { HIDE_FORGOT_PASSWORD_CONFIRMATION } from './actions'

const initialState = {}

export default createReducer(initialState, {
  [auth.actions.RESET_PASSWORD_FULFILLED]: (state) => {
    return { ...state, showForgotPasswordConfirmation: true }
  },
  [HIDE_FORGOT_PASSWORD_CONFIRMATION]: (state) => {
    return { ...state, showForgotPasswordConfirmation: false }
  },
})
