export const INITIALIZE_CONTENT = 'INITIALIZE_CONTENT'
export const CONTENT_INITIALIZED = 'CONTENT_INITIALIZED'
export const initializeContent = () => ({
  type: INITIALIZE_CONTENT,
})

export const SET_CAMPAIGN_LIST_SIZE = 'SET_CAMPAIGN_LIST_SIZE'
export const setCampaignListSize = (payload) => ({
  type: SET_CAMPAIGN_LIST_SIZE,
  payload,
})

export const USE_STATS_FROM_CACHE = 'USE_STATS_FROM_CACHE'
export const NO_STATS_TO_FETCH = 'NO_STATS_TO_FETCH'
