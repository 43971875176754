import * as React from "react";
import { forwardRef, memo } from "react";
const SvgCheckCircle = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 38 38", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "#4CAF50", fillRule: "evenodd", d: "m27.483 11.5 2.35 2.367L16.5 27.2l-8.333-8.333 2.35-2.35 5.983 5.966zM19 .667C29.13.667 37.333 8.87 37.333 19c0 10.13-8.204 18.333-18.333 18.333C8.87 37.333.667 29.13.667 19 .667 8.87 8.87.667 19 .667m0 33.75c8.498 0 15.417-6.919 15.417-15.417S27.498 3.583 19 3.583 3.583 10.502 3.583 19 10.502 34.417 19 34.417" }));
const ForwardRef = forwardRef(SvgCheckCircle);
const Memo = memo(ForwardRef);
export default Memo;
