export const HIDE_FORGOT_PASSWORD_CONFIRMATION =
  'HIDE_FORGOT_PASSWORD_CONFIRMATION'
export const hideForgotPasswordConfirmation = () => ({
  type: HIDE_FORGOT_PASSWORD_CONFIRMATION,
})

export const REDIRECT_URL_SAVED = 'REDIRECT_URL_SAVED'
export const NO_REDIRECT_URL_GIVEN = 'NO_REDIRECT_URL_GIVEN'
export const redirectUrlSaved = (payload) => ({
  type: REDIRECT_URL_SAVED,
  payload,
})
