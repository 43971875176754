import * as React from "react";
import { forwardRef, memo } from "react";
const SvgFacebook = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 22 22", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("circle", { cx: 11, cy: 11, r: 11, fill: "#3B5998" }), /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M12.5 6H15V3h-2.5C10.57 3 9 4.57 9 6.5V8H7v3h2v8h3v-8h2.5l.5-3h-3V6.5c0-.271.229-.5.5-.5" }));
const ForwardRef = forwardRef(SvgFacebook);
const Memo = memo(ForwardRef);
export default Memo;
