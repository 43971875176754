import {
  buttonClasses,
  fabClasses,
  PaletteColor,
  PaletteColorOptions,
  SimplePaletteColorOptions,
  Theme,
} from '@mui/material'
import { ThemeOptions, createTheme, lighten } from '@mui/material/styles'
import type {} from '@mui/x-data-grid/themeAugmentation'

declare module '@mui/material/styles' {
  // Adding colors here lets you define them in `createTheme`.
  export interface PaletteOptions {
    premiumAds: PaletteColorOptions
    facebook: PaletteColorOptions
    twitter: PaletteColorOptions
    instagram: PaletteColorOptions
    pbxxCyan: PaletteColorOptions
    discovery: PaletteColorOptions
    tiktok: PaletteColorOptions
  }

  // Adding colors here lets you access them in `theme.palette`.
  export interface Palette {
    premiumAds: PaletteColor
    facebook: PaletteColor
    twitter: PaletteColor
    instagram: PaletteColor
    pbxxCyan: PaletteColor
    discovery: PaletteColor
    tiktok: PaletteColor
  }
}

// Annnnnd again here for `color="myNewColor"` support.
interface CustomThemeColors {
  premiumAds: true
  facebook: true
  twitter: true
  instagram: true
  pbxxCyan: true
  discovery: true
  tiktok: true
}

// These don't need to be modified. Found with running this in a terminal:
//     ag 'interface .+PropsColorOverrides' node_modules/@mui/material/
declare module '@mui/material/Badge' {
  export interface BadgePropsColorOverrides extends CustomThemeColors {}
}
declare module '@mui/material/Button' {
  export interface ButtonPropsColorOverrides extends CustomThemeColors {}
}
declare module '@mui/material/Chip' {
  export interface ChipPropsColorOverrides extends CustomThemeColors {}
}
declare module '@mui/material/Pagination' {
  export interface PaginationPropsColorOverride extends CustomThemeColors {}
}
declare module '@mui/material/TextField' {
  export interface TextFieldPropsColorOverrides extends CustomThemeColors {}
}
declare module '@mui/material/Alert' {
  export interface AlertPropsColorOverrides extends CustomThemeColors {}
}
declare module '@mui/material/Radio' {
  export interface RadioPropsColorOverrides extends CustomThemeColors {}
}
declare module '@mui/material/Icon' {
  export interface IconPropsColorOverrides extends CustomThemeColors {}
}
declare module '@mui/material/InputBase' {
  export interface InputBasePropsColorOverrides extends CustomThemeColors {}
}
declare module '@mui/material/PaginationItem' {
  export interface PaginationItemPropsColorOverrides
    extends CustomThemeColors {}
}
declare module '@mui/material/LinearProgress' {
  export interface LinearProgressPropsColorOverrides
    extends CustomThemeColors {}
}
declare module '@mui/material/Slider' {
  export interface SliderPropsColorOverrides extends CustomThemeColors {}
}
declare module '@mui/material/CircularProgress' {
  export interface CircularProgressPropsColorOverrides
    extends CustomThemeColors {}
}
declare module '@mui/material/ButtonGroup' {
  export interface ButtonGroupPropsColorOverrides extends CustomThemeColors {}
}
declare module '@mui/material/Fab' {
  export interface FabPropsColorOverrides extends CustomThemeColors {}
}
declare module '@mui/material/Switch' {
  export interface SwitchPropsColorOverrides extends CustomThemeColors {}
}
declare module '@mui/material/AppBar' {
  export interface AppBarPropsColorOverrides extends CustomThemeColors {}
}
declare module '@mui/material/ToggleButtonGroup' {
  export interface ToggleButtonGroupPropsColorOverrides
    extends CustomThemeColors {}
}
declare module '@mui/material/FormLabel' {
  export interface FormLabelPropsColorOverrides extends CustomThemeColors {}
}
declare module '@mui/material/ToggleButton' {
  export interface ToggleButtonPropsColorOverrides extends CustomThemeColors {}
}
declare module '@mui/material/Checkbox' {
  export interface CheckboxPropsColorOverrides extends CustomThemeColors {}
}
declare module '@mui/material/FormControl' {
  export interface FormControlPropsColorOverrides extends CustomThemeColors {}
}
declare module '@mui/material/IconButton' {
  export interface IconButtonPropsColorOverrides extends CustomThemeColors {}
}
declare module '@mui/material/SvgIcon' {
  export interface SvgIconPropsColorOverrides extends CustomThemeColors {}
}

export type ThemeAwareColor =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'info'
  | 'warning'
  | keyof CustomThemeColors

const bodyFont = 'Lato, Helvetica, sans-serif'
const unit = 4

const baseTheme: ThemeOptions = {
  // See https://material-ui.com/customization/default-theme/

  // Adapted from our own Breakpoints.js
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 992,
      lg: 1600,
      xl: 1920,
    },
  },

  typography: {
    fontFamily: bodyFont,
    fontSize: 14,
    h1: { fontFamily: bodyFont },
    h2: { fontFamily: bodyFont },
    h3: { fontFamily: bodyFont },
    h4: { fontFamily: bodyFont },
    h5: { fontFamily: bodyFont, fontWeight: 'bold' },
    h6: { fontFamily: bodyFont },
    // title: { fontFamily: headingFont },
    // headline: { fontFamily: headingFont },
  },

  palette: {
    mode: 'light',
    primary: {
      main: 'rgb(52, 115, 169)',
    },
    secondary: {
      main: '#00965c',
    },
    error: {
      main: '#EB011E',
    },
    background: {
      default: '#FAFAFA',
    },
    premiumAds: {
      main: '#FFC208',
    },
    facebook: {
      main: '#3b5998',
    },
    twitter: {
      main: '#000',
      contrastText: '#fff',
    },
    instagram: {
      main: '#c13584',
    },
    pbxxCyan: {
      main: '#17BCC9',
      contrastText: '#fff',
    },
    discovery: {
      main: '#7B26AF',
    },
    tiktok: {
      main: '#ff0050',
    },
  },

  shape: {
    borderRadius: 4,
  },

  spacing: unit,
  // spacing: {
  //   unit,
  // },

  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'normal',
        variant: 'outlined',
      },
      styleOverrides: {
        // The default margins aren't symmetrical, which makes it difficult to
        // nicely center things placed next to a form control. These values add up
        // to the defaults from material-ui.
        marginNormal: {
          // marginTop: 16,
          // marginBottom: 8,
          marginTop: 12,
          marginBottom: 12,
        },
        marginDense: {
          // marginTop: 8,
          // marginBottom: 4,
          marginTop: 6,
          marginBottom: 6,
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        variant: 'scrollable',
        scrollButtons: false,
        textColor: 'inherit',
        indicatorColor: 'secondary',
      },
    },

    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 40,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: {
          'boxShadow': 'none',
          [`&${buttonClasses.focusVisible}`]: {
            boxShadow: 'none',
          },
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          'boxShadow': 'none',
          [`&${fabClasses.focusVisible}`]: {
            boxShadow: 'none',
          },
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        // Helps `useMediaQuery`, with `noSsr: false` it always returns "false"
        // at first.
        // This could probably be set for more mui components.
        noSsr: true,
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
        },
      },
    },
  },
}

export const lightTheme = createTheme(baseTheme)

export const darkTheme = createTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    mode: 'dark',
    contrastThreshold: 3,
    primary: {
      // Our primary color clashes with our dark background.
      main: lighten(lightTheme.palette.primary.main, 0.3),
    },

    background: {
      default: 'rgb(50, 50, 50)',
    },

    // TODO Not psyched about having to repeat this, but TS is complaining.
    premiumAds: {
      main: '#FFC208',
    },
    facebook: {
      main: '#3b5998',
    },
    twitter: {
      main: '#1da1f2',
    },
    instagram: {
      main: '#517fa4',
    },
    pbxxCyan: {
      main: '#17BCC9',
      contrastText: '#fff',
    },
    discovery: {
      main: '#7B26AF',
    },
    tiktok: {
      main: '#ff0050',
    },
  },
})

function isMuiSimplePaletteColorOptions(
  input: unknown,
): input is SimplePaletteColorOptions {
  if (input == null) {
    return false
  }

  // `main` is the only required property on `SimplePaletteColorOptions`.
  return (input as any).main != null
}

function fillOutCustomPalette(theme: Theme) {
  // mui's Palette object isn't a simple `Record<string, Palette>`, it's arbitrary
  // keys and a variety of types. Don't worry, we use a function to assert
  // something is a Palette color.
  const paletteAny = theme.palette as unknown as Record<string, unknown>

  for (const paletteSectionName in theme.palette) {
    const maybePaletteColor = paletteAny[paletteSectionName]

    if (
      isMuiSimplePaletteColorOptions(maybePaletteColor) &&
      (maybePaletteColor.contrastText == null ||
        maybePaletteColor.dark == null ||
        maybePaletteColor.light == null)
    ) {
      paletteAny[paletteSectionName] = theme.palette.augmentColor({
        color: maybePaletteColor,
      })
    }
  }
}

fillOutCustomPalette(lightTheme)
fillOutCustomPalette(darkTheme)
