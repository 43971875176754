import { createSelector } from 'reselect'

const showForgotPasswordConfirmationSelector = (state) =>
  state.forgotPassword && state.forgotPassword.showForgotPasswordConfirmation

export const selectShowForgotPasswordConfirmation = createSelector(
  [showForgotPasswordConfirmationSelector],
  (showForgotPasswordConfirmation) => {
    return { showForgotPasswordConfirmation }
  },
)
