import * as React from "react";
import { forwardRef, memo } from "react";
const SvgBanner = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 22 22", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("circle", { cx: 11, cy: 11, r: 11, fill: "#09669F" }), /* @__PURE__ */ React.createElement("path", { fill: "#FFF", d: "M5.5 5.5h11c.506 0 .917.41.917.917v8.25c0 .506-.41.916-.917.916h-11a.917.917 0 0 1-.917-.916v-8.25c0-.507.41-.917.917-.917m.917 1.833v2.75h9.166v-2.75z" }));
const ForwardRef = forwardRef(SvgBanner);
const Memo = memo(ForwardRef);
export default Memo;
