import { auth, darklyFeatureFlags } from '@promoboxx/redux-stores'
import { ofType } from 'redux-observable'
import { merge, of } from 'rxjs'
import { mergeMap, withLatestFrom } from 'rxjs/operators'

import { selectCustomLaunchDarklyIdentifyFields } from '../selectors'

export const identifyLaunchDarklyUser = (actions$, state$) => {
  return actions$.pipe(
    ofType(
      auth.actions.VALIDATE_AUTH_FULFILLED,
      auth.actions.SIGN_IN_FULFILLED,
    ),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const user = action.payload && action.payload.user
      const custom = selectCustomLaunchDarklyIdentifyFields(state)
      const payload = {
        user: {
          key: user.email,
          anonymous: false,
          email: user.email,
          custom: {
            ...custom,
            languagePreference: user.language_preference,
          },
        },
      }

      return merge(
        of({
          type: darklyFeatureFlags.actions.LAUNCH_DARKLY_IDENTIFY_USER,
          payload,
        }),
      )
    }),
  )
}

export const identifyLaunchDarklyUserAnonymous = (actions$) => {
  return actions$.pipe(
    ofType(auth.actions.VALIDATE_AUTH_REJECTED),
    mergeMap(() => {
      const payload = {
        user: {
          key: 'anonymous@promoboxx.com',
          anonymous: true,
        },
      }
      return merge(
        of({
          type: darklyFeatureFlags.actions.LAUNCH_DARKLY_IDENTIFY_USER,
          payload,
        }),
      )
    }),
  )
}

export const launchDarklyReadyFulfilled = (actions$) => {
  return actions$.pipe(
    ofType(darklyFeatureFlags.actions.LAUNCH_DARKLY_READY_FULFILLED),
    mergeMap(() => {
      return merge(
        of({
          type: darklyFeatureFlags.actions
            .LAUNCH_DARKLY_SUBSCRIBE_ALL_FEATURE_FLAGS,
        }),
        of({
          type: darklyFeatureFlags.actions.LAUNCH_DARKLY_GET_ALL_FEATURE_FLAGS,
        }),
      )
    }),
  )
}
