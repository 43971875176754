import * as React from "react";
import { forwardRef, memo } from "react";
const SvgInfoError = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 30 30", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "#FFF", d: "M15 .333C23.103.333 29.667 6.897 29.667 15S23.103 29.667 15 29.667.333 23.103.333 15 6.897.333 15 .333m0 27c6.799 0 12.333-5.534 12.333-12.333S21.8 2.667 15 2.667 2.667 8.2 2.667 15 8.2 27.333 15 27.333m4.625-5.828L15 16.88l-4.625 4.625-1.88-1.88L13.12 15l-4.625-4.625 1.88-1.88L15 13.12l4.625-4.625 1.88 1.88L16.88 15l4.625 4.625z" }));
const ForwardRef = forwardRef(SvgInfoError);
const Memo = memo(ForwardRef);
export default Memo;
