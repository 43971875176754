// This is all in a single file to facilitate projects that don't have any sort
// of traditional JS bundler (*cough* CP).
// If you do want to copy it to a project like that, it needs to be run through
// babel with:
//
//     ./node_modules/.bin/babel ./src/lib/anti-adblock/index.js

const acknowledgeCookieName = 'didAcknowledgeAdblock'

/**
 * Checks if the user is blocking ads and has acknowledged that they are
 * blocking ads.
 */
function isUserBlockingAdsCookie(options) {
  return isUserBlockingAds().then((isUserBlockingAds) => {
    const didUserAcknowledge =
      options.jsCookie.get(acknowledgeCookieName) === 'true'

    return !didUserAcknowledge && isUserBlockingAds
  })
}

/**
 * Sets the cookie stating the user has acknowledged they are blocking ads.
 */
function acceptUserBlockingAds(options) {
  options.jsCookie.set(acknowledgeCookieName, 'true')
}

/**
 * @typedef {{
 *  className?: string
 *  title?: string
 *  buttonLabel?: string
 *  explanation?: string
 * }} InjectAntiAdblockOptions
 */

/**
 * Injects a banner if adblock detected.
 * @type {(options: InjectAntiAdblockOptions) => void}
 */
export function injectAntiAdblock(options) {
  isUserBlockingAdsCookie(options).then((isUserBlockingAds) => {
    if (
      !isUserBlockingAds ||
      new URL(window.location.toString()).searchParams.get(
        'suppress_overlays',
      ) === 'true'
    ) {
      return
    }

    const baseClassName = 'InjectAntiAdblock'
    const container = document.createElement('div')
    container.style.position = 'fixed'
    container.style.bottom = '0'
    container.style.left = '0'
    container.style.right = '0'
    container.style.zIndex = '99999'
    container.style.backgroundColor = '#212121'
    container.style.color = '#FAFAFA'
    container.style.padding = '32px'
    container.style.display = 'flex'
    container.style.fontFamily = 'sans-serif'
    container.style.lineHeight = '1.5'
    container.style.fontSize = '16px'

    container.className = `${baseClassName}-root ${options.className || ''}`

    if (options.className) {
      container.className = options.className
    }

    const leftSide = document.createElement('div')
    leftSide.className = `${baseClassName}-leftSide`
    // leftSide.style.flexGrow = '1'

    const title = document.createElement('div')
    title.className = `${baseClassName}-title`
    title.style.fontWeight = 'bold'
    title.style.color = '#FFC107'
    title.innerHTML = options.title || "It looks like you're blocking ads"

    const explanation = document.createElement('div')
    explanation.className = `${baseClassName}-explanation`
    explanation.innerHTML =
      options.explanation ||
      'Promoboxx will never show you ads while using our app, but because we are a marketing platform, we have a lot in common with the things that ad blockers hide. With ad blockers enabled, you will not be getting the best experience in our app.'

    leftSide.appendChild(title)
    leftSide.appendChild(explanation)
    container.appendChild(leftSide)

    const rightSide = document.createElement('div')
    rightSide.style.alignSelf = 'center'
    rightSide.style.width = '120px'
    rightSide.style.textAlign = 'right'
    rightSide.className = `${baseClassName}-rightSide`

    const button = document.createElement('button')
    button.className = `${baseClassName}-button`
    button.style.backgroundColor = 'transparent'
    button.style.color = '#FFC107'
    button.style.border = '2px solid #FFC107'
    button.style.padding = '8px'
    button.style.lineHeight = '1'
    button.style.fontFamily = 'sans-serif'
    button.style.cursor = 'pointer'
    button.onclick = () => {
      acceptUserBlockingAds(options)
      document.body.removeChild(container)
    }
    button.innerText = options.buttonLabel || 'Got it!'
    button.type = 'button'

    rightSide.appendChild(button)
    container.appendChild(rightSide)

    document.body.appendChild(container)
  })
}

/**
 * Main logic to determine if ads are being blocked.
 * @type {() => Promise<boolean>}
 */
function isUserBlockingAds() {
  /** @type {Partial<CSSStyleDeclaration>} */
  const style = {
    // Lots of adblocks just set `display: none`.
    display: 'block',
    visibility: 'visible',
    opacity: '1',
    height: '500px',
  }
  // This exists in easylist, and probably most any adblocker worth its salt.
  const id = 'Adbanner'
  // We lookup based on this classname.
  const randomClass = Math.random().toString(16).replace('.', '-')
  const className = `c${randomClass}`

  // Create the "ad".
  const container = document.createElement('div')
  container.id = id
  container.className = className
  Object.keys(style).forEach((cssPropName) => {
    container.style[cssPropName] = style[cssPropName]
  })

  document.body.appendChild(container)

  return new Promise((resolve) => {
    const startedAt = new Date()
    const interval = setInterval(() => {
      const found = document.querySelector(`.${className}`)

      // Some adblocks stop the element from ever being added.
      if (!found) {
        clearInterval(interval)
        resolve(true)

        return
      }

      // Need to use `getComputedStyle`, as `HTMLElement.style` is not influenced
      // by stylesheets.
      const computedStyle = getComputedStyle(found)
      let doesCssMatch = true

      Object.keys(style).forEach((cssPropName) => {
        if (!doesCssMatch) {
          return
        }

        if (computedStyle[cssPropName] !== style[cssPropName]) {
          doesCssMatch = false
        }
      })

      const isBlocked = !doesCssMatch

      if (isBlocked) {
        document.body.removeChild(found)
        clearInterval(interval)
        resolve(true)
      } else {
        const duration = new Date().valueOf() - startedAt.valueOf()
        if (duration / 1000 > 5) {
          document.body.removeChild(found)
          clearInterval(interval)
          resolve(false)
        }
      }
    }, 500)
  })
}
