import { invariant } from '@apollo/client/utilities/globals'
import { RetailerAccount, User } from '@promoboxx/graphql-gateway-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { currentRetailerAccountSelector } from '@src/stores/app/selectors'

function useCurrentAuth() {
  const user: User | undefined = useSelector((state) => state.auth?.user)
  // TODO Our Redux stuff handles fetching the retailer account information when
  // the user is internal and doesn't actually have access to the retailer
  // account in the slug.
  // This hook needs to account for that, I'm not sure how, but I know Suspense
  // would make a part of it easier.
  const potentiallyImpersonatedRetailerAccount: RetailerAccount | undefined =
    useSelector(currentRetailerAccountSelector)

  const sortedRetailerAccounts = useMemo(() => {
    const allRetailerAccounts = [...(user?.retailer_accounts || [])]

    const potentiallyImpersonatedRetailerAccountIndex =
      potentiallyImpersonatedRetailerAccount
        ? allRetailerAccounts.findIndex(
            (x) => x.id === potentiallyImpersonatedRetailerAccount?.id,
          )
        : -1

    if (
      potentiallyImpersonatedRetailerAccount &&
      potentiallyImpersonatedRetailerAccountIndex === -1
    ) {
      allRetailerAccounts.push(potentiallyImpersonatedRetailerAccount)
    }

    const sortedRetailerAccounts = allRetailerAccounts.map((x) => {
      const editable: RetailerAccount = { ...x }

      editable.retailers = [...(editable.retailers || [])]
      editable.retailers.sort((a, b) => {
        return (a.brand?.name || '').localeCompare(b.brand?.name || '')
      })

      return editable
    })

    sortedRetailerAccounts.sort((a, b) => {
      return (a.name || '').localeCompare(b.name || '')
    })

    return sortedRetailerAccounts
  }, [potentiallyImpersonatedRetailerAccount, user?.retailer_accounts])

  const userWithoutRetailerAccounts:
    | Omit<User, 'retailer_accounts'>
    | undefined = user

  return {
    currentUser: userWithoutRetailerAccounts,
    get ensureCurrentUser() {
      invariant(userWithoutRetailerAccounts, 'No user found')

      return userWithoutRetailerAccounts
    },

    retailerAccounts: sortedRetailerAccounts,
  }
}

export default useCurrentAuth
