import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'

import legacySvgMap from './legacySvgMap'

const WrappedSvg = styled('div')((props) => ({
  width: `${props.w}`,
  height: `${props.h}`,
}))

/**
 * @deprecated Import and use SVGs as you would any other image.
 */
const LegacySvg = (props) => {
  const Component = legacySvgMap[props.symbol]

  if (!Component) {
    console.error(`No svg found for ${props.symbol}`)

    return null
  }

  return (
    <WrappedSvg h={`${props.height}`} w={`${props.width}`}>
      <Component height="100%" width="100%" />
    </WrappedSvg>
  )
}

LegacySvg.propTypes = {
  height: PropTypes.string,
  symbol: PropTypes.string.isRequired,
  width: PropTypes.string,
}

LegacySvg.defaultProps = {
  symbol: 'logo-white',
  width: '200px',
  height: '200px',
}

export default LegacySvg
