import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'

import { lightTheme, darkTheme } from './theme'

/** @type {React.FC<ThemeProviderProps>} */
const ThemeProvider = (props) => {
  const theme = props.mode === 'light' ? lightTheme : darkTheme

  return <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
}

/**
 * @typedef {{
 *  mode: 'light' | 'dark'
 * }} ThemeProviderProps
 */

export default ThemeProvider
