import { localAdBalances } from '@promoboxx/redux-stores'
import { selectContentDiscovery } from '@promoboxx/redux-stores/src/stores/content-discovery/selectors'
import { createSelector } from 'reselect'

import {
  isAllBrandsSelector,
  brandsForCurrentRetailerAccountSelector,
  brandDataByIdSelector,
  retailerIdsByBrandIdSelector,
} from '../app/selectors'
import {
  normalizePaidAdsSelector,
  activatedPaidAdsSelector,
  activatedPaidAdsCountSelector,
  nonFeaturedAvailablePaidAdsSelector,
  nonFeaturedAvailablePaidAdsCountSelector,
  upcomingActivatedPaidAdsSelector,
  upcomingActivatedPaidAdsCountSelector,
} from '../paidAds/selectors'
import {
  retailerAccountCampaignsSearchResultsSelector,
  retailerAccountCampaignsSearchIsLoadingSelector,
  retailerAccountCampaignsSearchIsLoadingPageSelector,
  retailerAccountCampaignsSearchTotalSelector,
  retailerAccountCampaignsSearchIsRejectedSelector,
} from '../retailerAccounts/campaigns/search/selectors'
import {
  retailerCampaignsSearchResultsSelector,
  retailerCampaignsSearchIsLoadingSelector,
  retailerCampaignsSearchIsLoadingPageSelector,
  retailerCampaignsSearchIsRejectedSelector,
  retailerCampaignsSearchTotalSelector,
} from '../retailers/campaigns/search/selectors'
import { slugsByRetailerIdForCurrentRetailerAccountSelector } from '../retailers/selectors'

export const quickStatsByCampaignIdSelector = (state) =>
  (state.content && state.content.quickStatsByCampaignId) || {}

export const contentInitializedSelector = (state) =>
  (state.content && state.content.contentInitialized) || false
export const selectContentInitialized = createSelector(
  [contentInitializedSelector],
  (contentInitialized) => {
    return {
      contentInitialized,
    }
  },
)

export const campaignListSizeSelector = (state) =>
  state.content && state.content.campaignListSize
export const filteredCampaignsSelector = createSelector(
  [
    isAllBrandsSelector,
    retailerAccountCampaignsSearchResultsSelector,
    retailerCampaignsSearchResultsSelector,
  ],
  (isAllBrands, retailerAccountCampaigns, retailerCampaigns) => {
    return isAllBrands ? retailerAccountCampaigns || [] : retailerCampaigns
  },
)

const normalizeChannelType = (channelName) => {
  switch (channelName) {
    case 'asset':
      return 'downloadables'
    case 'embed':
      return 'banner'
    default:
      return channelName
  }
}

const normalizeSocials = (socials) => {
  const channelOptions = {}
  const channelActivated = {}
  socials.forEach((social) => {
    channelOptions[social.channel] = social.count
    channelActivated[social.channel] = social.share_data !== null
  })
  return {
    channelOptions,
    channelActivated,
  }
}

const normalizeInvitation = (invitation, localAdBalances) => {
  let adBalance = localAdBalances.find((balance) => {
    return parseInt(balance.invitation_id, 10) === parseInt(invitation.id, 10)
  })
  let type = adBalance && adBalance.local_ads_balance ? 'localAd' : 'campaign'
  let creditAmount = (adBalance && adBalance.local_ads_balance) || 0
  let isActivated = invitation.state === 'engaged'
  return {
    type,
    creditAmount,
    isActivated,
  }
}

const getBrandInfo = (brandId, brands) => {
  let brand = brands.find((brand) => {
    return brand.id === brandId
  })
  if (!brand) {
    return {}
  }
  return {
    brandLogo: brand.logo_url,
    brandName: brand.name,
    brandSlug: brand.slug,
  }
}

const normalizeChannels = (socials, campaignSlug, activateUrl) => {
  const channels = []

  socials.forEach((social) => {
    const channel = normalizeChannelType(social.channel)
    channels.push({
      slug: campaignSlug,
      type: channel,
      to: `${activateUrl}/${channel}`,
    })
  })
  return {
    channels,
  }
}

const buildActivateUrl = (slugs) => {
  return `/#/${slugs.retailerAccount}/${slugs.brand}/campaigns/${slugs.campaign}/activate`
}

export const normalizedCampaignsSelector = createSelector(
  [
    filteredCampaignsSelector,
    brandsForCurrentRetailerAccountSelector,
    localAdBalances.selectors.localAdBalancesSelector,
    retailerIdsByBrandIdSelector,
    slugsByRetailerIdForCurrentRetailerAccountSelector,
  ],
  (
    campaigns = [],
    brands,
    localAdBalances = [],
    retailerIdsByBrandId,
    slugsByRetailerId,
  ) => {
    return campaigns.map((campaign) => {
      const {
        creative_path,
        brand_id,
        socials,
        description,
        end_date,
        id,
        invitation,
        name,
        logo_url,
        start_date,
      } = campaign
      const { brandLogo, brandName, brandSlug } = getBrandInfo(brand_id, brands)
      const { isActivated, type, creditAmount } = normalizeInvitation(
        invitation,
        localAdBalances,
      )
      const { channelOptions, channelActivated } = normalizeSocials(
        socials,
        creative_path,
      )
      const slugs = {
        ...slugsByRetailerId[retailerIdsByBrandId[brand_id]],
        campaign: campaign.creative_path,
      }

      const activateUrl = buildActivateUrl(slugs)

      const { channels } = normalizeChannels(
        socials,
        creative_path,
        activateUrl,
      )

      let normalizedCampaign = {
        activateUrl,
        brandLogo,
        brandName,
        brandSlug,
        channels,
        channelOptions,
        channelActivated,
        creditAmount,
        description,
        endDate: end_date,
        id,
        isActivated,
        slug: creative_path,
        startDate: start_date,
        title: name,
        thumbnailSrc: logo_url,
        type,
      }

      return normalizedCampaign
    })
  },
)

export const activatedCampaignsSelector = createSelector(
  [normalizedCampaignsSelector],
  (campaigns) => {
    return campaigns.filter((campaign) => campaign.isActivated)
  },
)

export const availableCampaignsSelector = createSelector(
  [normalizedCampaignsSelector],
  (campaigns) => {
    return campaigns.filter((campaign) => !campaign.isActivated)
  },
)

export const activatedCampaignsCountSelector = createSelector(
  [activatedCampaignsSelector],
  (campaigns) => {
    return campaigns.length
  },
)

export const availableCampaignsCountSelector = createSelector(
  [availableCampaignsSelector],
  (campaigns) => {
    return campaigns.length
  },
)

export const contentCampaignsIsLoadingSelector = createSelector(
  [
    isAllBrandsSelector,
    retailerCampaignsSearchIsLoadingSelector,
    retailerAccountCampaignsSearchIsLoadingSelector,
  ],
  (
    isAllBrand,
    retailerCampaignsSearchIsLoading,
    retailerAccountCampaignsSearchIsLoading,
  ) => {
    if (isAllBrand) {
      return retailerAccountCampaignsSearchIsLoading
    }
    return retailerCampaignsSearchIsLoading
  },
)

export const contentCampaignsIsLoadingPageSelector = createSelector(
  [
    isAllBrandsSelector,
    retailerCampaignsSearchIsLoadingPageSelector,
    retailerAccountCampaignsSearchIsLoadingPageSelector,
  ],
  (
    isAllBrands,
    retailerCampaignsSearchIsLoadingPage,
    retailerAccountCampaignsSearchIsLoadingPage,
  ) => {
    if (isAllBrands) {
      return retailerAccountCampaignsSearchIsLoadingPage
    }
    return retailerCampaignsSearchIsLoadingPage
  },
)

export const contentCampaignsIsRejectedSelector = createSelector(
  [
    isAllBrandsSelector,
    retailerCampaignsSearchIsRejectedSelector,
    retailerAccountCampaignsSearchIsRejectedSelector,
  ],
  (
    isAllBrands,
    retailerCampaignsSearchIsRejected,
    retailerAccountCampaignsSearchIsRejected,
  ) => {
    if (isAllBrands) {
      return retailerAccountCampaignsSearchIsRejected
    }
    return retailerCampaignsSearchIsRejected
  },
)

export const contentCampaignsTotalSelector = createSelector(
  [
    isAllBrandsSelector,
    retailerCampaignsSearchTotalSelector,
    retailerAccountCampaignsSearchTotalSelector,
  ],
  (
    isAllBrands,
    retailerCampaignsSearchTotal,
    retailerAccountCampaignsSearchTotal,
  ) => {
    if (isAllBrands) {
      return retailerAccountCampaignsSearchTotal
    }
    return retailerCampaignsSearchTotal
  },
)

export const contentCampaignsRetrievedTotalSelector = createSelector(
  [filteredCampaignsSelector],
  (campaigns) => {
    return campaigns.length
  },
)

export const campaignsPageShouldBeRequestedSelector = createSelector(
  [
    campaignListSizeSelector,
    contentCampaignsTotalSelector,
    contentCampaignsRetrievedTotalSelector,
  ],
  (currentAmountShown, totalNumberOfCampaigns, currentNumberOfCampaigns) => {
    // Doesn't take into account the paid ads, but it will error on the side of requesting even if they wont be shown immediately
    return (
      totalNumberOfCampaigns > currentNumberOfCampaigns &&
      currentAmountShown > currentNumberOfCampaigns
    )
  },
)

export const allCampaignsSelector = createSelector(
  [normalizedCampaignsSelector, normalizePaidAdsSelector],
  (campaigns = [], paidAds = []) => {
    return paidAds.concat(campaigns)
  },
)

/**
 * Returns combined list of paid ads campaigns and regular campaigns
 * Based on the current brand/retailer account
 * @returns {array}
 */
export const contentAvailableCampaignsSelector = createSelector(
  [availableCampaignsSelector, nonFeaturedAvailablePaidAdsSelector],
  (campaigns = [], paidAds = []) => {
    return paidAds.concat(campaigns)
  },
)

/**
 * Returns combined list of paid ads campaigns and regular campaigns
 * Based on the current brand/retailer account
 * @returns {array}
 */
export const contentActivatedCampaignsSelector = createSelector(
  [activatedCampaignsSelector, activatedPaidAdsSelector],
  (campaigns = [], paidAds = []) => {
    return paidAds.concat(campaigns)
  },
)

export const contentActivatedCampaignsCountSelector = createSelector(
  [activatedCampaignsCountSelector, activatedPaidAdsCountSelector],
  (campaignsCount, paidAdsCount) => {
    return campaignsCount + paidAdsCount
  },
)

export const contentAvailableCampaignsCountSelector = createSelector(
  [
    contentCampaignsTotalSelector,
    activatedCampaignsCountSelector,
    nonFeaturedAvailablePaidAdsCountSelector,
  ],
  (
    campaignsTotal = 0,
    activatedCampaignsTotal = 0,
    availablePaidAdsCount = 0,
  ) => {
    return campaignsTotal - activatedCampaignsTotal + availablePaidAdsCount
  },
)

export const selectCampaignStatsByCampaignId = createSelector(
  [quickStatsByCampaignIdSelector],
  (statsByCampaignId) => {
    return {
      statsByCampaignId,
    }
  },
)

/**
 * Component Selectors
 */

export const selectContentCampaignsIsLoading = createSelector(
  [contentCampaignsIsLoadingSelector],
  (campaignsIsLoading) => {
    return {
      campaignsIsLoading,
    }
  },
)

export const selectContentCampaignsIsLoadingPage = createSelector(
  [contentCampaignsIsLoadingPageSelector],
  (campaignsIsLoadingPage) => {
    return {
      campaignsIsLoadingPage,
    }
  },
)

export const selectContentCampaignsIsRejected = createSelector(
  [contentCampaignsIsRejectedSelector],
  (campaignsIsRejected) => {
    return {
      campaignsIsRejected,
    }
  },
)

export const selectContentCampaignsTotal = createSelector(
  [contentCampaignsTotalSelector],
  (campaignsTotal) => {
    return {
      campaignsTotal,
    }
  },
)

/**
 * Component selector to get all campaigns
 * @returns {{campaigns: array}}
 */
export const selectContentCampaigns = createSelector(
  [allCampaignsSelector],
  (campaigns) => ({
    campaigns,
  }),
)

/**
 * Component selector to get available campaigns for the content page
 * @returns {{availableCampaigns: array}}
 */
export const selectAvailableContentCampaigns = createSelector(
  [contentAvailableCampaignsSelector],
  (availableCampaigns) => {
    return {
      availableCampaigns,
    }
  },
)

export const selectAvailableContentCampaignsCount = createSelector(
  [contentAvailableCampaignsCountSelector],
  (availableCampaignsCount) => {
    return {
      availableCampaignsCount,
    }
  },
)

/**
 * Component selector to get available campaigns for the content page
 * @returns {{activatedCampaigns: array}}
 */
export const selectActivatedContentCampaigns = createSelector(
  [contentActivatedCampaignsSelector],
  (activatedCampaigns) => {
    return {
      activatedCampaigns,
    }
  },
)

export const selectActivatedContentCampaignsCount = createSelector(
  [contentActivatedCampaignsCountSelector],
  (activatedCampaignsCount) => {
    return {
      activatedCampaignsCount,
    }
  },
)

export const selectUpcomingActivatedContentCampaigns = createSelector(
  [upcomingActivatedPaidAdsSelector],
  (upcomingCampaigns) => {
    return {
      upcomingCampaigns,
    }
  },
)

export const selectUpcomingActivatedContentCampaignsCount = createSelector(
  [upcomingActivatedPaidAdsCountSelector],
  (upcomingCampaignsCount) => {
    return {
      upcomingCampaignsCount,
    }
  },
)

export const selectCampaignListSize = createSelector(
  [campaignListSizeSelector],
  (campaignListSize) => ({ campaignListSize }),
)

export const selectShowNoCampaignsExist = createSelector(
  [
    upcomingActivatedPaidAdsCountSelector,
    contentActivatedCampaignsCountSelector,
    contentAvailableCampaignsCountSelector,
  ],
  (upcomingCount, activatedCount, availableCount) => {
    const showNoCampaignsExist =
      upcomingCount === 0 && activatedCount === 0 && availableCount === 0
    return {
      showNoCampaignsExist,
    }
  },
)

export const normalizeContentDiscoverySelector = createSelector(
  [selectContentDiscovery, brandDataByIdSelector, retailerIdsByBrandIdSelector],
  ({ contentDiscovery }, brandDataById, retailerIdsByBrandId) => {
    return (
      contentDiscovery &&
      Array.isArray(contentDiscovery) &&
      contentDiscovery.map((contentDiscoveryItem) => {
        const brand = brandDataById[contentDiscoveryItem.brand_id]
        return {
          ...brand,
          retailerId: retailerIdsByBrandId[contentDiscoveryItem.brand_id],
          channels: [
            {
              type: contentDiscoveryItem.channel_type,
            },
          ],
          description:
            contentDiscoveryItem.content_type === 'link'
              ? contentDiscoveryItem.description
              : contentDiscoveryItem.message,
          id: contentDiscoveryItem.id,
          startDate: contentDiscoveryItem.content_created_at,
          thumbnailSrc: contentDiscoveryItem.image_url,
          title: contentDiscoveryItem.title,
          type: 'contentDiscovery',
        }
      })
    )
  },
)

export const selectContentDiscoveryCampaigns = createSelector(
  [normalizeContentDiscoverySelector],
  (contentDiscovery) => {
    return {
      results: contentDiscovery,
    }
  },
)

export const selectPaidAdCampaigns = createSelector(
  [normalizePaidAdsSelector],
  (results) => {
    results.sort((a, b) => {
      a = new Date(a.startDate).valueOf()
      b = new Date(b.startDate).valueOf()
      return b - a
    })
    results = results.filter((result) => {
      return result.isActivated || result.isActivatable
    })
    return {
      results,
    }
  },
)

//Helper selector to bundler retailer specific meta info from search campaigns
export const selectOrganicCampaignsRetailerMetaInfo = createSelector(
  [
    retailerCampaignsSearchIsLoadingSelector,
    retailerCampaignsSearchIsLoadingPageSelector,
    retailerCampaignsSearchTotalSelector,
    retailerCampaignsSearchIsRejectedSelector,
  ],
  (isLoading, isLoadingPage, total, isRejected) => {
    return {
      isLoading,
      isLoadingPage,
      total,
      isRejected,
    }
  },
)

//Helper selector to bundler retailer account specific meta info from search campaigns
export const selectOrganicCampaignsRetailerAccountMetaInfo = createSelector(
  [
    retailerAccountCampaignsSearchIsLoadingSelector,
    retailerAccountCampaignsSearchIsLoadingPageSelector,
    retailerAccountCampaignsSearchTotalSelector,
    retailerAccountCampaignsSearchIsRejectedSelector,
  ],
  (isLoading, isLoadingPage, total, isRejected) => {
    return {
      isLoading,
      isLoadingPage,
      total,
      isRejected,
    }
  },
)

export const selectOrganicCampaigns = createSelector(
  [
    normalizedCampaignsSelector,
    isAllBrandsSelector,
    selectOrganicCampaignsRetailerMetaInfo,
    selectOrganicCampaignsRetailerAccountMetaInfo,
  ],
  (results, isAllBrands, retailerMetaInfo, retailerAccountMetaInfo) => {
    // RetailerAccount vs Retailer specific search meta info
    if (isAllBrands) {
      return {
        results,
        ...retailerAccountMetaInfo,
      }
    } else {
      return {
        results,
        ...retailerMetaInfo,
      }
    }
  },
)
