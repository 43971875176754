import {
  ad,
  auth,
  campaigns,
  contentDiscovery as contentDiscoveryReduxStores,
  darklyFeatureFlags,
  facebookAds,
  facebookPages,
  feature_flags,
  localAdBalances,
  locations,
  retailers,
  retailersCampaignsSearch,
  retailersContentDiscovery,
  retailersFacebookPages,
  retailerAccounts,
  retailerAccountsCampaignsSearch,
  stats,
} from '@promoboxx/redux-stores'
import { combineEpics } from 'redux-observable'

import * as app from './app/epics'
import * as automations from './automations/epics'
import * as content from './content/epics'
import * as forgotPassword from './forgotPassword/epics'
import * as login from './login/epics'
import * as paidAds from './paidAds/epics'
import * as requestAccess from './requestAccess/epics'
import * as retailerAccountsFilters from './retailerAccounts/filters/epics'
import * as contentDiscovery from './retailers/contentDiscovery/epics'

const modules = [
  ad.epics,
  app,
  campaigns.epics,
  content,
  contentDiscovery,
  contentDiscoveryReduxStores.epics,
  facebookAds.epics,
  facebookPages.epics,
  forgotPassword,
  login,
  paidAds,
  requestAccess,
  auth.epics,
  darklyFeatureFlags.epics,
  feature_flags.epics,
  localAdBalances.epics,
  locations.retailers.epics,
  retailers.epics,
  retailersCampaignsSearch.epics,
  retailersContentDiscovery.epics,
  retailersFacebookPages.epics,
  retailerAccounts.epics,
  retailerAccountsFilters,
  retailerAccountsCampaignsSearch.epics,
  stats.epics,
  automations,
]

//Condense to array of values...
let epics = []
modules.forEach((module) => {
  Object.keys(module).forEach((key) => {
    epics.push(module[key])
  })
})

//Would be nice if they could just take the keyed object by default.
export default combineEpics(...epics)
