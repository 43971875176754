export const GET_AUTOMATIONS = 'GET_AUTOMATIONS'
export const GET_AUTOMATIONS_FULFILLED = 'GET_AUTOMATIONS_FULFILLED'
export const GET_AUTOMATIONS_REJECTED = 'GET_AUTOMATIONS_REJECTED'

export const getAutomations = (payload) => ({
  type: GET_AUTOMATIONS,
  payload,
})

export const CREATE_AUTOMATIONS = 'CREATE_AUTOMATIONS'
export const CREATE_AUTOMATIONS_FULFILLED = 'CREATE_AUTOMATIONS_FULFILLED'
export const CREATE_AUTOMATIONS_REJECTED = 'CREATE_AUTOMATIONS_REJECTED'

export const createAutomation = (payload) => ({
  type: CREATE_AUTOMATIONS,
  payload,
})

export const UPDATE_AUTOMATIONS = 'UPDATE_AUTOMATIONS'
export const UPDATE_AUTOMATIONS_FULFILLED = 'UPDATE_AUTOMATIONS_FULFILLED'
export const UPDATE_AUTOMATIONS_REJECTED = 'UPDATE_AUTOMATIONS_REJECTED'

export const updateAutomation = (payload) => ({
  type: UPDATE_AUTOMATIONS,
  payload,
})

export const CLEAR_AUTOMATIONS = 'CLEAR_AUTOMATIONS'
export const clearAutomations = () => ({ type: CLEAR_AUTOMATIONS })
