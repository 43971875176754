import * as React from "react";
import { forwardRef, memo } from "react";
const SvgFbMessenger = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 224 226", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("linearGradient", { id: "a", x1: "50%", x2: "50%", y1: "6.76%", y2: "95.6%" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, stopColor: "#00C6FF" }), /* @__PURE__ */ React.createElement("stop", { offset: 1, stopColor: "#0068FF" }))), /* @__PURE__ */ React.createElement("path", { fill: "url(#a)", d: "M41.255 185.52v40.2l37.589-21.37C89.322 207.37 100.46 209 112 209c61.86 0 112-46.79 112-104.5C224 46.786 173.86 0 112 0 50.144 0 0 46.786 0 104.5c0 32.68 16.078 61.86 41.255 81.02" }), /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M100.04 75.878 39.639 139.83l54.97-30.16 28.721 30.16 60.06-63.952-54.36 29.632z" }));
const ForwardRef = forwardRef(SvgFbMessenger);
const Memo = memo(ForwardRef);
export default Memo;
