export const FILTER_PARAMS_CHANGED = 'FILTER_PARAMS_CHANGED'
export const filterParamsChanged = (payload) => ({
  type: FILTER_PARAMS_CHANGED,
  payload,
})

export const SET_TAGS_IN_FILTERS = 'SET_TAGS_IN_FILTERS'
export const FILTER_TAGS_ALREADY_SET = 'FILTER_TAGS_ALREADY_SET'
export const setTagsInFilters = (payload) => ({
  type: SET_TAGS_IN_FILTERS,
  payload,
})
