import * as React from "react";
import { forwardRef, memo } from "react";
const SvgPremiumBadge = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 40 40", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("g", { fill: "none", fillRule: "evenodd" }, /* @__PURE__ */ React.createElement("path", { fill: "#FFC208", d: "M39.571 0v39.571H0z" }), /* @__PURE__ */ React.createElement("path", { fill: "#000", d: "M35.37 27.126c-.222 0-.433-.046-.634-.112l-1.647 5.26H23.038L21.39 27.01a2 2 0 0 1-.582.094c-1.154 0-2.089-.947-2.089-2.114a2.1 2.1 0 0 1 2.089-2.112c1.153 0 2.088.945 2.088 2.112 0 .772-.414 1.44-1.025 1.81.794.814 2.033 1.701 3.384 1.197 1.113-.416 2.166-2.738 2.523-4.024-1.02-.143-1.807-1.033-1.807-2.12 0-1.186.936-2.149 2.091-2.149s2.092.963 2.092 2.15c0 1.086-.786 1.974-1.806 2.118.36 1.287 1.411 3.61 2.524 4.025 1.356.506 2.6-.389 3.392-1.206a2.13 2.13 0 0 1-.96-1.788c0-1.173.925-2.124 2.067-2.124s2.067.951 2.067 2.124-.926 2.123-2.067 2.123M23.405 33.37h9.362c.861 0 1.56.506 1.56 1.372 0 .865-.699.717-1.56.717h-9.362c-.861 0-1.56.148-1.56-.717 0-.866.699-1.372 1.56-1.372" })));
const ForwardRef = forwardRef(SvgPremiumBadge);
const Memo = memo(ForwardRef);
export default Memo;
