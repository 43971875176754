import * as React from "react";
import { forwardRef, memo } from "react";
const SvgCalendar = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 23 24", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "#DA2427", fillRule: "evenodd", d: "M11.5 0C17.851 0 23 5.149 23 11.5v1C23 18.851 17.851 24 11.5 24S0 18.851 0 12.5v-1C0 5.149 5.149 0 11.5 0M5.75 8.98v8.387c0 .626.468 1.133 1.045 1.133h9.41c.577 0 1.045-.507 1.045-1.133V8.98zm2.875 8.68h-2.09v-2.24h2.09zm0-2.8h-2.09v-2.24h2.09zm0-2.8h-2.09V9.82h2.09zm2.614 5.6H9.148v-2.24h2.09zm0-2.8H9.148v-2.24h2.09zm0-2.8H9.148V9.82h2.09zm2.613 5.6h-2.09v-2.24h2.09zm0-2.8h-2.09v-2.24h2.09zm0-2.8h-2.09V9.82h2.09zm2.614 5.6h-2.091v-2.24h2.09zm0-2.8h-2.091v-2.24h2.09zm0-2.8h-2.091V9.82h2.09zm-.261-5.88h-1.7V4.92c0-.232-.175-.42-.391-.42s-.392.188-.392.42v1.26H9.278V4.92c0-.232-.175-.42-.392-.42-.216 0-.392.188-.392.42v1.26H6.795c-.577 0-1.045.507-1.045 1.133V8.42h11.5V7.313c0-.626-.468-1.133-1.045-1.133M8.886 8.14c-.433 0-.784-.376-.784-.84 0-.31.158-.581.392-.727V7.3c0 .232.176.42.392.42s.392-.188.392-.42v-.727a.85.85 0 0 1 .392.727c0 .464-.35.84-.784.84m5.228 0c-.433 0-.784-.376-.784-.84 0-.31.158-.581.392-.727V7.3c0 .232.175.42.392.42.216 0 .392-.188.392-.42v-.727a.85.85 0 0 1 .392.727c0 .464-.351.84-.784.84" }));
const ForwardRef = forwardRef(SvgCalendar);
const Memo = memo(ForwardRef);
export default Memo;
