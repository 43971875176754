import * as React from "react";
import { forwardRef, memo } from "react";
const SvgGuitar = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 169.06 412.38", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "a" }, /* @__PURE__ */ React.createElement("path", { d: "M132.62 209.06h14.24V384.8h-14.24z", style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("clipPath", { id: "b" }, /* @__PURE__ */ React.createElement("path", { d: "M132.62 209.06h14.24V384.8h-14.24z", style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("clipPath", { id: "c" }, /* @__PURE__ */ React.createElement("path", { d: "M86.48 144.11h60.38v64.95H86.48z", style: {
  fill: "none"
} }))), /* @__PURE__ */ React.createElement("g", { style: {
  isolation: "isolate"
} }, /* @__PURE__ */ React.createElement("path", { d: "M149.84 409.5c0 1.59-33.54 2.88-74.92 2.88S0 411.09 0 409.5s33.54-2.89 74.92-2.89 74.92 1.3 74.92 2.89", style: {
  fill: "#d1d3d4"
} }), /* @__PURE__ */ React.createElement("path", { d: "m152.75 146.39 6.15 71.52c.1 1.19.15 2.39.15 3.58A41.76 41.76 0 0 1 146.82 251l-4 4v-33l4.18-13.06-6.32-62.55Z", style: {
  fill: "#bea883"
} }), /* @__PURE__ */ React.createElement("path", { d: "M111.46 99.15h22.94a15.48 15.48 0 0 1 15.4 13.92l3.35 33.1h-41.69Z", style: {
  fill: "#212121"
} }), /* @__PURE__ */ React.createElement("path", { d: "M137.47 383.77h-20.93c0 9.39-16.59 11.61-26.86 12.8-4.5.52-7.91 5.47-7.91 10l55.7-2.41Z", style: {
  fill: "#231f20"
} }), /* @__PURE__ */ React.createElement("path", { d: "M106.67 383.77H85.74c0 9.39-16.6 11.61-26.86 12.8-4.51.52-7.92 5.47-7.92 10l55.71-2.41Z", style: {
  fill: "#231f20"
} }), /* @__PURE__ */ React.createElement("path", { d: "M50.07 403.14h57.02v6.26H50.07z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M50.07 405.22h57.02v.7H50.07z", style: {
  fill: "#231f20"
} }), /* @__PURE__ */ React.createElement("path", { d: "M80.29 55.46a51.06 51.06 0 0 0-5.19 22.63c0 24.25 26 54.56 45.89 54.56 2.49 0-17.64-31.36-15.3-31.94 3.81-.95 20.13 17.77 23.4 15.48 5.41-3.78-6.91-16.92-3.73-23.47s26.23 21.46 26.23 13.31-6.29-44-9.57-50.57Z", style: {
  fill: "#5e4138"
} }), /* @__PURE__ */ React.createElement("path", { d: "M130.68 19a19 19 0 1 1-19-19 19 19 0 0 1 19 19", style: {
  fill: "#f37021"
} }), /* @__PURE__ */ React.createElement("path", { d: "M146.29 45.46a35 35 0 1 1-70.07 0c0-19.35 15.69-39.2 35-39.2s35 19.85 35 39.2", style: {
  fill: "#f05323"
} }), /* @__PURE__ */ React.createElement("path", { d: "m85.86 384.8-9.52-175.74h70.52l-9.52 175.74h-20.6l.11-142.08a5.25 5.25 0 0 0-10.5 0l.11 142.09Z", style: {
  fill: "#212121"
} }), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.11999499999999999,
  mixBlendMode: "multiply"
} }, /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#a)"
} }, /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#b)"
} }, /* @__PURE__ */ React.createElement("path", { d: "M138.99 209.06h7.87l-9.52 175.74h-4.72", style: {
  fill: "#231f20"
} })))), /* @__PURE__ */ React.createElement("path", { d: "M112.21 99.15H89.27a15.49 15.49 0 0 0-15.41 13.92l-3.34 33.1h41.69Z", style: {
  fill: "#212121"
} }), /* @__PURE__ */ React.createElement("path", { d: "M97.94 209.06h48.92l-9.28-91.83a20.09 20.09 0 0 0-20-18.08h-12a20.1 20.1 0 0 0-20 18.08l-9.28 91.83h21.6", style: {
  fill: "#434343"
} }), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.11999499999999999,
  mixBlendMode: "multiply"
} }, /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#c)"
} }, /* @__PURE__ */ React.createElement("path", { d: "M140.3 144.11c-1.45 20.65-6.65 40.38-25 53.57-8.59 6.18-18.5 9.71-28.85 11.38h60.38Z", style: {
  fill: "#231f20"
} }))), /* @__PURE__ */ React.createElement("path", { d: "M92.74 139.65h-1.11v-6.46h1.55c1.25 0 1.88.53 1.88 1.91 0 1-.4 1.46-.78 1.63l.94 2.92H94.1l-.79-2.65h-.57Zm0-3.56h.39c.6 0 .82-.22.82-1s-.22-1-.82-1h-.39ZM96.64 134.76a1.7 1.7 0 0 1 3.4 0v3.33a1.7 1.7 0 0 1-3.4 0Zm1.11 3.3c0 .48.14.71.59.71s.59-.23.59-.71v-3.27c0-.49-.14-.72-.59-.72s-.59.23-.59.72ZM103.89 135.35v-.5c0-.55-.25-.78-.54-.78-.45 0-.59.23-.59.71v3.28c0 .48.14.71.59.71s.54-.23.54-.65v-.78H105v.75a1.68 1.68 0 0 1-3.35 0v-3.33a1.56 1.56 0 0 1 1.7-1.66 1.61 1.61 0 0 1 1.65 1.77v.48ZM106.61 139.65v-6.46h1.11v2.39h.02l1.16-2.39h1.11l-1.2 2.41 1.35 4.05h-1.13l-.9-3h-.02l-.39.71v2.29zM113.44 133.19h1.09l-1.35 6.46h-1.11l-1.35-6.46h1.09l.8 4.48h.02zM115.84 133.19h1.11v6.46h-1.11zM118.66 139.65v-6.46h1.11v5.5h1.74v.96zM122.67 139.65v-6.46h1.11v5.5h1.75v.96zM126.75 139.65v-6.46h2.97v.97h-1.86v1.66h1.43v.97h-1.43v1.9h1.98v.96zM97.5 147.12h1.82v3.27h-.54l-.21-.48a1.29 1.29 0 0 1-1.11.57 1.48 1.48 0 0 1-1.46-1.65v-3.33a1.54 1.54 0 0 1 1.63-1.66 1.61 1.61 0 0 1 1.66 1.77v.39h-1.08c0-1-.2-1.22-.64-1.22-.27 0-.49.16-.49.71v3.31c0 .42.13.69.52.69s.61-.26.61-.9V148h-.71ZM101 143.93h1.11v4.88c0 .47.19.7.59.7s.59-.23.59-.7v-4.88h1.11v4.83a1.7 1.7 0 1 1-3.4 0ZM106.15 143.93h1.11v6.46h-1.11zM109.67 144.9h-1.08v-.97h3.26v.97h-1.08v5.49h-1.1zM113.52 150.39h-1.07l1.19-6.46H115l1.19 6.46h-1.07l-.2-1.43h-1.22Zm.36-2.4h.9l-.44-2.77ZM118.5 150.39h-1.11v-6.46H119c1.24 0 1.87.53 1.87 1.91 0 1-.4 1.46-.77 1.63l.94 2.92h-1.13l-.79-2.67h-.57Zm0-3.56h.39c.6 0 .83-.22.83-1s-.23-1-.83-1h-.39ZM124.42 145.53c0-.56-.34-.72-.59-.72s-.55.23-.55.64c0 1.1 2.25 1.62 2.25 3.38a1.58 1.58 0 0 1-1.75 1.65 1.69 1.69 0 0 1-1.66-1.79l1-.15c0 .65.31 1 .66 1a.57.57 0 0 0 .65-.59c0-1.27-2.26-1.61-2.26-3.47a1.52 1.52 0 0 1 1.72-1.61 1.56 1.56 0 0 1 1.54 1.57Z", style: {
  fill: "#f9a01b"
} }), /* @__PURE__ */ React.createElement("path", { d: "M122.29 106.41a11.1 11.1 0 1 1-11.1-11.1 11.1 11.1 0 0 1 11.1 11.1", style: {
  fill: "#212121"
} }), /* @__PURE__ */ React.createElement("path", { d: "M111.19 113.94a6.89 6.89 0 0 1-6.89-6.9V73.79h13.79V107a6.9 6.9 0 0 1-6.9 6.9", style: {
  fill: "#bea883"
} }), /* @__PURE__ */ React.createElement("path", { d: "M111.39 88.9a35.85 35.85 0 1 1 35.85-35.85 35.84 35.84 0 0 1-35.85 35.85", style: {
  fill: "#5e4138"
} }), /* @__PURE__ */ React.createElement("path", { d: "M111.39 94.46a35.85 35.85 0 1 1 35.85-35.85 35.84 35.84 0 0 1-35.85 35.85", style: {
  fill: "#bea883"
} }), /* @__PURE__ */ React.createElement("path", { d: "M75.55 52.93a5.69 5.69 0 1 1-5.69 5.68 5.68 5.68 0 0 1 5.69-5.68", style: {
  fill: "#bea883"
} }), /* @__PURE__ */ React.createElement("path", { d: "M93.72 56.55a2.06 2.06 0 1 1-2.06 2.06 2.06 2.06 0 0 1 2.06-2.06M129.07 56.55a2.06 2.06 0 1 1-2.07 2.06 2.06 2.06 0 0 1 2.06-2.06", style: {
  fill: "#062640"
} }), /* @__PURE__ */ React.createElement("path", { d: "M147.24 52.93a5.69 5.69 0 1 1-5.69 5.68 5.68 5.68 0 0 1 5.69-5.68", style: {
  fill: "#bea883"
} }), /* @__PURE__ */ React.createElement("path", { d: "M100.09 73.82a16 16 0 0 0 22.6 0", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "m138.99 209.06-6.37 175.74", style: {
  fill: "#231f20"
} }), /* @__PURE__ */ React.createElement("path", { d: "M99 21.39a16 16 0 0 0-2.32 8.28c0 8.87 11.58 19.95 20.45 19.95 1.11 0-7.86-11.47-6.82-11.68 1.7-.35 9 6.5 10.42 5.66 2.42-1.38-3.07-6.19-1.66-8.58s11.69 7.85 11.69 4.87-2.81-16.08-4.26-18.5Z", style: {
  fill: "#5e4138"
} }), /* @__PURE__ */ React.createElement("path", { d: "M80.66 403.14h57.02v6.26H80.66z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "m72.87 225.75 9.83-79.58H70.52l-8.63 74.09", style: {
  fill: "#bea883"
} }), /* @__PURE__ */ React.createElement("path", { d: "M132.81 394.1a3.48 3.48 0 1 1-3.47-3.48 3.47 3.47 0 0 1 3.47 3.48", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M80.66 406.62h57.02v.7H80.66z", style: {
  fill: "#231f20"
} }), /* @__PURE__ */ React.createElement("path", { d: "M111.67 161.75a7.88 7.88 0 0 0-8 .6 19.3 19.3 0 0 0-2.73 2.11q-15.69 14.81-31.35 29.66c-4.92 4.68-9.82 9.39-14.63 14.2-1.25 1.25-2.77 3-1.51 4.79s3.23 1 4.9.49c3.86-1.09 7.64-2.46 11.53-3.4 6.07-1.46 12.18-2.73 18.3-3.93a6.16 6.16 0 0 1 6.46 2.86 56 56 0 0 1 4.12 6.21c3.62 6.94 7.06 14 10.62 20.95a14 14 0 0 0 2 3c1.32 1.45 2.76 1.22 3.3-.63a21.2 21.2 0 0 0 .83-5.54q.48-27.21.84-54.44c0-3.27.29-6.55.11-9.81a7.67 7.67 0 0 0-4.81-7.11", style: {
  fill: "#ffc50f"
} }), /* @__PURE__ */ React.createElement("path", { d: "m140.53 79.35 2-1-1.35-2.72 2.84-.91.34 2.51a4.8 4.8 0 0 0 1.55-.56c.19-.14.1-.87-.06-1.25a10.7 10.7 0 0 0-1-1.49l2.78-1.15c.07.61.15 1.22.23 1.84v1l2.21-1.05-1.38-2.57 2.64-1.1.72 2.55c0 .24 0 .47.07.7.42-.34.86-.67 1.26-1 .25-.22.61-.5.61-.75s-.45-.45-.65-.71c-.35-.44-.65-.91-1-1.37L155.1 69a16 16 0 0 1 .47 1.63 6 6 0 0 1-.12 1.33l2.3-1c.33-1.5-2.82-1-1.33-2.73.54-.62 1.73-.68 2.62-1 .17.78.33 1.55.55 2.58 3.53-2 6 1.06 9.47 2.17-.72.78-1.14 1.27-1.59 1.72-5.94 5.9-11.91 11.79-17.81 17.72a9.5 9.5 0 0 0-1.94 2.81q-13.22 30.3-26.33 60.66a7.75 7.75 0 0 0-.33 3.13c.12 4.24.47 8.47.44 12.71q-.3 35.69-.75 71.37a20.3 20.3 0 0 1-.5 4.33c-1.23 5.37-6.37 7.23-10.51 3.54a28.8 28.8 0 0 1-5-6.81c-3.18-5.3-5.9-10.9-9.28-16.07-5.5-8.39-7.54-9.47-17.25-7.37-11.74 2.54-23.3 5.87-34.94 8.84a43 43 0 0 1-6.27 1.29 8.1 8.1 0 0 1-4-.71 2.83 2.83 0 0 1-1.55-4 19.7 19.7 0 0 1 3.55-5.37c5.9-5.77 12-11.38 18-17Q74.38 183 95.82 163.23c4-3.64 8.28-6.91 12.41-10.38a4.9 4.9 0 0 0 1.31-1.66q13.89-30.81 27.76-61.62c1-2.11.27-4 0-6-.15-1.29-.58-2.56 1.14-3.18a2 2 0 0 0-.13-1.16 10.3 10.3 0 0 0-1.06-1.42c.91-.42 1.85-1.22 2.72-1.15 1.05.09.75 1.12.34 1.87-.06.12.06.34.18.87m-28.86 82.4a7.88 7.88 0 0 0-8 .6 19.3 19.3 0 0 0-2.73 2.11q-15.69 14.81-31.35 29.66c-4.92 4.68-9.82 9.39-14.63 14.2-1.25 1.25-2.77 3-1.51 4.79s3.23 1 4.9.49c3.86-1.09 7.64-2.46 11.53-3.4 6.07-1.46 12.18-2.73 18.3-3.93a6.16 6.16 0 0 1 6.46 2.86 56 56 0 0 1 4.12 6.21c3.62 6.94 7.06 14 10.62 20.95a14 14 0 0 0 2 3c1.32 1.45 2.76 1.22 3.3-.63a21.2 21.2 0 0 0 .83-5.54q.48-27.21.84-54.44c0-3.27.29-6.55.11-9.81a7.67 7.67 0 0 0-4.81-7.11", style: {
  fill: "#f05323"
} }), /* @__PURE__ */ React.createElement("path", { d: "M104.26 178.32c1.83.81 3.65 1.65 5.5 2.41 1 .42 1.37.91.91 2.06-1.33 3.33-1.27 3.34-4.57 1.87-3-1.34-5.95-2.74-9-3.94-2-.8-1.26-1.94-.71-3.18s.92-2.45 2.72-1.53 3.43 1.54 5.14 2.3M98.89 190.8c-1.77-.78-3.53-1.61-5.33-2.33-1.23-.49-1.6-1.18-.94-2.37a6 6 0 0 0 .44-1c.5-1.71 1.47-1.9 3-1.17 3.28 1.55 6.62 3 10 4.42 1.39.59 1.71 1.36 1 2.65-.3.52-.39 1.23-.79 1.61a2.14 2.14 0 0 1-1.65.62c-1.93-.71-3.79-1.62-5.68-2.45M97.65 194.08c1.78.78 3.54 1.61 5.34 2.34 1 .41 1.22 1 .76 2-1.79 3.9-1.13 3.25-4.41 1.86-3.08-1.29-6.08-2.77-9.18-4-1.92-.77-1.2-1.88-.68-3s.82-2.54 2.68-1.57 3.66 1.64 5.49 2.45", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M56.89 239.88a10.11 10.11 0 0 1-5.28-13.25l4.12-9.58a10.08 10.08 0 1 1 18.52 8l-4.12 9.59a10.11 10.11 0 0 1-13.24 5.28", style: {
  fill: "#bea883"
} }), /* @__PURE__ */ React.createElement("path", { d: "M55 225.75a3.83 3.83 0 1 1-3.82-3.82 3.82 3.82 0 0 1 3.82 3.82", style: {
  fill: "#bea883"
} })));
const ForwardRef = forwardRef(SvgGuitar);
const Memo = memo(ForwardRef);
export default Memo;
