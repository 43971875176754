import { gql } from '@apollo/client'
import { Stripe, loadStripe } from '@stripe/stripe-js'

import AppConfig from '@src/config/AppConfig'

let stripePromise: Promise<Stripe | null>
const percentFee = 0.029 // 2.9%
const feeInCents = 30 // 30 cents

// Based on stripe docs these error codes seem to be irrecoverable so the modal should not allow resubmission
export const irrecoverableErrorCodes = [
  'account_closed',
  'account_country_invalid_address',
  'account_error_country_change_requires_additional_steps',
  'account_invalid',
  'api_key_expired',
  'application_fees_not_allowed',
  'balance_insufficient',
  'bank_account_unusable',
  'bank_account_unverified',
  'bank_account_verification_failed',
  'card_decline_rate_limit_exceeded',
  'charge_already_captured',
  'charge_already_refunded',
  'charge_disputed',
  'customer_max_payment_methods',
  'customer_max_subscriptions',
  'idempotency_key_in_use',
  'livemode_mismatch',
  'no_account',
  'not_allowed_on_standard_account',
  'ownership_declaration_not_allowed',
  'payment_intent_action_required',
  'payment_method_not_available',
  'payment_method_provider_decline',
  'platform_account_required',
  'platform_api_key_expired',
  'rate_limit',
  'resource_already_exists',
  'secret_key_required',
  'stripe_tax_inactive',
  'testmode_charges_only',
  'tls_version_unsupported',
  'transfers_not_allowed',
  'invalid_request_error',
  'processing_error', // subset error code of card_error
  'payment_canceled', // pbxx custom value from BE
]

// estimated fee, 3% this is added to the amount that is charged to the customer
export const calculateFee = (
  baseChargeAmountCents: number,
): { feeAmount: number; totalAmount: number } => {
  const floatTotal = (baseChargeAmountCents + feeInCents) / (1 - percentFee)
  const totalAmount = Math.ceil(floatTotal)
  const feeAmount = totalAmount - baseChargeAmountCents
  return { feeAmount, totalAmount }
}

export const initStripe = () => {
  stripePromise = loadStripe(AppConfig.STRIPE_KEY || '')
}
export const GET_PAYMENT_INTENT = gql`
  query PollPaymentIntentStatus($id: ID!) {
    getStripePaymentIntentByID(id: $id) {
      id
      status
    }
  }
`
export const CREATE_STRIPE_PAYMENT_INTENT = gql`
  mutation CreatePaymentIntent($data: CreateStripePaymentIntentInput!) {
    createPaymentIntent(req: $data) {
      id
      payment_intent_id
      status
      client_secret
      final_charge_amount
      amount
      country_code
    }
  }
`
export const UPDATE_PAYMENT_INTENT = gql`
  mutation UpdatePaymentIntent($data: UpdateStripePaymentIntent!) {
    updatePaymentIntent(data: $data) {
      id
      payment_intent_id
      status
      client_secret
      final_charge_amount
      amount
      country_code
    }
  }
`
const stripePromiseRef = {
  instance: () => stripePromise,
}
export default stripePromiseRef
