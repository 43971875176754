import * as React from "react";
import { forwardRef, memo } from "react";
const SvgAppliances = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlSpace: "preserve", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, viewBox: "0 0 233 409", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M506.8 532.1H332.1c-2.5 0-4.5-2-4.5-4.5V264c0-2.5 2-4.5 4.5-4.5h174.6c2.5 0 4.5 2 4.5 4.5v263.6c.1 2.5-1.9 4.5-4.4 4.5", style: {
  fill: "#ababab",
  fillRule: "nonzero"
}, transform: "translate(-399.053 -285.033)scale(1.22787)" }), /* @__PURE__ */ React.createElement("path", { d: "M483.7 393.3c0 35.5-28.8 64.2-64.2 64.2-35.5 0-64.2-28.8-64.2-64.2s28.8-64.2 64.2-64.2 64.2 28.7 64.2 64.2", style: {
  fill: "#616262",
  fillRule: "nonzero"
}, transform: "translate(-399.053 -285.033)scale(1.22787)" }), /* @__PURE__ */ React.createElement("path", { d: "m487.2 315.6-14.2-4.2c-36.1-10.6-74.5-10.6-110.6 0l-14.2 4.2c-3.8 1.1-7.7-1.7-7.7-5.7v-32c0-2.5 2-4.4 4.5-4.4h145.4c2.5 0 4.5 2 4.5 4.4v32c-.1 4-3.9 6.9-7.7 5.7", style: {
  fill: "#fff",
  fillRule: "nonzero"
}, transform: "translate(-399.053 -285.033)scale(1.22787)" }), /* @__PURE__ */ React.createElement("path", { d: "M470.7 393.3c0 28.3-23 51.3-51.3 51.3s-51.3-23-51.3-51.3 23-51.3 51.3-51.3c28.4 0 51.3 23 51.3 51.3", style: {
  fill: "#beddf4",
  fillRule: "nonzero"
}, transform: "translate(-399.053 -285.033)scale(1.22787)" }), /* @__PURE__ */ React.createElement("g", { transform: "translate(-399.053 -285.033)scale(1.22787)" }, /* @__PURE__ */ React.createElement("clipPath", { id: "a" }, /* @__PURE__ */ React.createElement("path", { d: "M368.2 393.3c0 28.3 23 51.3 51.3 51.3s51.3-23 51.3-51.3-23-51.3-51.3-51.3c-28.4 0-51.3 23-51.3 51.3" })), /* @__PURE__ */ React.createElement("g", { clipPath: "url(#a)" }, /* @__PURE__ */ React.createElement("path", { d: "M458.6 314.1s-65 51.4-39 77.9c0 0-72.8 32.2-81.3-27.1", style: {
  fill: "#95c8ec",
  fillRule: "nonzero"
} }))), /* @__PURE__ */ React.createElement("g", { transform: "translate(-399.053 -285.033)scale(1.22787)" }, /* @__PURE__ */ React.createElement("clipPath", { id: "b" }, /* @__PURE__ */ React.createElement("path", { d: "M368.2 393.3c0 28.3 23 51.3 51.3 51.3s51.3-23 51.3-51.3-23-51.3-51.3-51.3c-28.4 0-51.3 23-51.3 51.3" })), /* @__PURE__ */ React.createElement("g", { clipPath: "url(#b)" }, /* @__PURE__ */ React.createElement("path", { d: "M380.3 469.3s65-51.4 39-77.9c0 0 72.8-32.2 81.3 27.1", style: {
  fill: "#95c8ec",
  fillRule: "nonzero"
} }))), /* @__PURE__ */ React.createElement("path", { d: "M368.2 289.2c0 5.4-4.4 9.7-9.7 9.7-5.4 0-9.7-4.4-9.7-9.7 0-5.4 4.4-9.7 9.7-9.7s9.7 4.4 9.7 9.7", style: {
  fill: "#ababab",
  fillRule: "nonzero"
}, transform: "translate(-399.053 -285.033)scale(1.22787)" }), /* @__PURE__ */ React.createElement("path", { d: "M386.5 283.6h62.1v11.3h-62.1zM463.5 280.2h26v2.8h-26zM463.5 285.3h26v2.8h-26zM463.5 290.4h26v2.8h-26zM463.5 295.5h26v2.8h-26z", style: {
  fill: "#ababab"
}, transform: "translate(-399.053 -285.033)scale(1.22787)" }), /* @__PURE__ */ React.createElement("path", { d: "M351 287.8h15v2.8h-15z", style: {
  fill: "#616262"
}, transform: "matrix(.99028 -.72592 .72592 .99028 -523.863 43.874)" }), /* @__PURE__ */ React.createElement("path", { d: "M396.1 289.4c0 1.5-1.2 2.7-2.7 2.7s-2.7-1.2-2.7-2.7 1.2-2.7 2.7-2.7 2.7 1.2 2.7 2.7M408.1 289.4c0 1.5-1.2 2.7-2.7 2.7s-2.7-1.2-2.7-2.7 1.2-2.7 2.7-2.7 2.7 1.2 2.7 2.7M420.2 289.4c0 1.5-1.2 2.7-2.7 2.7s-2.7-1.2-2.7-2.7 1.2-2.7 2.7-2.7 2.7 1.2 2.7 2.7M432.2 289.4c0 1.5-1.2 2.7-2.7 2.7s-2.7-1.2-2.7-2.7 1.2-2.7 2.7-2.7 2.7 1.2 2.7 2.7M444.3 289.4c0 1.5-1.2 2.7-2.7 2.7s-2.7-1.2-2.7-2.7 1.2-2.7 2.7-2.7 2.7 1.2 2.7 2.7", style: {
  fill: "#616262",
  fillRule: "nonzero"
}, transform: "translate(-399.053 -285.033)scale(1.22787)" }), /* @__PURE__ */ React.createElement("path", { d: "M490.1 523H345.2c-2.6 0-4.8-2.1-4.8-4.8 0-2.6 2.1-4.8 4.8-4.8h144.9c2.6 0 4.8 2.1 4.8 4.8-.1 2.7-2.2 4.8-4.8 4.8M490.1 506.6H345.2c-2.6 0-4.8-2.1-4.8-4.8 0-2.6 2.1-4.8 4.8-4.8h144.9c2.6 0 4.8 2.1 4.8 4.8-.1 2.7-2.2 4.8-4.8 4.8M490.1 490.3H345.2c-2.6 0-4.8-2.1-4.8-4.8 0-2.6 2.1-4.8 4.8-4.8h144.9c2.6 0 4.8 2.1 4.8 4.8-.1 2.6-2.2 4.8-4.8 4.8", style: {
  fill: "#969696",
  fillRule: "nonzero"
}, transform: "translate(-399.053 -285.033)scale(1.22787)" }));
const ForwardRef = forwardRef(SvgAppliances);
const Memo = memo(ForwardRef);
export default Memo;
