import * as React from "react";
import { forwardRef, memo } from "react";
const SvgCycling = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlSpace: "preserve", style: {
  fillRule: "evenodd",
  clipRule: "evenodd"
}, viewBox: "0 0 233 409", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "m309.22 373.3 54.49 56.52 2.66 6.92a9.81 9.81 0 0 1-2.9 11.06L297.78 502l-8.29-13 43.07-43.1-57.09-39.38 1.75-39.1z", style: {
  fill: "#f05323",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.976 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "m313.62 464.86 1.18 3.14c1 2.92-.05 5.56-.69 8.59l-5.69 16.5-3.61 3.06 2.57-25.07z", opacity: 0.13, style: {
  fill: "#010101",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M279 612c0 3.63-31.24 6.58-69.79 6.58s-69.78-2.95-69.78-6.58 31.24-6.58 69.78-6.58S279 608.35 279 612M513.32 612c0 3.63-31.25 6.58-69.79 6.58s-69.79-2.95-69.79-6.58 31.25-6.58 69.79-6.58 69.79 3 69.79 6.58", opacity: 0.2, style: {
  fill: "#010101",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "m373.73 362.16 15.65 28.44L426 362.75l6.62 12.09-41.72 29.21a11 11 0 0 1-6.171 1.894 11.02 11.02 0 0 1-9.919-6.244L357.09 366z", style: {
  fill: "#ac7a38",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "m442.05 430.22-8.55.31-.18-5.13 8.55-.31q.111-.01.222-.01c1.41 0 2.57 1.16 2.57 2.57s-1.16 2.57-2.57 2.57zM377.81 364.37l-21.87 8-4.16-11.37a11.6 11.6 0 0 1-.709-4c0-6.388 5.256-11.644 11.644-11.644 4.877 0 9.26 3.064 10.935 7.644z", style: {
  fill: "#f05323",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M379.26 324.91s-8.08 8.81-11.86 5.82l12.51 16.13s8.52-7.94 12.69-13.93z", style: {
  fill: "#8b633a",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "m718.28 360.16-26.14 48.47", style: {
  fill: "none",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -237.876 -7.143)" }), /* @__PURE__ */ React.createElement("path", { d: "m734.95 376.82-34.17 40.46-32.89 53.64", style: {
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -237.876 -7.143)" }), /* @__PURE__ */ React.createElement("path", { d: "M447.58 545.88a8 8 0 0 1-5.644 2.33c-4.388 0-8-3.611-8-8s3.612-8 8-8a8 8 0 0 1 8 8 8 8 0 0 1-2.356 5.67", style: {
  fill: "#010101",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "m692.14 408.63-10.45 13.83-30.66 35.61", style: {
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -237.876 -7.143)" }), /* @__PURE__ */ React.createElement("path", { d: "M442.21 479.82h.01c33.189 0 60.5 27.311 60.5 60.5s-27.311 60.5-60.5 60.5-60.5-27.311-60.5-60.5a60.52 60.52 0 0 1 17.72-42.78 60.12 60.12 0 0 1 42.77-17.72m0-9c-38.119.003-69.484 31.371-69.484 69.49 0 38.121 31.369 69.49 69.49 69.49s69.49-31.369 69.49-69.49a69.51 69.51 0 0 0-20.356-49.14 69.32 69.32 0 0 0-49.14-20.35", style: {
  fill: "#010101",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M442.21 470.82h.004c38.121 0 69.49 31.369 69.49 69.49s-31.369 69.49-69.49 69.49-69.49-31.369-69.49-69.49a69.51 69.51 0 0 1 20.356-49.14 69.33 69.33 0 0 1 49.13-20.35m0-3.5c-40.046.001-72.998 32.954-72.998 73s32.954 73 73 73 73-32.953 73-73a73.02 73.02 0 0 0-21.382-51.62 72.53 72.53 0 0 0-51.62-21.38", style: {
  fill: "#333",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "m693.31 359.43 5.03 61.84 14.6 55.08M671.55 361.61l13.93 62.01 6 55.55", style: {
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -237.876 -7.143)" }), /* @__PURE__ */ React.createElement("path", { d: "m752.74 418.86-63.15 6.78-51.53 13.32", style: {
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -237.876 -7.143)" }), /* @__PURE__ */ React.createElement("path", { d: "m652.12 376.8 43.83 37.95 51.42 29.76M638.53 394.63l50.23 29.48 45.33 37.93", style: {
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -237.876 -7.143)" }), /* @__PURE__ */ React.createElement("path", { d: "m633.34 417.27 58.53-4.21 58.57-15.01", style: {
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -237.876 -7.143)" }), /* @__PURE__ */ React.createElement("path", { d: "M442.15 534h.017c3.44 0 6.27 2.83 6.27 6.27s-2.83 6.27-6.27 6.27-6.27-2.83-6.27-6.27c0-1.661.66-3.255 1.833-4.43a6.22 6.22 0 0 1 4.42-1.84m0-1.66h-.011c-4.344 0-7.92 3.575-7.92 7.92s3.576 7.92 7.92 7.92c4.345 0 7.92-3.575 7.92-7.92a7.92 7.92 0 0 0-2.319-5.6 7.88 7.88 0 0 0-5.59-2.32", style: {
  fill: "#b5b6b6",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "m435.32 359.33 15.44 52.87", style: {
  fill: "none",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -237.876 -7.143)" }), /* @__PURE__ */ React.createElement("path", { d: "m458.88 358.4 4.11 53.87 15.33 65.74M450.76 412.19l2.29 17.19 3.5 51.22", style: {
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -237.876 -7.143)" }), /* @__PURE__ */ React.createElement("path", { d: "M397.38 432.92a3.37 3.37 0 0 1-3.09-2.12l-5.64-14.37a3.48 3.48 0 0 1-.09-2.44 3.35 3.35 0 0 1 3.17-2.23l28.32.18a3.433 3.433 0 0 1 3.44 3q.014.155.014.31a3.346 3.346 0 0 1-3.33 3.33h-.024l-21.1-.13h-.024c-.899 0-1.64.74-1.64 1.64 0 .205.039.409.114.6l2.95 7.5c.191.428.289.891.289 1.36a3.356 3.356 0 0 1-3.34 3.34h-.019", style: {
  fill: "#b5b6b6",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M395.5 420.06c1.36 4 2.44 6.81 3.95 11 1.09-.89 1.6-1.29 1.05-2.68l-3-7.67a1.6 1.6 0 0 1-.111-.59c0-.889.731-1.62 1.62-1.62h.011l21.14.13c1.37 0 .66-.53 1.18-1.71-8.32-.12-15.15-.1-23.52-.06a2.45 2.45 0 0 0-2.438 2.44c0 .265.043.529.128.78", opacity: 0.1, style: {
  fill: "#010101",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M208.32 480.11h.4c33.118.104 60.31 27.382 60.31 60.5 0 33.189-27.311 60.5-60.5 60.5h-.59c-33.118-.104-60.31-27.382-60.31-60.5 0-33.189 27.311-60.5 60.5-60.5zm0-9h-.215c-38.127 0-69.5 31.373-69.5 69.5 0 38.046 31.24 69.382 69.285 69.5h.665c38.127 0 69.5-31.373 69.5-69.5 0-38.046-31.24-69.382-69.285-69.5z", style: {
  fill: "#010101",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M208.32 471.11h.45c38.045.118 69.285 31.454 69.285 69.5 0 38.127-31.373 69.5-69.5 69.5h-.665c-38.045-.118-69.285-31.454-69.285-69.5 0-38.127 31.373-69.5 69.5-69.5zm0-3.5h-.23c-40.047 0-73 32.953-73 73 0 39.96 32.81 72.874 72.77 73h.71c40.047 0 73-32.953 73-73 0-39.96-32.81-72.874-72.77-73z", style: {
  fill: "#333",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M250.53 398.81h6.67v41.37h-6.67z", style: {
  fill: "#b0b1b1"
}, transform: "matrix(.56699 -.23869 .23869 .56699 -171.873 -.72)" }), /* @__PURE__ */ React.createElement("path", { d: "m248.92 399.14-6.15 2.59.81 1.92c2.92.08 5.27.68 7.13 3.69 1.15 1.87 4.85 15.46 8.31 18.41.441.366.937.66 1.47.87z", opacity: 0.1, style: {
  fill: "#010101",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M219.9 400.75c1.87 3.94 5.66 5.11 11.73 5.15 8.87.06 16.85-9.67 45.76-9.49 7.24 0 6.38-8-7.45-8.05-19.66-.13-22.7-4.59-38.44-4.69-18.46-.12-14.29 11.39-11.6 17.08", style: {
  fill: "#616262",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M227.64 395.65c-3.88 1.26-7.42-.28-10.12-3a22.1 22.1 0 0 0 2.38 8.14c1.87 3.94 5.66 5.11 11.72 5.15 8.88.06 16.86-9.67 45.77-9.49.547.01 1.094-.04 1.63-.15-15.56-8.46-34.47-6.32-51.38-.61", opacity: 0.1, style: {
  fill: "#010101",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M427.26 451.39a3.356 3.356 0 0 1 .32-4.71l6.92-6.24a12.7 12.7 0 0 0 4.47-9.72c-.139-6.624-5.595-12.012-12.22-12.07l-9.27-.06v-6.67l9.27.06c10.349.106 18.805 8.65 18.805 18.999 0 5.456-2.35 10.656-6.445 14.261l-7.11 6.47a3.356 3.356 0 0 1-4.71-.32", style: {
  fill: "#fff",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "m416.21 375.66 47.85 41.18 49.08 28.93M403.08 393.2l53.48 34.33 44.6 36.39", style: {
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -237.876 -7.143)" }), /* @__PURE__ */ React.createElement("path", { d: "m501.85 376.19-40.95 49.9-29.38 49.51", style: {
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -237.876 -7.143)" }), /* @__PURE__ */ React.createElement("path", { d: "m396.03 417.92 61.75-4.05 57.5-14.95M400.03 440.59l59.21-15.01 60.62-5", style: {
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -237.876 -7.143)" }), /* @__PURE__ */ React.createElement("path", { d: "m413.07 461.5 40.62-45.96 31.13-51.83", style: {
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -237.876 -7.143)" }), /* @__PURE__ */ React.createElement("path", { d: "M221.12 540.29c-.049 7.163-5.956 13.029-13.12 13.029-7.197 0-13.12-5.922-13.12-13.12s5.923-13.12 13.12-13.12l.12.001c7.186.066 13.05 6.024 13 13.21", style: {
  fill: "#434343",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M222.81 556.48c-2.37-4.49-11.06 2.94-11.12-3.06s6.05-10.95-.82-10.79c-8.05.19-9.68-5.76-10.35-3.09s-1.74 10.33-1.74 10.33-2.78 10 9.55 13.88c1.67.52 2.21 6.49.8 7.53-.19.14-.37.3-.56.47a5.45 5.45 0 0 0-3.33 5.014c0 2.984 2.456 5.44 5.44 5.44a5.44 5.44 0 0 0 2.53-.624 3.83 3.83 0 0 0 1.65-1.33 5.44 5.44 0 0 0 1.25-3.48v-.23q.091-.803.09-1.61c0-4.66-.58-8.94 2.76-11.61s5.06-4.56 3.86-6.84", style: {
  fill: "#010101",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M215.5 540.25c-.022 4.105-3.405 7.47-7.51 7.47-4.12 0-7.51-3.39-7.51-7.51s3.39-7.51 7.51-7.51h.01c4.116.027 7.482 3.434 7.46 7.55", style: {
  fill: "#010101",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "m428.31 494.49-24.86-65.88a5.48 5.48 0 0 0-6.47-3.36l-1 .27a5.47 5.47 0 0 0-3 2l-.34.46a5.46 5.46 0 0 1-4.41 2.19L262 429.38a8.86 8.86 0 0 0-8 5l-50.77 105.26a3.3 3.3 0 0 0-.342 1.47 3.34 3.34 0 0 0 2.962 3.31l97.49 10.64a4.57 4.57 0 0 0 3.63-1.2l97.33-91a1.71 1.71 0 0 1 2.75.58l14.34 33.85 20 44.14c.235.513.823.772 1.36.6a1.103 1.103 0 0 0 .71-1.41zm-167.69-46.74 37 98c.103.273.156.563.156.856a2.43 2.43 0 0 1-2.696 2.404l-80.66-9.37a3.013 3.013 0 0 1-2.75-2.99 3 3 0 0 1 .38-1.46l46.35-87.58a1.222 1.222 0 0 1 2.22.14m139.3 5.51-92.67 88.21a1.54 1.54 0 0 1-1.065.428 1.54 1.54 0 0 1-1.445-1.008l-37.22-99.46a3.3 3.3 0 0 1-.213-1.16 3.285 3.285 0 0 1 3.213-3.27h.05l122.49.78a2.73 2.73 0 0 1 2.51 1.71l4.76 11.93a1.72 1.72 0 0 1-.42 1.84", style: {
  fill: "#eeedee",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M281.14 494.66c-3.74-10.18-6.58-16.31-9.84-26.08-4.07-12.18-8.33-25.28-12.06-25.3-2.36 0-17.3 26.22-25.92 44-6.92 14.18-23.78 42-23.84 51.43 0 1.67 2.79 1.5 5.27 1h-.33a3.013 3.013 0 0 1-2.75-2.99 3 3 0 0 1 .38-1.46l46.35-87.58a1.222 1.222 0 0 1 2.22.14l37 98c.103.273.155.561.155.853a2.43 2.43 0 0 1-2.305 2.417c1.51 1.19 2.4 3.36 3.32 1.56 4-5.31-9.55-33.83-17.65-55.92", opacity: 0.1, style: {
  fill: "#010101",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M268.78 549.05c-20.66-2.13-41.34-2.93-62-5.73a1.367 1.367 0 0 0-1.346-1.555c-.746 0-1.36.614-1.36 1.36a1.367 1.367 0 0 0 1.776 1.295l97.49 10.64h.09v-1.13c-11.32-4.07-23.32-4.15-34.66-4.89M402.22 459.7c-13.7 11.77-66.13 61.75-97.22 91.86.12-.09 1.85 2.41 2 2.3l97.33-91a1.71 1.71 0 0 1 2.75.58l1.19 2.81c.2-1.4-.78-3.94-1.86-5.87a2.718 2.718 0 0 0-4.16-.72M270.53 437h.05l122.49.78a2.73 2.73 0 0 1 2.51 1.71l1.33 3.34q.06-1.501-.06-3c-.27-3.487-3.212-6.215-6.71-6.22-39-.19-74.91.27-112.61-.61-6.47-.63-11.39.83-10.21 7.15a3.265 3.265 0 0 1 3.21-3.19", opacity: 0.1, style: {
  fill: "#010101",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M306.28 530.54a24 24 0 0 0-1.718-.061c-13.276 0-24.2 10.924-24.2 24.2s10.924 24.2 24.2 24.2 24.2-10.924 24.2-24.2q0-.71-.042-1.419c-.714-12.076-10.374-21.857-22.44-22.72M317 551.79l-6.77.57a3.014 3.014 0 0 1-3.25-3.3l.65-6.76a3.684 3.684 0 0 1 3.651-3.293c.638 0 1.265.167 1.819.483a17.63 17.63 0 0 1 6.78 6.87c.304.545.464 1.159.464 1.784A3.674 3.674 0 0 1 317 551.79m-15.31-9.53.57 6.77q.012.133.012.266a3.014 3.014 0 0 1-3.312 2.984l-6.75-.65a3.674 3.674 0 0 1-3.294-3.642c0-.637.167-1.264.484-1.818a17.44 17.44 0 0 1 6.87-6.78 3.65 3.65 0 0 1 1.784-.465 3.664 3.664 0 0 1 3.636 3.335m-9.52 15.31 6.76-.57q.138-.013.276-.013a3.014 3.014 0 0 1 2.984 3.313l-.65 6.76a3.684 3.684 0 0 1-3.651 3.293 3.7 3.7 0 0 1-1.819-.483 17.63 17.63 0 0 1-6.78-6.87 3.66 3.66 0 0 1-.464-1.784 3.674 3.674 0 0 1 3.344-3.646m15.31 9.53-.57-6.77a3.015 3.015 0 0 1 3.29-3.25l6.76.65a3.674 3.674 0 0 1 3.299 3.642c0 .642-.169 1.272-.489 1.828a17.57 17.57 0 0 1-6.87 6.78 3.66 3.66 0 0 1-1.774.459 3.674 3.674 0 0 1-3.646-3.339", style: {
  fill: "#434343",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M305 551.56a2.753 2.753 0 0 0-2.74 2.74 2.753 2.753 0 0 0 2.74 2.74 2.753 2.753 0 0 0 2.74-2.74 2.753 2.753 0 0 0-2.74-2.74", style: {
  fill: "#333",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M326.76 598.08 310.7 596a1.477 1.477 0 0 1-1.26-1.64l.3-2.29a1.477 1.477 0 0 1 1.64-1.26l8 1.06 8 1a1.467 1.467 0 0 1 1.25 1.64l-.3 2.29a1.466 1.466 0 0 1-1.64 1.26", style: {
  fill: "#010101",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M326.76 598.08 310.7 596a1.477 1.477 0 0 1-1.26-1.64l.3-2.29a1.477 1.477 0 0 1 1.64-1.26l8 1.06 8 1a1.467 1.467 0 0 1 1.25 1.64l-.3 2.29a1.467 1.467 0 0 1-1.57 1.28", style: {
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "m310.3 552.3 8.9 37q.045.145.045.296a1 1 0 0 1-1.915.404l-17.66-33.7a5.7 5.7 0 0 1-.56-2.469c0-3.138 2.582-5.72 5.72-5.72A5.73 5.73 0 0 1 309.8 551q.327.626.5 1.31", style: {
  fill: "#010101",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "m569.58 470.91-.68 5.19", style: {
  fill: "none",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -237.876 -7.143)" }), /* @__PURE__ */ React.createElement("path", { d: "m304.9 529.87-23.33-.73-74.48-2.33a12.29 12.29 0 0 0-9.32 3.69 12.67 12.67 0 0 0-3.53 11.21c2.07 12 13.67 11.61 13.67 11.61s9.25 19.32 2.81 27.73l93-2.56.218.001c13.342 0 24.32-10.978 24.32-24.32 0-12.979-10.389-23.788-23.358-24.301", style: {
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "m279.06 565 11.82 2.36.3.06c-.94 8.71 21.36 12.23 30.76 14.35 4.13.94 6.8 5.86 6.35 10.08L276.84 584z", style: {
  fill: "#f05323",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "m250.91 350.76-8.4 9.29c-13.62 18.38-5.64 34.4-1.24 41l27.45 41.3 19.73 32.7-9.39 89.95 11.22 2.23 21.06-91.68a23.9 23.9 0 0 0-.76-13.3l-29.92-84.77 4.16-6.74z", style: {
  fill: "#8b633a",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "m529.18 444.19.49-4.3 11.73 2.25-.95 4.13z", style: {
  fill: "#f5f6f6",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -237.876 -7.143)" }), /* @__PURE__ */ React.createElement("path", { d: "m305.56 384.11-12.16-7.61-.69.6a18.28 18.28 0 0 0-5.18 19.8l6.2 17.58s-2.84-27.05 11.83-30.37", opacity: 0.21, style: {
  fill: "#010101",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M372.6 330.29c-21.1-10.24-48.77-12.1-67.88-7-31.66 8.5-54.14 27.79-54.14 27.79l34.24 19.59 13.41 9.05 7.33 4.35L335 376.5c21.74-5.63 34.12-18.59 49.45-34.25-10.58-2.23-11.49-6.54-11.84-12", style: {
  fill: "#062640",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.976 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "m586.67 234.79 7.93 63.29 83.6 20.6 3.97-10.47-67.04-21.04-5.52-50.92z", style: {
  fill: "#8b633a",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -237.876 -7.143)" }), /* @__PURE__ */ React.createElement("path", { d: "m362.28 357-27.46 4.74-2.47-14.32a14 14 0 0 1-.252-2.637c0-7.642 6.288-13.93 13.93-13.93 6.855 0 12.742 5.06 13.772 11.837z", style: {
  fill: "#f05323",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.976 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M447.66 408.87a2.2 2.2 0 0 0-2.05 1.61c-1.21 3.79-9.76 3.87-9.76 3.87-1 7.47 3.88 8.41 6.42 8.41q.734.005 1.46-.11c-.62-3.85.75-4.69 1.85-4.69.322 0 .639.072.93.21.609.337 1.13.813 1.52 1.39a23.6 23.6 0 0 1 1.62 3.55c.69 1.91.47 4.95-.2 7.28a7.54 7.54 0 0 0 .31 4.46 3.34 3.34 0 0 0 2.58 2.08q.193-.002.38-.05c.51-.15.85-.42.59-.94-.66-1.33-1.54-4.13-1-6a12.48 12.48 0 0 0-.67-9.07l-1.25-2.63a5.86 5.86 0 0 1-.52-4.11l.61-2.57a2.1 2.1 0 0 0-1.78-2.57l-.75-.09h-.25", style: {
  fill: "#010101",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.976 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M436.17 426.15a10.3 10.3 0 0 0-3.664-.676c-5.634 0-10.27 4.636-10.27 10.27 0 4.143 2.507 7.898 6.334 9.486z", style: {
  fill: "#f05323",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.976 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "m434.57 447.62-6-2.39 7.51-18.83 6 2.39a1.743 1.743 0 0 1 1 2.25l-6.23 15.61a1.714 1.714 0 0 1-2.24 1", style: {
  fill: "#f05323",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.976 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "m444.33 435.22-7.95-3.17 1.9-4.77 8 3.17a2.58 2.58 0 0 1 1.509 2.341c0 1.41-1.16 2.57-2.57 2.57-.285 0-.569-.048-.839-.141", style: {
  fill: "#f05323",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.976 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "m443.18 440-7.95-3.17 1.9-4.77 7.95 3.17a2.58 2.58 0 0 1 1.509 2.341c0 1.41-1.16 2.57-2.57 2.57-.285 0-.569-.048-.839-.141", style: {
  fill: "#f05323",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.976 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "m440.83 444.58-7.95-3.16 1.9-4.77 8 3.16a2.58 2.58 0 0 1 1.509 2.341c0 1.41-1.16 2.57-2.57 2.57-.285 0-.569-.048-.839-.141", style: {
  fill: "#f05323",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.976 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "m437.4 448.64-7.95-3.16 1.9-4.77 7.95 3.16a2.58 2.58 0 0 1 1.509 2.341c0 1.41-1.16 2.57-2.57 2.57-.285 0-.569-.048-.839-.141", style: {
  fill: "#f05323",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.976 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M436.17 426.15a10.3 10.3 0 0 0-3.664-.676c-5.634 0-10.27 4.636-10.27 10.27 0 4.143 2.507 7.898 6.334 9.486z", style: {
  fill: "#f05323",
  fillRule: "nonzero"
}, transform: "scale(.61518)rotate(-67.396 212.224 435.31)" }), /* @__PURE__ */ React.createElement("path", { d: "m434.57 447.62-6-2.39 7.51-18.83 6 2.39a1.743 1.743 0 0 1 1 2.25l-6.23 15.61a1.714 1.714 0 0 1-2.24 1", style: {
  fill: "#f05323",
  fillRule: "nonzero"
}, transform: "scale(.61518)rotate(-67.396 212.224 435.31)" }), /* @__PURE__ */ React.createElement("path", { d: "m444.33 435.22-7.95-3.17 1.9-4.77 8 3.17a2.58 2.58 0 0 1 1.509 2.341c0 1.41-1.16 2.57-2.57 2.57-.285 0-.569-.048-.839-.141", style: {
  fill: "#f05323",
  fillRule: "nonzero"
}, transform: "scale(.61518)rotate(-67.396 212.224 435.31)" }), /* @__PURE__ */ React.createElement("path", { d: "m443.18 440-7.95-3.17 1.9-4.77 7.95 3.17a2.58 2.58 0 0 1 1.509 2.341c0 1.41-1.16 2.57-2.57 2.57-.285 0-.569-.048-.839-.141", style: {
  fill: "#f05323",
  fillRule: "nonzero"
}, transform: "scale(.61518)rotate(-67.396 212.224 435.31)" }), /* @__PURE__ */ React.createElement("path", { d: "m440.83 444.58-7.95-3.16 1.9-4.77 8 3.16a2.58 2.58 0 0 1 1.509 2.341c0 1.41-1.16 2.57-2.57 2.57-.285 0-.569-.048-.839-.141", style: {
  fill: "#f05323",
  fillRule: "nonzero"
}, transform: "scale(.61518)rotate(-67.396 212.224 435.31)" }), /* @__PURE__ */ React.createElement("path", { d: "m437.4 448.64-7.95-3.16 1.9-4.77 7.95 3.16a2.58 2.58 0 0 1 1.509 2.341c0 1.41-1.16 2.57-2.57 2.57-.285 0-.569-.048-.839-.141", style: {
  fill: "#f05323",
  fillRule: "nonzero"
}, transform: "scale(.61518)rotate(-67.396 212.224 435.31)" }), /* @__PURE__ */ React.createElement("path", { d: "M362.36 305.64v-19.56c0-3.699 3.041-6.745 6.74-6.75v-4.14c0-6.753 5.557-12.31 12.31-12.31h15.29c19.091 0 34.8 15.709 34.8 34.8v2.59z", style: {
  fill: "#062640",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M396.93 338.86h-.01c-18.356 0-33.46-15.104-33.46-33.46s15.104-33.46 33.46-33.46 33.46 15.104 33.46 33.46v.01c0 18.35-15.1 33.45-33.45 33.45", style: {
  fill: "#8b633a",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M363.48 300.1c2.913 0 5.31 2.397 5.31 5.31s-2.397 5.31-5.31 5.31-5.31-2.397-5.31-5.31v-.01c0-2.908 2.392-5.3 5.3-5.3z", style: {
  fill: "#8b633a",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M380.43 303.48h.01c1.059 0 1.93.871 1.93 1.93s-.871 1.93-1.93 1.93a1.94 1.94 0 0 1-1.93-1.93v-.01a1.93 1.93 0 0 1 1.92-1.92M413.42 303.48h.01c1.059 0 1.93.871 1.93 1.93s-.871 1.93-1.93 1.93a1.94 1.94 0 0 1-1.93-1.93c0-1.055.865-1.925 1.92-1.93", style: {
  fill: "#062640",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M430.38 300.1c2.913 0 5.31 2.397 5.31 5.31s-2.397 5.31-5.31 5.31-5.31-2.397-5.31-5.31 2.397-5.31 5.31-5.31", style: {
  fill: "#8b633a",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M380.32 286.41c-6.155 0-11.22-5.065-11.22-11.22l25.41-8.88 16.31 5.69-2.62 2.69a38.9 38.9 0 0 1-27.88 11.77", style: {
  fill: "#062640",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M386.38 319.6a14.904 14.904 0 0 0 21.09 0", style: {
  fill: "#fff",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "m421.65 291.34 6.91 25s-6.11 21.56-31.63 22.55M372 291.34l-6.91 25s6.11 21.56 31.64 22.55", style: {
  fillRule: "nonzero",
  stroke: "#f05323",
  strokeWidth: 3
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }), /* @__PURE__ */ React.createElement("path", { d: "M399.79 342.59h-5a1.879 1.879 0 0 1-2.007-1.865 1.88 1.88 0 0 1 2.007-1.865h5a1.877 1.877 0 0 1 1.733 1.865c0 .974-.761 1.794-1.733 1.865M413.43 257.81v.12a3.706 3.706 0 0 1-3.88 3.25 3.574 3.574 0 0 1-3.21-4.06l.29-2.08a1.626 1.626 0 0 0-1.18-1.8l-2.9-.81a1.608 1.608 0 0 0-2 1.55 3.736 3.736 0 0 1-3.38 3.77 3.587 3.587 0 0 1-3.76-3.565v-.005a1.647 1.647 0 0 0-2.07-1.58l-3.36.92c-.789.221-1.3.998-1.19 1.81l.23 1.59a3.715 3.715 0 0 1-2.81 4.21 3.572 3.572 0 0 1-4.22-3 1.655 1.655 0 0 0-2.31-1.27c-12.148 5.574-19.968 17.774-19.96 31.14a2.88 2.88 0 0 0 2.5 2.83l32.25 4.4a35.5 35.5 0 0 0 9.71 0l31.12-4.37a2.9 2.9 0 0 0 2.49-2.87v-.21c-.003-13.399-7.866-25.617-20.06-31.17a1.65 1.65 0 0 0-2.3 1.27", style: {
  fill: "#f05323",
  fillRule: "nonzero"
}, transform: "matrix(.61518 0 0 .61518 -83.975 -81.531)" }));
const ForwardRef = forwardRef(SvgCycling);
const Memo = memo(ForwardRef);
export default Memo;
