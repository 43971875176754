import * as React from "react";
import { forwardRef, memo } from "react";
const SvgKebab = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "#999", fillRule: "evenodd", d: "M13.5 12a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" }));
const ForwardRef = forwardRef(SvgKebab);
const Memo = memo(ForwardRef);
export default Memo;
