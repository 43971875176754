import { auth } from '@promoboxx/redux-stores'
import { ofType } from 'redux-observable'
import { merge, of } from 'rxjs'
import { mergeMap } from 'rxjs/operators'

import * as actions from '../actions'

export const setAuthValidationComplete = (actions$) => {
  return actions$.pipe(
    ofType(
      auth.actions.VALIDATE_AUTH_FULFILLED,
      auth.actions.VALIDATE_AUTH_REJECTED,
    ),
    mergeMap(() => {
      return merge(
        of({
          type: actions.SET_AUTH_VALIDATION_COMPLETE,
          payload: true,
        }),
      )
    }),
  )
}
