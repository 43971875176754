import { Stripe } from '@stripe/stripe-js'

import stripePromiseRef, { initStripe } from '@src/components/payment/stripe'

import { Service } from './index'

const stripe: Service<Promise<Stripe | null>> = {
  init: () => {
    initStripe()
    return new Promise((resolve) => {
      stripePromiseRef.instance().then(() => {
        resolve()
      })
    })
  },
  loaded: () => {
    return window.Stripe !== undefined
  },
  instance: () => {
    return stripePromiseRef.instance()
  },
}
export default stripe
