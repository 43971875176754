import { createReducer } from '../../lib/Utils'

import { SET_PAID_AD_ID } from './actions'

const initialState = {
  selectedPaidAdId: null,
}

export default createReducer(initialState, {
  [SET_PAID_AD_ID]: (state, action) => {
    const newState = { ...state, selectedPaidAdId: action.payload.id }
    return newState
  },
})
