import * as React from "react";
import { forwardRef, memo } from "react";
const SvgDownloadables = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 22 22", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("circle", { cx: 11, cy: 11, r: 11, fill: "#0C85CF" }), /* @__PURE__ */ React.createElement("path", { fill: "#FFF", d: "M12.833 11h1.834L11 14.667 7.333 11h1.834V4.583h3.666zm-5.5 4.583h7.334v1.834H7.333z" }));
const ForwardRef = forwardRef(SvgDownloadables);
const Memo = memo(ForwardRef);
export default Memo;
