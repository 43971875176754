import * as React from "react";
import { forwardRef, memo } from "react";
const SvgModalWallpaper = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", preserveAspectRatio: "none", viewBox: "0 0 1600 465", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("linearGradient", { id: "a", x1: "0%", x2: "98.961%", y1: "53.059%", y2: "45.852%" }, /* @__PURE__ */ React.createElement("stop", { offset: "0%", stopColor: "#F1F1F1", stopOpacity: 0.5 }), /* @__PURE__ */ React.createElement("stop", { offset: "100%", stopColor: "#FFF", stopOpacity: 0.5 }))), /* @__PURE__ */ React.createElement("path", { fill: "url(#a)", d: "M1600 435.646c-238.653 43.613-576.374 38.627-792.376-14.957C587.408 366.059 242.77 361.107 0 405.834V0h1600z" }));
const ForwardRef = forwardRef(SvgModalWallpaper);
const Memo = memo(ForwardRef);
export default Memo;
