import { retailerAccounts } from '@promoboxx/redux-stores'
import I18n from 'i18n-js'
import { createSelector } from 'reselect'

import {
  brandsForCurrentRetailerAccountSelector,
  currentRetailerAccountSelector,
  currentBrandSelector,
  userIsInternalSelector,
  userHasBrandPermissionsSelector,
  userHasDivisionPermissionsSelector,
  nonUserRetailerAccountFromUrlSelector,
} from '../app/selectors'
import {
  allocationDetailsBalanceByBrandForCurrentRetailerAccountSelector,
  allocationDetailsTotalForCurrentRetailerAccountSelector,
} from '../retailerAccounts/allocationDetails/selectors'

/**
 * Component Selectors
 */
export const selectIsLocalAdsAvailable = createSelector(
  [brandsForCurrentRetailerAccountSelector],
  (brands) => {
    const brandWithLocalAdsEnabled = brands.findIndex((brand) => {
      return brand.settings && brand.settings.facebook_ads_enabled
    })
    return {
      isLocalAdsAvailable: brandWithLocalAdsEnabled !== -1,
    }
  },
)

export const selectCurrentRetailerAccountNameSlug = createSelector(
  [currentRetailerAccountSelector],
  (currentRetailerAccount) => {
    if (currentRetailerAccount) {
      const { name, slug } = currentRetailerAccount
      return {
        name,
        slug,
      }
    }
    return {}
  },
)

export const selectRetailerAccountsDropdownData = createSelector(
  [
    retailerAccounts.selectors.userRetailerAccountsSelector,
    nonUserRetailerAccountFromUrlSelector,
    userIsInternalSelector,
  ],
  (retailerAccounts, retailerAccountFromUrl, userIsInternal) => {
    let mappedRetailerAccounts = []
    if (!retailerAccounts && retailerAccountFromUrl && userIsInternal) {
      mappedRetailerAccounts.push({
        name: retailerAccountFromUrl.name,
        logo: retailerAccountFromUrl.logo_thumbnail_url,
        id: Number(retailerAccountFromUrl.id),
      })
    } else {
      mappedRetailerAccounts = retailerAccounts.map((retailer) => {
        return {
          name: retailer.name,
          logo: retailer.logo_thumbnail_url,
          id: Number(retailer.id),
        }
      })
    }

    mappedRetailerAccounts.sort((retailer1, retailer2) => {
      const retailer1Name = retailer1.name.toLowerCase()
      const retailer2Name = retailer2.name.toLowerCase()
      return retailer1Name < retailer2Name ? -1 : 1
    })
    return {
      dropdownData: mappedRetailerAccounts,
    }
  },
)

export const selectCurrentBrandSlugHeaderColors = createSelector(
  [currentBrandSelector],
  (currentBrand) => {
    if (currentBrand) {
      return {
        currentBrandMenuData: {
          headerBackgroundColor: currentBrand.header_background_color,
          headerForegroundColor: currentBrand.header_foreground_color,
          slug: currentBrand.slug,
        },
      }
    } else {
      return {
        currentBrandMenuData: {
          headerBackgroundColor: null,
          headerForegroundColor: null,
          slug: 'all',
        },
      }
    }
  },
)

export const selectCurrentBrandIdLogoUrlNameSlug = createSelector(
  [currentBrandSelector],
  (currentBrand) => {
    if (currentBrand) {
      return {
        currentBrand: {
          id: currentBrand.id,
          logoUrl: currentBrand.logo_url,
          name: currentBrand.name,
          slug: currentBrand.slug,
        },
      }
    } else {
      return {
        currentBrand: {
          name: I18n.t('nav.allBrands'),
          slug: 'all',
        },
      }
    }
  },
)

export const selectShowBrandDashboardLink = createSelector(
  [
    userIsInternalSelector,
    userHasBrandPermissionsSelector,
    userHasDivisionPermissionsSelector,
  ],
  (isInternal, hasBrandPermissions, hasDivisionPermissions) => {
    return {
      showBrandDashboardLink:
        isInternal || hasBrandPermissions || hasDivisionPermissions,
    }
  },
)

export const currentRetailerAccountAllBrandSelector = createSelector(
  [allocationDetailsTotalForCurrentRetailerAccountSelector],
  (allocationDetailsTotalForCurrentRetailerAccount) => {
    return {
      id: null,
      name: I18n.t('nav.allBrands'),
      slug: 'all',
      walletBalanceCents:
        allocationDetailsTotalForCurrentRetailerAccount.allocationDetailsTotal,
    }
  },
)

export const selectCurrentRetailerAccountBrandsMenuData = createSelector(
  [
    brandsForCurrentRetailerAccountSelector,
    allocationDetailsBalanceByBrandForCurrentRetailerAccountSelector,
    currentRetailerAccountAllBrandSelector,
  ],
  (
    brandIdMapForCurrentRetailerAccount,
    allocationDetailsBalanceByBrandIdMap,
    currentRetailerAccountAllBrand,
  ) => {
    const mappedBrands = brandIdMapForCurrentRetailerAccount
      .map((brand) => {
        return {
          id: brand.id,
          logoUrl: brand.logo_url,
          name: brand.name,
          slug: brand.slug,
          walletBalanceCents:
            allocationDetailsBalanceByBrandIdMap[brand.id] || 0,
        }
      })
      .sort((brand1, brand2) => {
        const brand1Name = brand1.name.toLowerCase()
        const brand2Name = brand2.name.toLowerCase()
        return brand1Name < brand2Name ? -1 : 1
      })

    mappedBrands.unshift(currentRetailerAccountAllBrand)

    return {
      brands: mappedBrands,
    }
  },
)

export const retailersWithSSOSelector = createSelector(
  [currentRetailerAccountSelector],
  (currentRetailerAccount) => {
    if (currentRetailerAccount) {
      return currentRetailerAccount.retailers.filter((retailer) => {
        return (
          retailer.sso_enabled &&
          retailer.brand &&
          retailer.brand.sso_name &&
          retailer.brand.sso_url
        )
      })
    }
  },
)

export const selectRetailerSSOData = createSelector(
  [retailersWithSSOSelector],
  (retailersWithSSO = []) => {
    const retailersSSOData = retailersWithSSO.map((retailer) => {
      return {
        label: `${I18n.t('nav.visit')} ${retailer.brand.name} ${
          retailer.brand.sso_name
        }`,
        sso_url: retailer.brand.sso_url,
        slug: retailer.slug,
      }
    })
    return {
      retailersSSOData,
    }
  },
)
