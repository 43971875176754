import { retailerAccounts } from '@promoboxx/redux-stores'
import { createSelector } from 'reselect'

import { nonUserRetailerAccountsBySlugSelector } from '../retailerAccounts/selectors'

export const nonUserRetailerAccountsSelector = createSelector(
  [nonUserRetailerAccountsBySlugSelector],
  (nonUserRetailerAccount = {}) => {
    let nonUserRetailerAccounts = {}
    for (const key in nonUserRetailerAccount) {
      const ra = nonUserRetailerAccount[key]
      nonUserRetailerAccounts[key] = {}
      for (const i in ra.retailers) {
        const retailer = ra.retailers[i]
        nonUserRetailerAccounts[key][retailer.brand.slug] = retailer.brand
      }
    }

    return nonUserRetailerAccounts
  },
)

export const brandsBySlugByRetailerAccountSlugSelector = createSelector(
  [
    retailerAccounts.selectors.userRetailerAccountsSelector,
    nonUserRetailerAccountsSelector,
  ],
  (retailerAccounts = [], nonUserRetailerAccounts = {}) => {
    const userRetailerAccounts = retailerAccounts.reduce(
      (accumulator, retailerAccount) => {
        accumulator[retailerAccount.slug] = retailerAccount.retailers.reduce(
          (accumulator, retailer) => {
            const brand = retailer.brand
            accumulator[brand.slug] = brand
            return accumulator
          },
          {},
        )
        return accumulator
      },
      {},
    )

    return {
      ...nonUserRetailerAccounts,
      ...userRetailerAccounts,
    }
  },
)

export const selectBrandsBySlugByRetailerAccountSlug = createSelector(
  [brandsBySlugByRetailerAccountSlugSelector],
  (brandsBySlugByRetailerAccountSlug) => ({
    brandsBySlugByRetailerAccountSlug,
  }),
)
