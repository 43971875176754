import { createReducer } from '../../lib/Utils'

import { SHOW_REQUEST_ACCESS_CONFIRMATION } from './actions'

const initialState = { showRequestAccessConfirmation: false }

export default createReducer(initialState, {
  [SHOW_REQUEST_ACCESS_CONFIRMATION]: (state) => {
    const newState = { ...state, showRequestAccessConfirmation: true }
    return newState
  },
})
