import history from '@src/lib/history'

export const SELECT_BRAND = 'SELECT_BRAND'
export const SKIP_BRAND_URL_UPDATE = 'SKIP_BRAND_URL_UPDATE'
export const SELECT_RETAILER_ACCOUNT = 'SELECT_RETAILER_ACCOUNT'
export const SKIP_RETAILER_ACCOUNT_URL_UPDATE =
  'SKIP_RETAILER_ACCOUNT_URL_UPDATE'
/**
 * Select brand
 * @param {{ id: number | null, skipUrlUpdate: boolean }} payload
 * @returns {{payload: *, type: string}}
 */
export const selectBrand = (payload) => ({ type: SELECT_BRAND, payload })
export const skipBrandUrlUpdate = () => ({ type: SKIP_BRAND_URL_UPDATE })
/**
 * Select retailer account
 * @param {{ id: number, skipUrlUpdate: boolean }} payload
 * @returns {{payload: *, type: string}}
 */
export const selectRetailerAccount = (payload) => ({
  type: SELECT_RETAILER_ACCOUNT,
  payload,
})
export const skipRetailerAccountUrlUpdate = () => ({
  type: SKIP_RETAILER_ACCOUNT_URL_UPDATE,
})

export const SET_AUTH_VALIDATION_COMPLETE = 'SET_AUTH_VALIDATION_COMPLETE'
export const setAuthValidationComplete = (payload) => ({
  type: SET_AUTH_VALIDATION_COMPLETE,
  payload,
})

export const JWT_REFRESH_TIMER_STARTED = 'JWT_REFRESH_TIMER_STARTED'

export const UPDATE_URL = 'UPDATE_URL'
export const updateUrl = (payload) => {
  history.instance().push(payload)
  return { type: UPDATE_URL }
}
