import { retailerAccounts } from '@promoboxx/redux-stores'
import { createSelector } from 'reselect'

export const retailerAccountsSelector = (state) => state.retailerAccounts || {}
export const retailerAccountSlugRequestsSelector = (state) =>
  (state.appRetailerAccounts &&
    state.appRetailerAccounts.retailerAccountSlugRequests) ||
  {}

export const userRetailerAccountsBySlugSelector = createSelector(
  [retailerAccounts.selectors.userRetailerAccountsSelector],
  (retailerAccounts = []) => {
    return retailerAccounts.reduce((accumulator, retailerAccount) => {
      accumulator[retailerAccount.slug] = retailerAccount
      return accumulator
    }, {})
  },
)

export const nonUserRetailerAccountsByIdSelector = createSelector(
  [retailerAccounts.selectors.retailerAccountsSelector],
  (nonUserRetailerAccounts) => {
    const retailerAccountsById = {}
    for (let id in nonUserRetailerAccounts) {
      let retailerAccount = nonUserRetailerAccounts[id].retailerAccount
      if (retailerAccount) {
        retailerAccountsById[id] = retailerAccount
      }
    }
    return retailerAccountsById
  },
)

export const nonUserRetailerAccountsBySlugSelector = createSelector(
  [retailerAccounts.selectors.retailerAccountsSelector],
  (nonUserRetailerAccounts) => {
    const retailerAccountsBySlug = {}
    for (let id in nonUserRetailerAccounts) {
      let retailerAccount = nonUserRetailerAccounts[id].retailerAccount
      if (retailerAccount) {
        retailerAccountsBySlug[retailerAccount.slug] = retailerAccount
      }
    }
    return retailerAccountsBySlug
  },
)

export const selectUserRetailerAccountsBySlug = createSelector(
  [userRetailerAccountsBySlugSelector],
  (userRetailerAccountsBySlug) => ({
    userRetailerAccountsBySlug,
  }),
)

export const selectNonUserRetailerAccountsBySlug = createSelector(
  [nonUserRetailerAccountsBySlugSelector],
  (nonUserRetailerAccountsBySlug) => ({
    nonUserRetailerAccountsBySlug,
  }),
)

export const selectRetailerAccountSlugRequests = createSelector(
  [retailerAccountSlugRequestsSelector],
  (retailerAccountSlugRequests) => ({ retailerAccountSlugRequests }),
)
