import moment from 'moment'
import { createSelector } from 'reselect'

import { formatWithoutCents } from '../../../lib/Utils'
import { currentRetailerAccountIdSelector } from '../../app/selectors'
import { retailerAccountsSelector } from '../selectors'

export const allocationDetailsForCurrentRetailerAccountSelector =
  createSelector(
    [retailerAccountsSelector, currentRetailerAccountIdSelector],
    (retailerAccounts, currentRetailerAccountId) => {
      const retailerAccount = retailerAccounts[currentRetailerAccountId] || {}
      const allocationDetails = retailerAccount.allocationDetails || []
      return {
        allocationDetails,
      }
    },
  )

export const allocationDetailsBalanceByBrandForCurrentRetailerAccountSelector =
  createSelector(
    [allocationDetailsForCurrentRetailerAccountSelector],
    (allocationDetailsForCurrentRetailerAccount) => {
      return allocationDetailsForCurrentRetailerAccount.allocationDetails.reduce(
        (map, allocationDetail) => {
          map[allocationDetail.brand_id] =
            allocationDetail.allocation_details.reduce(
              (balance, allocation_detail) => {
                return (balance += allocation_detail.balance)
              },
              0,
            )
          return map
        },
        {},
      )
    },
  )

export const allocationDetailsTotalForCurrentRetailerAccountSelector =
  createSelector(
    [allocationDetailsBalanceByBrandForCurrentRetailerAccountSelector],
    (allocationDetailsBalanceByBrandForCurrentRetailerAccount) => {
      return {
        allocationDetailsTotal: Object.values(
          allocationDetailsBalanceByBrandForCurrentRetailerAccount,
        ).reduce((balance, brandAllocationDetailsBalance) => {
          return (balance += brandAllocationDetailsBalance)
        }, 0),
      }
    },
  )

export const allocationDetailsForWalletSelector = createSelector(
  [allocationDetailsForCurrentRetailerAccountSelector],
  (allocationDetailsForCurrentRetailerAccount) => {
    const brandAllocationDetails = []

    allocationDetailsForCurrentRetailerAccount.allocationDetails.forEach(
      (brand) => {
        if (brand.allocation_details && brand.allocation_details.length > 0) {
          brand.allocation_details.forEach((allocationDetail, index) => {
            const balance = formatWithoutCents.format(
              allocationDetail.balance / 100,
            )
            const expirationDate = allocationDetail.expires_at
              ? moment(allocationDetail.expires_at).format('MM/DD/YY')
              : null

            const hasExpired = moment(expirationDate).isBefore()
            if (!hasExpired) {
              brandAllocationDetails.push({
                key: `${allocationDetail.brand_id}-${index}`,
                balance,
                brandName: allocationDetail.brand_name,
                brandSlug: allocationDetail.brand_slug,
                campaignName: allocationDetail.campaign_name,
                expirationDate,
                logoUrl: allocationDetail.logo_url,
              })
            }
          })
        }
      },
    )

    return { brandAllocationDetails }
  },
)
