import { auth } from '@promoboxx/redux-stores'
import I18n from 'i18n-js'
import { enqueueSnackbar } from 'notistack'
import { filter, map, mapTo } from 'rxjs/operators'

import * as actions from './actions'

export const requestAccessFulfilled = (action$) => {
  return action$.pipe(
    filter((action) => action.type === auth.actions.REQUEST_ACCESS_FULFILLED),
    mapTo({
      type: actions.SHOW_REQUEST_ACCESS_CONFIRMATION,
    }),
  )
}

export const requestAccessError = (action$) => {
  return action$.pipe(
    filter((action) => action.type === auth.actions.REQUEST_ACCESS_REJECTED),
    map(() => {
      enqueueSnackbar(I18n.t('shared.errorSendingRequest'), {
        variant: 'error',
      })
      return { type: 'NO_OP' }
    }),
  )
}
