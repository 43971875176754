import { auth, darklyFeatureFlags } from '@promoboxx/redux-stores'

import { createReducer } from '../../lib/Utils'

import {
  SELECT_BRAND,
  SELECT_RETAILER_ACCOUNT,
  SET_AUTH_VALIDATION_COMPLETE,
} from './actions'

const initialState = {}

export default createReducer(initialState, {
  [SELECT_BRAND]: (state, action) => {
    return { ...state, currentBrand: action.payload.id }
  },
  [SELECT_RETAILER_ACCOUNT]: (state, action) => {
    const newRetailerAccount = action.payload.id
    let currentBrand = state.currentBrand
    if (newRetailerAccount !== state.currentRetailerAccount) {
      currentBrand = null
    }
    return {
      ...state,
      currentRetailerAccount: newRetailerAccount,
      currentBrand,
    }
  },
  [SET_AUTH_VALIDATION_COMPLETE]: (state, action) => {
    return { ...state, authValidationComplete: action.payload }
  },
  [auth.actions.GET_JWT_FROM_ACCESS_TOKEN_FULFILLED]: (state) => {
    return { ...state, getJwtFromAccessTokenComplete: true }
  },
  [auth.actions.GET_JWT_FROM_ACCESS_TOKEN_REJECTED]: (state) => {
    return { ...state, getJwtFromAccessTokenComplete: true }
  },
  [auth.actions.SIGN_IN_FULFILLED]: (state) => {
    return {
      ...state,
      launchDarklyUserFlagsUpdated: false,
      launchDarklyUserIdentified: false,
    }
  },
  [auth.actions.VALIDATE_AUTH_FULFILLED]: (state) => {
    return {
      ...state,
      launchDarklyUserFlagsUpdated: false,
      launchDarklyUserIdentified: false,
    }
  },
  [darklyFeatureFlags.actions.LAUNCH_DARKLY_IDENTIFY_USER]: (state) => {
    return {
      ...state,
      launchDarklyUserIdentified: false,
      launchDarklyUserFlagsUpdated: false,
    }
  },
  [darklyFeatureFlags.actions.LAUNCH_DARKLY_IDENTIFY_USER_FULFILLED]: (
    state,
  ) => {
    return {
      ...state,
      launchDarklyUserIdentified: true,
      launchDarklyUserFlagsUpdated: true,
    }
  },
  [darklyFeatureFlags.actions.LAUNCH_DARKLY_IDENTIFY_USER_REJECTED]: (
    state,
  ) => {
    return {
      ...state,
      launchDarklyUserIdentified: true,
      launchDarklyUserFlagsUpdated: true,
    }
  },
  [darklyFeatureFlags.actions.LAUNCH_DARKLY_READY_FULFILLED]: (state) => {
    return { ...state, launchDarklyFinished: true }
  },
  [darklyFeatureFlags.actions.LAUNCH_DARKLY_READY_REJECTED]: (state) => {
    return { ...state, launchDarklyFinished: true }
  },
  [darklyFeatureFlags.actions.LAUNCH_DARKLY_INIT_REJECTED]: (state) => {
    return { ...state, launchDarklyFinished: true }
  },
})
