import { retailerAccounts } from '@promoboxx/redux-stores'
import { requestStatus } from '@promoboxx/redux-stores/src/lib/Utils'

import { createReducer } from '../../lib/Utils'

const initialState = {}

export default createReducer(initialState, {
  [retailerAccounts.actions.GET_RETAILER_ACCOUNT]: (state, action) => {
    const { id } = action.payload
    return {
      ...state,
      retailerAccountSlugRequests: {
        ...state.retailerAccountSlugRequests,
        [id]: {
          requestStatus: requestStatus.pending,
        },
      },
    }
  },
  [retailerAccounts.actions.GET_RETAILER_ACCOUNT_FULFILLED]: (
    state,
    action,
  ) => {
    const { id } = action.meta
    return {
      ...state,
      retailerAccountSlugRequests: {
        ...state.retailerAccountSlugRequests,
        [id]: {
          requestStatus: requestStatus.complete,
        },
      },
    }
  },
  [retailerAccounts.actions.GET_RETAILER_ACCOUNT_REJECTED]: (state, action) => {
    if (action.payload instanceof Error) {
      return state
    }
    const { id } = action.meta
    return {
      ...state,
      retailerAccountSlugRequests: {
        ...state.retailerAccountSlugRequests,
        [id]: {
          requestStatus: requestStatus.rejected,
          error: action.payload.response,
        },
      },
    }
  },
})
