import * as React from "react";
import { forwardRef, memo } from "react";
const SvgOldGuy = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 273.41 330.2", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "a" }, /* @__PURE__ */ React.createElement("path", { d: "M163.69 53.32h18.88v3.91h-18.88z", style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("clipPath", { id: "b" }, /* @__PURE__ */ React.createElement("path", { d: "M164.35 168.96h10.66v143.99h-10.66z", style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("clipPath", { id: "d" }, /* @__PURE__ */ React.createElement("path", { d: "M164.35 168.96h10.66v143.99h-10.66z", style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("clipPath", { id: "e" }, /* @__PURE__ */ React.createElement("path", { d: "M150.52 136.79h24.49v31.96h-24.49z", style: {
  fill: "none"
} })), /* @__PURE__ */ React.createElement("clipPath", { id: "f" }, /* @__PURE__ */ React.createElement("path", { d: "M121.89 129.35h39.46v5.78h-39.46z", style: {
  fill: "none"
} }))), /* @__PURE__ */ React.createElement("g", { style: {
  isolation: "isolate"
} }, /* @__PURE__ */ React.createElement("path", { d: "M210.72 327.31c0 1.6-33.55 2.89-74.93 2.89s-74.92-1.29-74.92-2.89 33.55-2.88 74.92-2.88 74.93 1.29 74.93 2.88", style: {
  fill: "#d1d3d4"
} }), /* @__PURE__ */ React.createElement("path", { d: "M163.15 53.63h-1.51l-.3-4.04h1.81z", style: {
  fill: "#4f352f"
} }), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.39999399999999996
} }, /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#a)"
} }, /* @__PURE__ */ React.createElement("path", { d: "M182 53.86h0a9.6 9.6 0 0 1-6.8 2.82h-11", style: {
  fill: "none",
  stroke: "#231f20",
  strokeLinecap: "round",
  strokeMiterlimit: 10,
  strokeWidth: "1.09207px"
} }))), /* @__PURE__ */ React.createElement("path", { d: "M167.56 49.59h14.46v4.05h-14.46z", style: {
  fill: "#4f352f"
} }), /* @__PURE__ */ React.createElement("path", { d: "M174.41 16.91q-1 15.94-8.06 19.79", style: {
  fill: "none",
  stroke: "#465b65",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "1.52434px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M175.46 20.18h-2.04l1.02-3.04z", style: {
  fill: "none",
  stroke: "#f05323",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "1.52434px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M154 309.7h15.53c0 7 12.32 8.62 19.94 9.5 3.35.39 5.88 4.06 5.88 7.43L154 324.84Z", style: {
  fill: "#bea883"
} }), /* @__PURE__ */ React.createElement("path", { d: "M196 325.28v2.18h-12.38c-6.37 0-8.39-1.31-15.49-1.31l-2.35 1.31H154v-4.36c23.06 0 15.12 2.18 41.94 2.18", style: {
  fill: "#8b633a"
} }), /* @__PURE__ */ React.createElement("path", { d: "M132.75 309.7h-15.54c0 7-12.32 8.62-19.94 9.5-3.35.39-5.88 4.06-5.88 7.43l41.36-1.79Z", style: {
  fill: "#bea883"
} }), /* @__PURE__ */ React.createElement("path", { d: "M90.8 325.28v2.18h12.36c6.37 0 8.39-1.31 15.49-1.31l2.35 1.31h11.75v-4.36c-23.07 0-15.13 2.18-41.95 2.18", style: {
  fill: "#8b633a"
} }), /* @__PURE__ */ React.createElement("path", { d: "M271.5 184.53 199.12 197a2.3 2.3 0 0 1-2.67-1.88L188 146.05a2.3 2.3 0 0 1 1.88-2.66l72.38-12.48a2.31 2.31 0 0 1 2.66 1.89l8.46 49.06a2.31 2.31 0 0 1-1.88 2.67", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M25.49 97.66c-3.22-.8-6.89-15.23-6.89-15.23l-1.35-1.82a9.59 9.59 0 0 1-1.58-8.16l.65-2.45a2.7 2.7 0 1 1 5.22 1.38l-.52 2a5.25 5.25 0 0 0 .78 4.38c1.94 2.77 5.39 7.63 6.89 9.32a12.8 12.8 0 0 1-12.83 20.9l-2.66-.7Z", style: {
  fill: "#fecd82"
} }), /* @__PURE__ */ React.createElement("path", { d: "M139.51 88.35H113c-9.19 0-14.68 25.77-34.92 25.77v18.12l61.46-14.76Z", style: {
  fill: "#beddf4"
} }), /* @__PURE__ */ React.createElement("path", { d: "m175.01 169.21-2.95-80.86h-59.09l-2.24 80.86h72.24-72.24", style: {
  fill: "#beddf4"
} }), /* @__PURE__ */ React.createElement("path", { d: "M141.28 94.63a8 8 0 0 1-8-8V48.18h15.95v38.47a8 8 0 0 1-8 8", style: {
  fill: "#fecd82"
} }), /* @__PURE__ */ React.createElement("path", { d: "M141.51 82.92A41.46 41.46 0 1 1 183 41.46a41.46 41.46 0 0 1-41.47 41.46", style: {
  fill: "#fecd82"
} }), /* @__PURE__ */ React.createElement("path", { d: "M100.05 34.88a6.58 6.58 0 1 1-6.58 6.58 6.59 6.59 0 0 1 6.58-6.58M183 34.88a6.58 6.58 0 1 1-6.57 6.58 6.58 6.58 0 0 1 6.57-6.58", style: {
  fill: "#fecd82"
} }), /* @__PURE__ */ React.createElement("path", { d: "M128.44 61.84a18.49 18.49 0 0 0 26.15 0Z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M121.07 37.28a2.39 2.39 0 1 1-2.39 2.39 2.39 2.39 0 0 1 2.39-2.39M162 37.28a2.39 2.39 0 1 1-2.39 2.39 2.39 2.39 0 0 1 2.39-2.39", style: {
  fill: "#062640"
} }), /* @__PURE__ */ React.createElement("path", { d: "M141.46 59.2a10.16 10.16 0 0 0 19.34 4.37 36.17 36.17 0 0 0-19-7.09 10.6 10.6 0 0 0-.37 2.72", style: {
  fill: "#bdbdbd"
} }), /* @__PURE__ */ React.createElement("path", { d: "M142.34 59.2A10.16 10.16 0 0 1 123 63.57a36.1 36.1 0 0 1 19-7.09 10.2 10.2 0 0 1 .37 2.72", style: {
  fill: "#bdbdbd"
} }), /* @__PURE__ */ React.createElement("path", { d: "M142 59.89a3.41 3.41 0 0 1-3.41-3.41v-3.41h6.82v3.41a3.41 3.41 0 0 1-3.41 3.41", style: {
  fill: "#fecd82"
} }), /* @__PURE__ */ React.createElement("path", { d: "m133.31 84.89-2.57 4.98.55 13.1 9.99-8.34zM149.49 84.89l2.57 4.98-.55 13.1-10-8.34z", style: {
  fill: "#95c8ec"
} }), /* @__PURE__ */ React.createElement("path", { d: "M141.28 94.63v74.58", style: {
  fill: "none",
  stroke: "#95c8ec",
  strokeMiterlimit: 10,
  strokeWidth: ".9836619999999999px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M126 107.05A75.39 75.39 0 0 1 20 94.51", style: {
  fill: "none",
  stroke: "#fecd82",
  strokeMiterlimit: 10,
  strokeWidth: "17.9575px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M126 107.05a76 76 0 0 1-76.27 10.17 74.7 74.7 0 0 1-19.54-12.15", style: {
  fill: "none",
  stroke: "#beddf4",
  strokeMiterlimit: 10,
  strokeWidth: "17.9575px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M28.54 96.54a13 13 0 0 0-13.85-12.08l-3.32.23 1.77 25.93 3.32-.23a13 13 0 0 0 12.08-13.85", style: {
  fill: "#fecd82"
} }), /* @__PURE__ */ React.createElement("path", { d: "M16.79 105.89S14 96.27 21.92 94.47c6.2-1.41-7.47-6.68-8.81-6.59a2.44 2.44 0 0 0-2.26 2.59l1.08 15.76a2.44 2.44 0 0 0 4.86-.34", style: {
  fill: "#fbaa19"
} }), /* @__PURE__ */ React.createElement("path", { d: "M15 93.57a3.6 3.6 0 0 0-3.83-3.35l-7.81-.64a3.59 3.59 0 0 0 .49 7.17l7.81.65A3.6 3.6 0 0 0 15 93.57", style: {
  fill: "#fecd82"
} }), /* @__PURE__ */ React.createElement("path", { d: "M15.77 87.59a3.6 3.6 0 0 0-3.83-3.34l-7.81-.65a3.6 3.6 0 0 0 .49 7.18l7.81.64a3.59 3.59 0 0 0 3.34-3.83M15.48 100.74a3.59 3.59 0 0 0-3.83-3.34l-7.81-.65a3.6 3.6 0 0 0 .49 7.18l7.81.64a3.59 3.59 0 0 0 3.34-3.83", style: {
  fill: "#fecd82"
} }), /* @__PURE__ */ React.createElement("path", { d: "M15.68 107.06a3.61 3.61 0 0 0-3.84-3.34L6 103.34a3.59 3.59 0 0 0 .49 7.17l5.81.39a3.61 3.61 0 0 0 3.35-3.84", style: {
  fill: "#fecd82"
} }), /* @__PURE__ */ React.createElement("path", { d: "M167.42 97.31a75.38 75.38 0 0 1 66 83.76", style: {
  fill: "none",
  stroke: "#fecd82",
  strokeMiterlimit: 10,
  strokeWidth: "17.9575px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M167.42 97.31a75.36 75.36 0 0 1 66.35 69.14", style: {
  fill: "none",
  stroke: "#beddf4",
  strokeMiterlimit: 10,
  strokeWidth: "17.9575px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M231.48 167.55a13 13 0 0 0-9.57 15.69l.78 3.23 25.31-6.11-.78-3.23a13 13 0 0 0-15.69-9.58", style: {
  fill: "#fecd82"
} }), /* @__PURE__ */ React.createElement("path", { d: "M230.83 181.41a3.6 3.6 0 0 0-2.65 4.34l.68 7.81a3.6 3.6 0 0 0 7-1.69l-.68-7.81a3.6 3.6 0 0 0-4.34-2.65", style: {
  fill: "#fecd82"
} }), /* @__PURE__ */ React.createElement("path", { d: "M224.81 181.65a3.6 3.6 0 0 0-2.65 4.34l.68 7.8a3.6 3.6 0 0 0 7-1.69l-.68-7.8a3.59 3.59 0 0 0-4.34-2.65M237.82 179.72a3.59 3.59 0 0 0-2.65 4.34l.68 7.8a3.6 3.6 0 0 0 7-1.69l-.68-7.8a3.59 3.59 0 0 0-4.34-2.65", style: {
  fill: "#fecd82"
} }), /* @__PURE__ */ React.createElement("path", { d: "M244 178.46a3.6 3.6 0 0 0-2.65 4.34l.6 5.8a3.6 3.6 0 0 0 7-1.69l-.6-5.8a3.6 3.6 0 0 0-4.34-2.65", style: {
  fill: "#fecd82"
} }), /* @__PURE__ */ React.createElement("path", { d: "M100.05 34.88s22.08-11.59 22.08-21.45c6.27 6.28 49.93 0 49.93 0S161.64 0 141.51 0s-41.46 14.76-41.46 34.88", style: {
  fill: "#bdbdbd"
} }), /* @__PURE__ */ React.createElement("path", { d: "m235.28 152.37 2.43 14.08M27.85 108.32l11.44 8.54", style: {
  fill: "none",
  stroke: "#95c8ec",
  strokeMiterlimit: 10,
  strokeWidth: ".9836619999999999px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M153.69 116.09h-1.58v-8.18a.79.79 0 0 1 1.58 0Z", style: {
  fill: "#f05323"
} }), /* @__PURE__ */ React.createElement("path", { d: "M149.1 116.09h16.23", style: {
  fill: "none",
  stroke: "#95c8ec",
  strokeMiterlimit: 10,
  strokeWidth: ".9836619999999999px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M151.64 108.88h.47v8.65h-.47z", style: {
  fill: "#f05323"
} }), /* @__PURE__ */ React.createElement("path", { d: "M154.42 108.88h.47v8.65h-.47z", style: {
  fill: "#465b65"
} }), /* @__PURE__ */ React.createElement("path", { d: "m114.18 313-2.94-144H175l-3.54 144h-18.87l-4.72-108.65c-.06-1.58-2.17-2.84-4.75-2.84s-4.69 1.26-4.74 2.84L133.65 313Z", style: {
  fill: "#a1887f"
} }), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.11999499999999999,
  mixBlendMode: "multiply"
} }, /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#b)"
} }, /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#d)"
} }, /* @__PURE__ */ React.createElement("path", { d: "M174.49 175.49h-5.71l-4.43 137.47h7.12l3.54-144z", style: {
  fill: "#231f20"
} })))), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.11999499999999999,
  mixBlendMode: "multiply"
} }, /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#e)"
} }, /* @__PURE__ */ React.createElement("path", { d: "M150.52 168.75c24.49 0 23.19-32 23.19-32l1.3 32Z", style: {
  fill: "#231f20"
} }))), /* @__PURE__ */ React.createElement("path", { d: "M113.09 175.49h-1.36l-.28-3.65h1.64zM174.49 175.49h-5.71v-3.65h6.01z", style: {
  fill: "#4f352f"
} }), /* @__PURE__ */ React.createElement("path", { d: "M156.64 175.69h0a8.66 8.66 0 0 0 6.13 2.54h9.91M130.1 175.69h0a8.62 8.62 0 0 1-6.12 2.54h-9.91", style: {
  fill: "none",
  stroke: "#8e6f64",
  strokeLinecap: "round",
  strokeMiterlimit: 10,
  strokeWidth: ".9836619999999999px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M134.77 171.84h13.04v3.65h-13.04z", style: {
  fill: "#4f352f"
} }), /* @__PURE__ */ React.createElement("path", { d: "M142.14 171.36a2.31 2.31 0 1 1 0 4.61M140.98 173.66h-.9", style: {
  fill: "none",
  stroke: "#f9c02d",
  strokeLinecap: "round",
  strokeMiterlimit: 10,
  strokeWidth: ".9836619999999999px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M117.07 171.84h13.04v3.65h-13.04zM151.62 171.84h13.04v3.65h-13.04z", style: {
  fill: "#4f352f"
} }), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.11999499999999999,
  mixBlendMode: "multiply"
} }, /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#f)"
} }, /* @__PURE__ */ React.createElement("path", { d: "M122.28 133.9a.64.64 0 0 0 .33 1.22c4.75-.61 12.07-2.88 18.9-2.88 6.64 0 14.47 2.22 19.12 2.83a.64.64 0 0 0 .31-1.23c-4.74-1.88-12.54-4.49-19.43-4.49s-14.65 2.66-19.23 4.55", style: {
  fill: "#231f20"
} }))), /* @__PURE__ */ React.createElement("path", { d: "M118.03 99.97h6.08v17.82h-6.08z", style: {
  fill: "#fbaa19"
}, transform: "rotate(-87.86 121.062 108.878)" })));
const ForwardRef = forwardRef(SvgOldGuy);
const Memo = memo(ForwardRef);
export default Memo;
