import { retailersContentDiscovery } from '@promoboxx/redux-stores'
import { ofType } from 'redux-observable'
import { of, merge } from 'rxjs'
import { mergeMap, withLatestFrom } from 'rxjs/operators'

import { slugsByRetailerIdForCurrentRetailerAccountSelector } from '../selectors'

export const createContentDiscoveryCampaignFulfilled = (action$, state$) => {
  return action$.pipe(
    ofType(
      retailersContentDiscovery.actions
        .CREATE_CONTENT_DISCOVERY_CAMPAIGN_FULFILLED,
    ),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const slugsByRetailerId =
        slugsByRetailerIdForCurrentRetailerAccountSelector(state)
      const { creativePath, retailerId } = action.payload.response
      const slugs = slugsByRetailerId[retailerId]

      window.location = `/#/${slugs.retailerAccount}/${slugs.brand}/campaigns/${creativePath}/activate`

      return merge(
        of({
          type: 'NO_OP',
        }),
      )
    }),
  )
}
