import { createSelector } from 'reselect'

const showRequestConfirmationSelector = (state) =>
  (state.requestAccess && state.requestAccess.showRequestAccessConfirmation) ||
  false

export const selectShowRequestConfirmation = createSelector(
  [showRequestConfirmationSelector],
  (showRequestConfirmation) => {
    return { showRequestConfirmation }
  },
)
