import * as React from "react";
import { forwardRef, memo } from "react";
const SvgAdCarousel = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "#aaa", fillRule: "evenodd", d: "M23.5 2.4V.5h-4v23h4v-6.7h-3V2.4zM0 0h17v24H0zm.5.5v23h16V.5zM19 0h5v24h-5zM1.5 2.4h14v14.4h-14z" }));
const ForwardRef = forwardRef(SvgAdCarousel);
const Memo = memo(ForwardRef);
export default Memo;
