import * as auth from './auth'
import * as JWT from './jwt'
import * as launchDarkly from './launchDarkly'
import * as selectBrand from './selectBrand'
import * as selectRetailerAccount from './selectRetailerAccount'

const epics = {
  ...JWT,
  ...auth,
  ...launchDarkly,
  ...selectBrand,
  ...selectRetailerAccount,
}

export default epics
