import * as React from "react";
import { forwardRef, memo } from "react";
const SvgFrequentUpdates = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 60 60", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "#F37021", fillRule: "nonzero", d: "M55.88 22.85c-.49-1.73-1.55-3.06-3.06-3.85-1.95-1.01-4.23-.89-5.81-.61L43.67 6.67a2.29 2.29 0 0 0-1.68-1.6c-.81-.19-1.65.08-2.21.7-8.16 9.1-11.51 11.31-20.49 15.26l-7.06 2.04c-6.22 1.8-9.82 8.35-8.04 14.61a11.72 11.72 0 0 0 5.63 7.09c1.76.97 3.69 1.47 5.64 1.47.92 0 1.85-.12 2.77-.34l.76 2.68c.4 1.41 1.18 2.68 2.23 3.69l1.19 1.13c.44.42 1 .62 1.57.62.6 0 1.21-.24 1.65-.71.87-.91.83-2.36-.09-3.22l-1.19-1.12c-.47-.44-.81-1-.99-1.63l-.76-2.67 3.28-.95c9.45-1.41 13.72-1.31 25.28 1.99q.315.09.63.09c.6 0 1.18-.24 1.61-.67.58-.59.81-1.44.58-2.24l-3.33-11.7c1.5-.57 3.5-1.62 4.63-3.47.89-1.44 1.09-3.13.6-4.87M12.02 40.79a7.18 7.18 0 0 1-3.45-4.36c-1.1-3.85 1.11-7.88 4.92-8.98l5.13-1.48 2.36 8.27 1.62 5.68-5.14 1.48c-1.85.53-3.77.32-5.44-.61m23.2-2.5c-2.55 0-5.13.24-8.17.65l-3.33-11.66-.82-2.87c7.27-3.31 11.15-5.86 17.48-12.57l8.1 28.42c-5.39-1.39-9.28-1.97-13.26-1.97M51.4 25.34c-.37.61-1.2 1.1-2 1.44l-1.13-3.97c.86-.1 1.82-.1 2.45.23.28.14.59.38.78 1.05.18.66.05 1-.1 1.25m-14.43-3.77c.65.89.45 2.14-.44 2.78-2.08 1.51-4.49 2.77-4.59 2.82-.29.15-.61.23-.92.23a1.99 1.99 0 0 1-.92-3.76c.02-.01 2.25-1.18 4.09-2.51.89-.65 2.13-.45 2.78.44" }));
const ForwardRef = forwardRef(SvgFrequentUpdates);
const Memo = memo(ForwardRef);
export default Memo;
