import jsCookie from 'js-cookie'

import AppConfig from '@src/config/AppConfig'

export function deleteOmniauthCookies() {
  // Remove the cookie omniauth sets.
  jsCookie.remove('auth_headers', {
    domain: AppConfig.OMNIAUTH_COOKIE_DOMAIN,
  })

  // Remove the JWT omniauth sets.
  jsCookie.remove('omniauth_session_jwt', {
    domain: AppConfig.OMNIAUTH_COOKIE_DOMAIN,
  })
}

export function deleteLegacyAuthHeaders() {
  if (AppConfig.USE_OMNIAUTH_LOGIN) {
    jsCookie.remove('auth_headers', {
      // Using `domain: window.location.host` doesn't seem to actually delete
      // the cookie. Luckily, an empty domain will delete the cookie set by
      // Retailer, and _won't_ delete a cookie set by Omniauth.
      domain: '',
    })
  }
}
