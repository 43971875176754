import * as React from "react";
import { forwardRef, memo } from "react";
const SvgPlanning = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 60 60", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "#4BC6E4", fillRule: "nonzero", d: "M37.05 4.1c-10.03 0-18.2 8.26-18.2 18.42 0 4.27 1.45 8.2 3.86 11.32L5.46 51.34a2.626 2.626 0 0 0 .03 3.72c.51.5 1.18.76 1.85.76.68 0 1.36-.26 1.87-.78L26.49 37.5c2.98 2.16 6.62 3.43 10.56 3.43 10.03 0 18.2-8.26 18.2-18.42-.01-10.15-8.17-18.41-18.2-18.41m0 32.16c-7.45 0-13.52-6.17-13.52-13.75S29.6 8.77 37.05 8.77c7.46 0 13.52 6.17 13.52 13.74 0 7.58-6.07 13.75-13.52 13.75m9.12-13.53a2.055 2.055 0 0 1-2.03 1.78c-.09 0-.18-.01-.27-.02a2.05 2.05 0 0 1-1.76-2.3c.41-3.06-3-4.66-3.15-4.73-1.02-.47-1.48-1.68-1.02-2.7a2.04 2.04 0 0 1 2.69-1.03c.26.11 6.35 2.89 5.54 9m-4.31 4.48c.43.43.68 1.04.68 1.65a2.322 2.322 0 0 1-2.33 2.33c-.61 0-1.22-.25-1.65-.68s-.68-1.04-.68-1.65.25-1.21.68-1.65a2.35 2.35 0 0 1 1.65-.69c.61 0 1.22.26 1.65.69" }));
const ForwardRef = forwardRef(SvgPlanning);
const Memo = memo(ForwardRef);
export default Memo;
