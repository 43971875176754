import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  concat,
  ApolloLink,
} from '@apollo/client'

import AppConfig from './AppConfig'

export const jwtRef = {
  /** @type {string | null} */
  current: null,
}

const apolloCache = new InMemoryCache()

export const apolloClient = () => {
  const httpLink = createHttpLink({ uri: AppConfig.APOLLO_CLIENT })

  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => {
      return {
        headers: {
          ...headers,
          ...(jwtRef.current ? { authorization: jwtRef.current } : {}),
        },
      }
    })

    return forward(operation)
  })

  const client = new ApolloClient({
    cache: apolloCache,
    link: concat(authMiddleware, httpLink),
    connectToDevTools: true,
    name: 'retailer',
  })

  return client
}

export const invalidateApolloQuery = (
  /** @type {(keyof import('@promoboxx/graphql-gateway-types').Query)[]} */
  queryNames,
) => {
  const response = queryNames.map((queryName) =>
    apolloCache.evict({
      id: 'ROOT_QUERY',
      fieldName: queryName,
    }),
  )

  // See note towards the end of
  // https://www.apollographql.com/docs/react/caching/garbage-collection/#cacheevict
  apolloCache.gc()

  return response
}
