import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'

import LegacySvg from '../LegacySvg'
import Loading from '../Loading'

const StyledLoader = styled('div')({
  display: 'flex',
  height: '100vh',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})

const Loader = (props) => {
  return (
    <StyledLoader>
      <LegacySvg
        height={`${props.height}px`}
        symbol={props.icon}
        width={`${props.width}px`}
      />
      <Loading />
    </StyledLoader>
  )
}

Loader.propTypes = {
  height: PropTypes.number,
  icon: PropTypes.string,
  width: PropTypes.number,
}

Loader.defaultProps = {
  height: 200,
  icon: 'boxx-blue',
  width: 200,
}

export default Loader
