import { Cloudinary } from 'cloudinary-core'

import cloudinaryRef, { initCloudinary } from '@src/lib/Cloudinary'

import { Service } from './index'

const cloudinary: Service<Cloudinary> = {
  async init() {
    initCloudinary()
  },
  loaded() {
    return cloudinaryRef.instance() !== undefined
  },
  instance() {
    return cloudinaryRef.instance()
  },
}
export default cloudinary
