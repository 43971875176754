import {
  connect as reduxConnect,
  connectAdvanced as reduxConnectAdvanced,
} from 'react-redux'

import storeActions from './actions'
import storeSelectors from './selectors'

export const actions = storeActions
export const selectors = storeSelectors
export const connect = reduxConnect
export const connectAdvanced = reduxConnectAdvanced
