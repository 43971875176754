import querystring from 'querystring'

import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { map, mergeMap, catchError, withLatestFrom } from 'rxjs/operators'

import AppConfig from '../../config/AppConfig'
import * as appActions from '../app/actions'

import * as actions from './actions'

export const getAutomations = (action$, state$) => {
  return action$.pipe(
    ofType(actions.GET_AUTOMATIONS),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const jwt = state.auth.jwt
      const settings = {
        method: 'GET',
        responseType: 'json',
        headers: {
          Authorization: jwt,
        },
        url: `${
          AppConfig.SERVICE_BASE
        }/ad/v1/automation?${querystring.stringify(action.payload)}`,
      }

      return ajax(settings).pipe(
        map((payload) => {
          return {
            type: actions.GET_AUTOMATIONS_FULFILLED,
            payload: payload.response,
          }
        }),
        catchError((payload) => {
          return of({
            type: actions.GET_AUTOMATIONS_REJECTED,
            payload: payload.response,
          })
        }),
      )
    }),
  )
}

export const createAutomation = (action$, state$) => {
  return action$.pipe(
    ofType(actions.CREATE_AUTOMATIONS),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const jwt = state.auth.jwt
      const settings = {
        method: 'POST',
        responseType: 'json',
        headers: {
          Authorization: jwt,
        },
        url: `${AppConfig.SERVICE_BASE}/ad/v1/automation`,
        body: JSON.stringify(action.payload),
      }

      return ajax(settings).pipe(
        map((payload) => {
          return {
            type: actions.CREATE_AUTOMATIONS_FULFILLED,
            payload: payload.response,
          }
        }),
        catchError((payload) => {
          return of({
            type: actions.CREATE_AUTOMATIONS_REJECTED,
            payload: payload.response,
          })
        }),
      )
    }),
  )
}

export const updateAutomation = (action$, state$) => {
  return action$.pipe(
    ofType(actions.UPDATE_AUTOMATIONS),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const jwt = state.auth.jwt
      const settings = {
        method: 'PUT',
        responseType: 'json',
        headers: {
          Authorization: jwt,
        },
        url: `${AppConfig.SERVICE_BASE}/ad/v1/automation/${action.payload.id}?retailer_id=${action.payload.retailer_id}`,
        body: JSON.stringify(action.payload),
      }

      return ajax(settings).pipe(
        map((payload) => {
          return {
            type: actions.UPDATE_AUTOMATIONS_FULFILLED,
            payload: payload.response,
          }
        }),
        catchError((payload) => {
          return of({
            type: actions.UPDATE_AUTOMATIONS_REJECTED,
            payload: payload.response,
          })
        }),
      )
    }),
  )
}

/*
sideEffect(SELECT_RETAILER_ACCOUNT, (action, dispatch, getState) => {
  // Simply too difficult in redux-observable.
  dispatch(clearAutomations())
  const state = getState()
  const retailerAccount = state.auth.user.retailer_accounts.find((x) => x.id === action.payload.id)

  if (!retailerAccount) {
    return
  }

  dispatch(getAutomations({
    retailer_ids: retailerAccount.retailers.map((x) => x.id).join(','),
  }))
})
*/
export const clearAndFetch = (action$, state$) => {
  return action$.pipe(
    ofType(appActions.SELECT_RETAILER_ACCOUNT),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const retailerAccount = state.auth.user.retailer_accounts.find(
        (x) => x.id === action.payload.id,
      )

      if (
        !retailerAccount ||
        !retailerAccount.retailers ||
        retailerAccount.retailers.length === 0
      ) {
        return of({ type: 'REDUX_OBSERVABLE_OVERHEAD' })
      }

      return of(
        actions.getAutomations({
          retailer_ids: retailerAccount.retailers.map((x) => x.id).join(','),
        }),
      )
    }),
    catchError((err) => {
      console.error(err)
      return of({ type: 'REDUX_OBSERVABLE_OVERHEAD' })
    }),
  )
}
