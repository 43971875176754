import * as React from "react";
import { forwardRef, memo } from "react";
const SvgEmail = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 22 22", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("circle", { cx: 11, cy: 11, r: 11, fill: "#0085B8" }), /* @__PURE__ */ React.createElement("path", { fill: "#FFF", d: "M5.677 5.5h10.646l-4.836 3.569a.82.82 0 0 1-.974 0zm11.74 1.087v8.22a.79.79 0 0 1-.802.776H5.385a.79.79 0 0 1-.802-.775V6.587l5.93 4.376a.82.82 0 0 0 .974 0z" }));
const ForwardRef = forwardRef(SvgEmail);
const Memo = memo(ForwardRef);
export default Memo;
