import * as React from "react";
import { forwardRef, memo } from "react";
const SvgYoutube = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlSpace: "preserve", viewBox: "0 0 300 300", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("rect", { width: 300, height: 300, ry: 150, style: {
  fill: "red",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M149.938 79.223s-63.22 0-79.086 4.123c-8.496 2.374-15.492 9.37-17.866 17.99-4.123 15.867-4.123 48.726-4.123 48.726s0 32.985 4.123 48.602c2.374 8.62 9.245 15.491 17.866 17.865 15.992 4.248 79.086 4.248 79.086 4.248s63.344 0 79.21-4.123c8.621-2.374 15.492-9.12 17.74-17.865 4.249-15.742 4.249-48.601 4.249-48.601s.125-32.985-4.248-48.852c-2.25-8.62-9.12-15.491-17.74-17.74-15.867-4.373-79.211-4.373-79.211-4.373m-20.116 40.48 52.6 30.36-52.6 30.234z", style: {
  fill: "#fff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
} }));
const ForwardRef = forwardRef(SvgYoutube);
const Memo = memo(ForwardRef);
export default Memo;
