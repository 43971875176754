import { auth } from '@promoboxx/redux-stores'
import I18n from 'i18n-js'
import { enqueueSnackbar } from 'notistack'
import queryString from 'query-string'
import { ofType } from 'redux-observable'
import { map } from 'rxjs/operators'

import * as actions from './actions'

export const forgotPasswordError = (actions$) => {
  return actions$.pipe(
    ofType(auth.actions.RESET_PASSWORD_REJECTED),
    map(() => {
      enqueueSnackbar(I18n.t('auth.forgotPasswordError'), { variant: 'error' })
      return { type: 'NO_OP' }
    }),
  )
}

export const saveRedirectUrlOnPasswordReset = (actions$) => {
  return actions$.pipe(
    ofType(auth.actions.RESET_PASSWORD_FULFILLED),
    map(() => {
      const queryStrings = queryString.parse(document.location.search)
      if (queryStrings.redirect_url) {
        localStorage.setItem(
          'pbxxr1App.auth_redirect_url',
          queryStrings.redirect_url,
        )
        return {
          type: actions.REDIRECT_URL_SAVED,
          payload: queryStrings.redirect_url,
        }
      } else {
        return {
          type: actions.NO_REDIRECT_URL_GIVEN,
        }
      }
    }),
  )
}
