import {
  userSelector as authUserSelector,
  userPermissionsSelector,
} from '@promoboxx/redux-stores/src/stores/auth/selectors'
import { brandsByRetailerAccountSelector } from '@promoboxx/redux-stores/src/stores/brands/selectors'
import {
  retailerAccountsByIdSelector,
  retailerAccountsBySlugSelector,
  userRetailerAccountsSelector,
} from '@promoboxx/redux-stores/src/stores/retailer-accounts/selectors'
import _ from 'lodash'
import { matchPath } from 'react-router-dom'
import { createSelector } from 'reselect'

import history from '@src/lib/history'

import { nonUserRetailerAccountsByIdSelector } from '../retailerAccounts/selectors'

const urlSegments = () => {
  let urlSegments
  const routerLocation = history.instance().location.pathname
  if (routerLocation && routerLocation.length) {
    urlSegments = routerLocation
      .split('?')[0]
      .split('/')
      .filter((segment) => segment && segment.length > 0)
  }
  return urlSegments
}

export const userSelector = (state) => (state.auth && state.auth.user) || {}
export const currentRetailerAccountIdSelector = (state) =>
  state.app && state.app.currentRetailerAccount
export const currentBrandIdSelector = (state) =>
  state.app && state.app.currentBrand
export const authValidationCompleteSelector = (state) =>
  !!(state.app && state.app.authValidationComplete)
export const getJwtFromAccessTokenCompleteSelector = (state) =>
  !!(state.app && state.app.getJwtFromAccessTokenComplete)
export const hasRetailerAccountsSelector = (state) =>
  !!(
    state.auth &&
    state.auth.user &&
    state.auth.user.retailer_accounts &&
    state.auth.user.retailer_accounts.length
  )
export const launchDarklyFinishedSelector = (state) =>
  !!(state.app && state.app.launchDarklyFinished)
export const launchDarklyUserIdentifiedSelector = (state) =>
  !!(state.app && state.app.launchDarklyUserIdentified)

export const userRolesSelector = createSelector(
  [authUserSelector],
  (user = {}) => {
    if (user) {
      return user.user_roles
    }
  },
)

export const userIsInternalSelector = createSelector(
  [userRolesSelector],
  (userRoles = []) => {
    return (
      userRoles.indexOf('admin') !== -1 ||
      userRoles.indexOf('client_services') !== -1
    )
  },
)

export const userIsAdminSelector = createSelector(
  [userRolesSelector],
  (userRoles = []) => {
    return userRoles.indexOf('admin') !== -1
  },
)

export const brandsForCurrentRetailerAccountSelector = createSelector(
  [
    currentRetailerAccountIdSelector,
    brandsByRetailerAccountSelector,
    nonUserRetailerAccountsByIdSelector,
    userIsInternalSelector,
  ],
  (
    currentRetailerAccount,
    brandsByRetailerAccount,
    nonUserRetailerAccountsById,
    userIsInternal,
  ) => {
    let brands = brandsByRetailerAccount[currentRetailerAccount]

    if (!brands && userIsInternal) {
      let retailerAccount = nonUserRetailerAccountsById[currentRetailerAccount]
      if (retailerAccount) {
        brands = retailerAccount.retailers.map((retailer) => {
          return retailer.brand
        })
      }
    }

    return brands || []
  },
)

export const brandIdsForCurrentRetailerAccountSelector = createSelector(
  [brandsForCurrentRetailerAccountSelector],
  (brandsForCurrentRetailerAccount) => {
    return brandsForCurrentRetailerAccount.map((brand) => {
      return brand.id
    })
  },
)

export const brandNameLogoByIdSelector = createSelector(
  [
    brandIdsForCurrentRetailerAccountSelector,
    brandsForCurrentRetailerAccountSelector,
  ],
  (brandIdsForCurrentRetailerAccount, brandsForCurrentRetailerAccount) => {
    return brandsForCurrentRetailerAccount.reduce((brandsById, brand) => {
      brandsById[brand.id] = {
        brandLogo: brand.logo_url,
        brandName: brand.name,
      }
      return brandsById
    }, {})
  },
)

export const brandDataByIdSelector = createSelector(
  [brandNameLogoByIdSelector, brandsForCurrentRetailerAccountSelector],
  (brandNameLogoByIdSelector, brandsForCurrentRetailerAccount) => {
    return brandsForCurrentRetailerAccount.reduce((brandsById, brand) => {
      brandsById[brand.id] = {
        ...brandNameLogoByIdSelector[brand.id],
        brandSlug: brand.slug,
      }
      return brandsById
    }, {})
  },
)

export const brandsById = createSelector(
  [brandsForCurrentRetailerAccountSelector],
  (brandsForCurrentRetailerAccount) => {
    const obj = {}

    for (const brand of brandsForCurrentRetailerAccount) {
      obj[brand.id] = brand
    }

    return obj
  },
)

export const currentRetailerAccountSelector = createSelector(
  [
    currentRetailerAccountIdSelector,
    retailerAccountsByIdSelector,
    nonUserRetailerAccountsByIdSelector,
    userIsInternalSelector,
  ],
  (
    currentRetailerAccountId,
    retailerAccountsById,
    nonUserRetailerAccounts,
    userIsInternal,
  ) => {
    let retailerAccount = retailerAccountsById[currentRetailerAccountId]
    if (currentRetailerAccountId && !retailerAccount && userIsInternal) {
      retailerAccount = nonUserRetailerAccounts[currentRetailerAccountId]
    }
    return retailerAccount
  },
)

export const currentBrandSelector = createSelector(
  [
    currentBrandIdSelector,
    brandsForCurrentRetailerAccountSelector,
    urlSegments,
  ],
  (currentBrandId, brands) => {
    const contentPath = '/retailer/content/:retailerAccountSlug/:brandSlug'
    const match = matchPath(history.instance().location.pathname, {
      path: contentPath,
    })
    const brandSlug = (match && match.params.brandSlug) || 'all'
    if (brandSlug === 'all' && !currentBrandId) {
      return null
    }
    let brand
    brand = brands.find((brand) => brand.id === currentBrandId)
    if (!brand) {
      brand = brands.find((brand) => brand.slug === brandSlug)
    }
    return brand
  },
)

export const isAllBrandsSelector = createSelector(
  [currentBrandIdSelector],
  (currentBrandId) => {
    return typeof currentBrandId !== 'number'
  },
)

export const retailerIdsByBrandIdSelector = createSelector(
  [currentRetailerAccountSelector],
  (retailerAccount) => {
    if (retailerAccount) {
      return retailerAccount.retailers.reduce(
        (retailerAccumulator, retailer) => {
          retailerAccumulator[retailer.brand_id] = isNaN(Number(retailer.id))
            ? null
            : Number(retailer.id)
          return retailerAccumulator
        },
        {},
      )
    }
    return {}
  },
)

export const retailerIdsForCurrentBrandOrCurrentRetailerAccountSelector =
  createSelector(
    [
      brandIdsForCurrentRetailerAccountSelector,
      currentBrandIdSelector,
      currentRetailerAccountSelector,
    ],
    (
      brandIdsForCurrentRetailerAccount,
      currentBrandId,
      currentRetailerAccount,
    ) => {
      if (currentRetailerAccount) {
        let retailerIds = []
        let brandIds

        if (currentBrandId && typeof currentBrandId === 'number') {
          brandIds = [currentBrandId]
        } else {
          brandIds = brandIdsForCurrentRetailerAccount
        }

        currentRetailerAccount.retailers.reduce((ids, retailer) => {
          if (brandIds.includes(retailer.brand_id)) {
            ids.push(retailer.id)
          }
          return ids
        }, retailerIds)

        return retailerIds
      }
      return []
    },
  )

export const selectRetailerIdsForCurrentBrandOrCurrentRetailerAccount =
  createSelector(
    [retailerIdsForCurrentBrandOrCurrentRetailerAccountSelector],
    (retailerIds) => ({
      retailerIds,
    }),
  )

export const currentRetailerAccountSlugSelector = createSelector(
  [currentRetailerAccountSelector],
  (retailerAccount) => {
    if (retailerAccount) {
      return retailerAccount.slug
    }
    return ''
  },
)

export const currentBrandSlugSelector = createSelector(
  [currentBrandSelector],
  (currentBrand) => {
    return (currentBrand && currentBrand.slug) || 'all'
  },
)

export const userHasBrandPermissionsSelector = createSelector(
  [userPermissionsSelector],
  (permissions) => {
    const brandManagement = permissions.findIndex((permission) => {
      return permission.permissionable_type === 'Brand'
    })
    return brandManagement !== -1
  },
)

export const userHasDivisionPermissionsSelector = createSelector(
  [userPermissionsSelector],
  (permissions) => {
    const divisionManagement = permissions.findIndex((permission) => {
      return permission.permissionable_type === 'Division'
    })
    return divisionManagement !== -1
  },
)

/**
 * Selectors for components
 */
export const selectCurrentRetailerAccountId = createSelector(
  [currentRetailerAccountIdSelector],
  (currentRetailerAccountId) => ({
    currentRetailerAccountId,
  }),
)

export const selectCurrentBrandId = createSelector(
  [currentBrandIdSelector],
  (currentBrandId) => ({
    currentBrandId,
  }),
)

export const currentRetailerAccountAndBrandSlugSelector = createSelector(
  [currentRetailerAccountSlugSelector, currentBrandSlugSelector],
  (retailerAccountSlug, brandSlug) => {
    return {
      retailerAccount: retailerAccountSlug,
      brand: brandSlug,
    }
  },
)

export const selectCurrentRetailerAccountAndBrandSlug = createSelector(
  [currentRetailerAccountAndBrandSlugSelector],
  (currentRetailerAccountAndBrandSlug) => {
    return {
      currentSlugs: currentRetailerAccountAndBrandSlug,
    }
  },
)

export const selectUserIsInternal = createSelector(
  [userIsInternalSelector],
  (userIsInternal) => ({
    userIsInternal,
  }),
)

export const selectUserHasBrandPermissions = createSelector(
  [userHasBrandPermissionsSelector],
  (hasPermission) => ({
    userHasBrandPermissions: hasPermission,
  }),
)

export const selectUserIsNotInternalOrBrand = createSelector(
  [userIsInternalSelector, userHasBrandPermissionsSelector],
  (isInternal, hasBrandPermissions) => ({
    userIsNotInternalOrBrand: !isInternal && !hasBrandPermissions,
  }),
)

export const selectAuthValidationComplete = createSelector(
  [authValidationCompleteSelector],
  (authValidationComplete) => ({
    authValidationComplete,
  }),
)

export const selectGetJwtFromAccessTokenComplete = createSelector(
  [getJwtFromAccessTokenCompleteSelector],
  (getJwtFromAccessTokenComplete) => ({
    getJwtFromAccessTokenComplete,
  }),
)

export const selectHasRetailerAccounts = createSelector(
  [hasRetailerAccountsSelector],
  (hasRetailerAccounts) => ({
    hasRetailerAccounts,
  }),
)

export const urlSegmentsSelector = createSelector(
  [() => history.instance().location],
  (routerLocation) => {
    let urlSegments = []
    if (
      routerLocation &&
      routerLocation.pathname &&
      routerLocation.pathname.length
    ) {
      urlSegments = routerLocation.pathname
        .split('?')[0]
        .split('/')
        .filter((segment) => segment && segment.length > 0)
    }

    return urlSegments
  },
)

export const nonUserRetailerAccountFromUrlSelector = createSelector(
  [urlSegments, retailerAccountsBySlugSelector],
  (urlSegments, retailerAccountsBySlug) => {
    let retailerAccount
    const slugs = Object.keys(retailerAccountsBySlug)
    const matchingSlug = slugs.find((slug) => urlSegments.indexOf(slug) !== -1)
    if (matchingSlug) {
      retailerAccount = retailerAccountsBySlug[matchingSlug]
    }
    return retailerAccount
  },
)

export const retailerAccountFromUrlSelector = createSelector(
  [
    urlSegments,
    userRetailerAccountsSelector,
    nonUserRetailerAccountFromUrlSelector,
    userIsInternalSelector,
  ],
  (
    urlSegments,
    userRetailerAccounts,
    matchingNonUserRetailerAccount,
    userIsInternal,
  ) => {
    let retailerAccount

    if (urlSegments.length) {
      if (userRetailerAccounts.length) {
        retailerAccount = userRetailerAccounts.find((retailerAccount) => {
          return urlSegments.find((urlSegment) => {
            return urlSegment === retailerAccount.slug
          })
        })
      }
      if (
        !retailerAccount &&
        matchingNonUserRetailerAccount &&
        userIsInternal
      ) {
        retailerAccount = matchingNonUserRetailerAccount
      }
    }

    return retailerAccount
  },
)

export const selectRetailerAccountFromUrl = createSelector(
  [retailerAccountFromUrlSelector],
  (retailerAccountFromUrl) => {
    let retailerAccount
    if (retailerAccountFromUrl) {
      retailerAccount = {
        id: retailerAccountFromUrl.id,
        slug: retailerAccountFromUrl.slug,
      }
    }
    return {
      retailerAccount,
    }
  },
)

export const brandFromUrlSelector = createSelector(
  [retailerAccountFromUrlSelector, urlSegments],
  (retailerAccount, urlSegments) => {
    let brand

    if (
      urlSegments.length &&
      retailerAccount &&
      retailerAccount.retailers &&
      retailerAccount.retailers.length
    ) {
      let retailer = retailerAccount.retailers.find((retailer) => {
        return urlSegments.find((urlSegment) => {
          return urlSegment === retailer.brand.slug
        })
      })

      if (retailer) {
        brand = retailer.brand
      }
    }

    return brand
  },
)

export const selectBrandFromUrl = createSelector(
  [brandFromUrlSelector],
  (brandFromUrl) => {
    let brand
    if (brandFromUrl) {
      brand = {
        id: brandFromUrl.id,
        slug: brandFromUrl.slug,
      }
    }
    return {
      brand,
    }
  },
)

export const selectRetailerAccountsIds = createSelector(
  [userRetailerAccountsSelector],
  (retailerAccounts) => {
    const retailerAccountIds = []

    retailerAccounts.forEach((retailerAccount) => {
      retailerAccountIds.push(Number(retailerAccount.id))
    })

    return {
      retailerAccountIds,
    }
  },
)

export const selectAppReady = createSelector(
  [launchDarklyFinishedSelector, launchDarklyUserIdentifiedSelector],
  (launchDarklyFinished, launchDarklyUserIdentified) => {
    return {
      appReady: launchDarklyFinished && launchDarklyUserIdentified,
    }
  },
)

export const selectCustomLaunchDarklyIdentifyFields = createSelector(
  [authUserSelector],
  (user) => {
    let brandNames = []
    let brandIds = []
    let retailerAccountNames = []
    let retailerAccountIds = []

    //Pull out the data from the user object
    if (user && user.retailer_accounts) {
      user.retailer_accounts.forEach((retailer_account) => {
        retailerAccountNames.push(retailer_account.name)
        retailerAccountIds.push(retailer_account.id)

        if (retailer_account.retailers) {
          retailer_account.retailers.forEach((retailer) => {
            brandNames.push(retailer.brand.name)
            brandIds.push(retailer.brand.id)
          })
        }
      })
    }

    //Sort and ensure each entry only appears once.
    return {
      brandNames: _.uniq(brandNames.sort()),
      brandIds: _.uniq(brandIds.sort()),
      retailerAccountNames: _.uniq(retailerAccountNames.sort()),
      retailerAccountIds: _.uniq(retailerAccountIds.sort()),
    }
  },
)
