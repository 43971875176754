import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { mergeMap, withLatestFrom } from 'rxjs/operators'

import history from '@src/lib/history'

import * as actions from '../actions'
import * as selectors from '../selectors'

export const handleBrandSelect = (actions$, state$) => {
  return actions$.pipe(
    ofType(actions.SELECT_BRAND),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      if (action.payload.skipUrlUpdate) {
        return of(actions.skipBrandUrlUpdate())
      } else {
        const pathname = history.instance().location.pathname

        const brand = selectors.brandFromUrlSelector(state)
        const actionId = action.payload.id
        const currentSlugs =
          selectors.currentRetailerAccountAndBrandSlugSelector(state)
        const oldUrlPart = `${currentSlugs.retailerAccount}/${
          (brand && brand.slug) || 'all'
        }`
        const newUrlPart = `${currentSlugs.retailerAccount}/${
          actionId ? currentSlugs.brand : 'all'
        }`

        //return merge(of(push(pathname.replace(oldUrlPart, newUrlPart))))
        const newPath = pathname.replace(oldUrlPart, newUrlPart)
        return of(actions.updateUrl(newPath))
      }
    }),
  )
}
