import { Service } from './index'

declare global {
  interface Window {
    FS?: any
  }
}

interface FullStoryInstance {
  getCurrentSession(): string
}

const fullstory: Service<FullStoryInstance> = {
  loaded() {
    return window.FS !== undefined
  },
  instance() {
    return window.FS
  },
}
export default fullstory
