import * as React from "react";
import { forwardRef, memo } from "react";
const SvgAdImage = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "#aaa", d: "M0 0h24v24H0zm.5.5v23h23V.5zM2 2.4h20v14.4H2z" }));
const ForwardRef = forwardRef(SvgAdImage);
const Memo = memo(ForwardRef);
export default Memo;
