import * as React from "react";
import { forwardRef, memo } from "react";
const SvgInfoNotification = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 30 28", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "#FFF", d: "M1.762 27.333c-.251 0-.498-.065-.716-.189a1.395 1.395 0 0 1-.52-1.923L13.764 1.37a1.44 1.44 0 0 1 2.473 0l13.237 23.852c.126.215.193.458.193.705 0 .777-.64 1.407-1.429 1.407zm2.361-2h21.754a.667.667 0 0 0 .585-.986L15.585 4.407a.667.667 0 0 0-1.17 0L3.538 24.346a.667.667 0 0 0 .585.986m11.64-2.22h-1.525a.667.667 0 0 1-.667-.668v-2.147h2.858v2.147a.667.667 0 0 1-.667.667m.761-3.969h-2.857V12c0-.368.298-.667.666-.667h1.524c.369 0 .667.299.667.667z" }));
const ForwardRef = forwardRef(SvgInfoNotification);
const Memo = memo(ForwardRef);
export default Memo;
