import I18n from 'i18n-js'

import normalizeLocaleName from '@src/lib/normalizeLocaleName'

import en from '../i18n/langs/en.json'

I18n.fallbacks = true
I18n.defaultLocale = 'en'
I18n.locale = normalizeLocaleName('en')
I18n.translations = {
  en,
}

const origMissingTranslation = I18n.missingTranslation.bind(I18n)
const originalTranslate = I18n.t.bind(I18n)

export const finishSetup = (locale?: string) => {
  const searchParams = new URLSearchParams(window.location.search)
  I18n.locale = normalizeLocaleName(
    searchParams.get('enrollment_page_language_override') ||
      locale ||
      localStorage.getItem('language') ||
      navigator.language,
  )
  // Now that we've been told the app is ready to go, we can go back to using
  // fallbacks and use the standard "missing translation" message, rather than
  // crash the app when a developer is using a translation that hasn't been
  // defined yet.
  I18n.fallbacks = true
  I18n.missingTranslation = origMissingTranslation

  // Twitter has been changed to "X", there are cases where we are using BE values directly in translations, such as the calendar
  // this should take care of that automatically
  I18n.t = (scope: I18n.Scope, options?: I18n.TranslateOptions | undefined) => {
    if (options) {
      for (const key in options) {
        if (Object.prototype.hasOwnProperty.call(options, key)) {
          const element = options[key]
          if (typeof element === 'string' && element.match(/twitter/gi)) {
            options[key] = element.replace(/twitter/gi, 'X')
          }
        }
      }
    }
    return originalTranslate(scope, options)
  }
}

// When developing, we want to let developers know that they're using the I18n
// system in a way that will not work.
if (process.env.NODE_ENV !== 'production') {
  I18n.fallbacks = false
  I18n.missingTranslation = (scope) => {
    throw new Error(`I18n requested for ${scope} before i18n was set up`)
  }
}
