import { useRef } from 'react'

// Useful when you want to access something in a hook but don't want the hook to
// re-evaluate when it changes.
// https://github.com/streamich/react-use/blob/ade8d3905f544305515d010737b4ae604cc51024/docs/useLatest.md
function useLatest<T>(value: T) {
  const ref = useRef<T>(value)
  ref.current = value

  return ref
}

export default useLatest
