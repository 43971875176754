import I18n from 'i18n-js'

import AppConfig from '@src/config/AppConfig'
import { SUPPORTED_LANGUAGES } from '@src/lib/normalizeLocaleName'

async function loadCrowdinTranslations() {
  if (!AppConfig.TRANSLATIONS_FILE_FILTER || !AppConfig.TRANSLATIONS_DOMAIN) {
    return
  }

  const translationsToAdd: [string, object][] = []

  const manifest = await loadManifest()
  const project = manifest.projects.retailer
  if (!project) {
    return
  }

  const translations = await loadTranslations(project)
  const languageMappings = project.languageMappings ?? {}

  for (const languageOrLocale in translations) {
    const translation = translations[languageOrLocale]

    if (translation) {
      // If we have set up a custom mapping, make sure we also add
      // that to our global list of translations.
      const mappingForLanguage = languageMappings[languageOrLocale] ?? []
      for (const mapping of mappingForLanguage) {
        translationsToAdd.push([mapping, translation])
      }

      translationsToAdd.push([languageOrLocale, translation])
    }
  }

  // Reset SUPPORTED_LANGUAGES with official list.
  while (SUPPORTED_LANGUAGES.length) {
    SUPPORTED_LANGUAGES.pop()
  }
  for (const language of project.languages) {
    SUPPORTED_LANGUAGES.push(language.toLowerCase())
  }

  I18n.translations = {}
  for (const translationTuple of translationsToAdd) {
    I18n.translations[translationTuple[0].toLowerCase()] = translationTuple[1]
  }
}

async function loadTranslations(appManifest: ApplicationManifest) {
  const translations: Record<string, object> = {}
  // We only want files listed in manifest.languages. Our tool builds
  // translations for both those and language mappings in case a client is lazy,
  // but doing things this way means the user saves bandwidth.
  const validFiles = appManifest.files.filter(
    (file) =>
      appManifest.languages.includes(file.locale) &&
      file.path.includes(AppConfig.TRANSLATIONS_FILE_FILTER || '[]'),
  )

  await Promise.all(
    validFiles.map(async (file) => {
      const data = await fetch(
        `${AppConfig.TRANSLATIONS_DOMAIN}${file.path}`,
      ).then((resp) => resp.json())

      translations[file.locale] = data
    }),
  )

  return translations
}

async function loadManifest(): Promise<Manifest> {
  return fetch(`${AppConfig.TRANSLATIONS_DOMAIN}`).then((resp) => resp.json())
}

// Taken from pbxx-translate.
interface ApplicationManifest {
  languages: string[]
  languageMappings: Record<string, string[]>
  files: { locale: string; path: string }[]
}

interface Manifest {
  projects: Record<string, ApplicationManifest>
}

export default loadCrowdinTranslations
