import * as React from "react";
import { forwardRef, memo } from "react";
const SvgHappyCustomers = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 60 60", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "#904098", fillRule: "nonzero", d: "M56.09 14.49c-2.63-5.86-8.18-9.65-14.14-9.65s-9.83 2.62-12.1 5.46c-2.28-2.84-6.14-5.46-12.1-5.46-5.97 0-11.52 3.79-14.15 9.65C.86 20.6 2.05 27.4 6.76 32.68c11.29 12.67 21.27 21.65 21.37 21.73.49.44 1.1.66 1.72.66.61 0 1.23-.22 1.72-.66.1-.09 10.08-9.07 21.37-21.73 4.7-5.29 5.88-12.09 3.15-18.19m-7.01 14.76c-8.35 9.36-15.95 16.67-19.24 19.74-3.29-3.07-10.89-10.37-19.24-19.74-3.37-3.78-4.21-8.4-2.3-12.66 1.77-3.95 5.57-6.61 9.44-6.61 7.69 0 9.43 5.75 9.6 6.42a2.565 2.565 0 0 0 2.48 1.95h.02c1.17 0 2.2-.81 2.5-1.95.17-.66 1.9-6.42 9.61-6.42 3.87 0 7.67 2.65 9.44 6.6 1.9 4.28 1.07 8.89-2.31 12.67m-.63-7.46c.08 1.24-.86 2.31-2.1 2.4h-.15c-1.18 0-2.17-.91-2.25-2.11-.24-3.65-2.63-4.25-3.11-4.34a2.24 2.24 0 0 1-1.91-2.54c.17-1.23 1.33-2.09 2.56-1.92 2.24.32 6.57 2.49 6.96 8.51m-3.11 3.74c.48.48.75 1.15.75 1.82 0 .68-.27 1.35-.75 1.82a2.581 2.581 0 0 1-4.4-1.82 2.581 2.581 0 0 1 4.4-1.82" }));
const ForwardRef = forwardRef(SvgHappyCustomers);
const Memo = memo(ForwardRef);
export default Memo;
