import { auth } from '@promoboxx/redux-stores'
import I18n from 'i18n-js'
import jsCookie from 'js-cookie'
import { enqueueSnackbar } from 'notistack'
import { ofType } from 'redux-observable'
import { map, mergeMap, withLatestFrom } from 'rxjs/operators'

import AppConfig from '@src/config/AppConfig'

import * as actions from './actions'

const cookieOps = {
  path: '/',
  expires: 9999,
  expirationUnit: 'days',
  secure: true,
}

function setAuthCookie(headers) {
  jsCookie.set('auth_headers', headers, {
    ...cookieOps,
    domain: AppConfig.USE_OMNIAUTH_LOGIN
      ? AppConfig.OMNIAUTH_COOKIE_DOMAIN
      : undefined,
  })
}

function getAuthCookie() {
  return jsCookie.getJSON('auth_headers')
}

export const loginValidateAuthWithCache = (action$, state$) => {
  return action$.pipe(
    ofType(actions.VALIDATE_AUTH_WITH_CACHE),
    withLatestFrom(state$),
    map(([, state]) => {
      let payload
      const headers = auth.selectors.headersSelector(state)
      if (!headers) {
        payload = getAuthCookie()
      }
      return auth.actions.validateAuth(payload)
    }),
  )
}

export const loginValidateAuthFulfilled = (action$, state$) => {
  return action$.pipe(
    ofType(auth.actions.VALIDATE_AUTH_FULFILLED),
    withLatestFrom(state$),
    mergeMap(([action]) => {
      setAuthCookie(action.payload.headers)
      return [
        {
          type: actions.AUTH_COOKIE_SET,
        },
      ]
    }),
  )
}

export const loginSuccess = (action$, state$) => {
  return action$.pipe(
    ofType(auth.actions.SIGN_IN_FULFILLED),
    withLatestFrom(state$),
    mergeMap(([action]) => {
      setAuthCookie(action.payload.headers)
      return [
        {
          type: 'NO_OP',
        },
      ]
    }),
  )
}

export const loginError = (action$) => {
  return action$.pipe(
    ofType(auth.actions.SIGN_IN_REJECTED),
    map(() => {
      enqueueSnackbar(I18n.t('auth.loginError'), { variant: 'error' })
      return { type: 'NO_OP' }
    }),
  )
}
