import {
  ad,
  auth,
  campaigns,
  contentDiscovery,
  darklyFeatureFlags,
  facebookAds,
  facebookPages,
  feature_flags,
  localAdBalances,
  locations,
  retailers,
  retailersCampaignsSearch,
  retailersFacebookPages,
  retailerAccounts,
  retailerAccountsCampaignsSearch,
  stats,
} from '@promoboxx/redux-stores'
import { combineReducers } from 'redux'

import app from './app/reducers'
import automations from './automations/reducer'
import content from './content/reducers'
import forgotPassword from './forgotPassword/reducers'
import paidAds from './paidAds/reducers'
import requestAccess from './requestAccess/reducers'
import retailerAccountsFilters from './retailerAccounts/filters/reducers'
import appRetailerAccounts from './retailerAccounts/reducers'
const reducers = {
  ad: ad.reducers,
  app,
  appRetailerAccounts,
  auth: auth.reducers,
  campaigns: campaigns.reducers,
  content,
  contentDiscovery: contentDiscovery.reducers,
  darklyFeatureFlags: darklyFeatureFlags.reducers,
  facebookAds: facebookAds.reducers,
  facebookPages: facebookPages.reducers,
  feature_flags: feature_flags.reducers,
  forgotPassword,
  localAdBalances: localAdBalances.reducers,
  locations: combineReducers({
    retailers: locations.retailers.reducers,
  }),
  paidAds,
  requestAccess,
  retailers: retailers.reducers,
  retailersCampaignsSearch: retailersCampaignsSearch.reducers,
  retailersFacebookPages: retailersFacebookPages.reducers,
  retailerAccounts: retailerAccounts.reducers,
  retailerAccountsFilters,
  retailerAccountsCampaignsSearch: retailerAccountsCampaignsSearch.reducers,
  stats: stats.reducers,
  automations,
}

export default reducers
