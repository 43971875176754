import { retailersCampaignsSearch } from '@promoboxx/redux-stores'
import { requestStatus } from '@promoboxx/redux-stores/src/lib/Utils'
import { createSelector } from 'reselect'

import { currentRetailerIdSelector } from '../../selectors'

export const currentRetailerCampaignsSearchSelector = createSelector(
  [
    currentRetailerIdSelector,
    retailersCampaignsSearch.selectors.campaignsSearchRetailersByIdSelector,
  ],
  (currentRetailerId, campaignsSearchByRetailerId) => {
    if (
      typeof currentRetailerId === 'number' &&
      campaignsSearchByRetailerId[currentRetailerId]
    ) {
      return campaignsSearchByRetailerId[currentRetailerId]
    }
    return {}
  },
)

export const retailerCampaignsSearchRequestsSelector = createSelector(
  [currentRetailerCampaignsSearchSelector],
  (currentRetailerCampaignsSearch) => {
    return currentRetailerCampaignsSearch.searchCampaignsRequests || []
  },
)

/**
 * Will be true if all requests pending or a mixture of pending and rejected,
 * but not if any are complete or if all are rejected
 * @type {OutputSelector<any, any, (res: any) => boolean>}
 */
export const retailerCampaignsSearchIsLoadingSelector = createSelector(
  [retailerCampaignsSearchRequestsSelector],
  (requests) => {
    if (requests.length > 0) {
      let hasCompleteRequests = false
      let hasOnlyRejectedRequests = true
      requests.forEach((request) => {
        if (request.status === requestStatus.complete) {
          hasCompleteRequests = true
          return false
        } else if (request.status === requestStatus.pending) {
          hasOnlyRejectedRequests = false
        }
      })
      return !(hasCompleteRequests || hasOnlyRejectedRequests)
    }
    return false
  },
)

/**
 * Will return true if there are a mixture of complete and pending requests
 * @type {OutputSelector<any, boolean, (res: any) => boolean>}
 */
export const retailerCampaignsSearchIsLoadingPageSelector = createSelector(
  [retailerCampaignsSearchRequestsSelector],
  (requests) => {
    let hasPendingRequest = false
    let hasCompleteRequest = false
    requests.forEach((request) => {
      if (request.status === requestStatus.pending) {
        hasPendingRequest = true
      } else if (request.status === requestStatus.complete) {
        hasCompleteRequest = true
      }
    })
    return hasPendingRequest && hasCompleteRequest
  },
)

/**
 * Returns true if all requests are rejected
 * @type {OutputSelector<any, any, (res: any) => boolean>}
 */
export const retailerCampaignsSearchIsRejectedSelector = createSelector(
  [retailerCampaignsSearchRequestsSelector],
  (requests) => {
    if (requests.length > 0) {
      let hasOnlyRejectedRequests = true
      requests.forEach((request) => {
        if (request.status !== requestStatus.rejected) {
          hasOnlyRejectedRequests = false
          return false
        }
      })
      return hasOnlyRejectedRequests
    }
    return false
  },
)

export const retailerCampaignsSearchResultsSelector = createSelector(
  [currentRetailerCampaignsSearchSelector],
  (currentRetailerCampaignsSearch) => {
    const campaigns = currentRetailerCampaignsSearch.searchCampaigns || []
    // Flatten pages of results
    return [].concat(...campaigns)
  },
)

export const retailerCampaignsSearchTotalSelector = createSelector(
  [currentRetailerCampaignsSearchSelector],
  (currentRetailerCampaignsSearch) => {
    return currentRetailerCampaignsSearch.searchCampaignsTotal
  },
)

export const retailerCampaignsSearchFiltersSelector = createSelector(
  [currentRetailerCampaignsSearchSelector],
  (currentRetailerCampaignsSearch) => {
    return currentRetailerCampaignsSearch.searchCampaignsFilters || {}
  },
)
