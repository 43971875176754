import { AnalyticsBrowser } from '@segment/analytics-next'

import {
  IdentifiedUser,
  AnalyticsTrackEvent,
  AnalyticsPageEvent,
} from '@src/analytics'

import AppConfig from '../AppConfig'

import { Service } from './index'

declare global {
  interface Window {
    analytics?: AnalyticsBrowser
  }
}

let segmentInstance: AnalyticsBrowser | undefined = undefined

const segment: Service<AnalyticsBrowser> = {
  init() {
    return new Promise<void>((resolve) => {
      if (AppConfig.SEGMENT_KEY) {
        segmentInstance = AnalyticsBrowser.load(
          {
            writeKey: AppConfig.SEGMENT_KEY,
            cdnURL: 'https://telemetry-cdn.promoboxx.com',
          },
          {
            integrations: {
              'Segment.io': {
                apiHost: 'telemetry-api.promoboxx.com/v1',
                protocol: 'https',
              },
            },
          },
        )
        window.analytics = segmentInstance
        // extra precaution to reset segment data since it holds onto prior tracking data sent
        segmentInstance?.reset()
      }
      resolve()
    })
  },
  loaded() {
    return segmentInstance !== undefined
  },
  instance() {
    return segmentInstance
  },
  identify(details: IdentifiedUser) {
    if (segmentInstance) {
      segmentInstance.identify(details.id, details, {}, () => {})
    }
  },
  track(details: AnalyticsTrackEvent) {
    const { data = {}, type, event, ...rest } = details
    if (segmentInstance) {
      segmentInstance.track(event, {
        ...data,
        ...rest,
        type,
      })
    }
  },
  page(details: AnalyticsPageEvent) {
    const { category, type, ...traits } = details
    if (segmentInstance) {
      segmentInstance.page(category || 'user-screen', type, traits)
    }
  },
}
export default segment
