import * as React from "react";
import { forwardRef, memo } from "react";
const SvgLock = ({
  title,
  titleId,
  desc,
  descId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", ref, "aria-labelledby": titleId, "aria-describedby": descId, ...props }, desc ? /* @__PURE__ */ React.createElement("desc", { id: descId }, desc) : null, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("g", { fill: "none", fillRule: "evenodd" }, /* @__PURE__ */ React.createElement("path", { fill: "#999", d: "M7.5 9h9a4.5 4.5 0 1 0-9 0M18 9a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-9a2 2 0 0 1 2-2 6 6 0 1 1 12 0" }), /* @__PURE__ */ React.createElement("path", { d: "M0 0h24v24H0z" })));
const ForwardRef = forwardRef(SvgLock);
const Memo = memo(ForwardRef);
export default Memo;
